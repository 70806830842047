<template>
	<div class="page-events">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0 text-danger">

				</h2>
			</div>
			<div class="col-auto" v-if="j && j.website">
				<a :href="j.website" target="_blank" class="btn btn-outline-primary btn-sm me-2">
					View on website
				</a>
			</div>
		</div>

		<div class="page-emergency-alerts">

			<div class="row justify-content-center">
				<div class="col-lg-10">
					<!-- Current Alert Card -->
					<div class="card mb-4 border-danger shadow-lg alert-pulse" v-if="hasActiveAlert">
						<div class="card-header">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="my-0 text-danger">
									<font-awesome-icon :icon="['fas', 'bell']" class="me-2 fa-shake" />
									<font-awesome-icon :icon="['fas', 'broadcast-tower']" class="me-2" />
									ACTIVE EMERGENCY ALERT
								</h5>
								<button @click="removeAlert" class="btn btn-outline-danger btn-sm"
									:disabled="alertState === 'deleting'">
									<template v-if="alertState === 'deleting'">
										<span class="spinner-border spinner-border-sm me-1" role="status"></span>
										Removing...
									</template>
									<template v-else>
										<font-awesome-icon :icon="['fas', 'times']" class="me-1" />
										Remove Alert
									</template>
								</button>
							</div>
						</div>
						<div class="card-body px-5">
							<div class="alert alert-danger mb-3">
								<div class="d-flex align-items-center mb-2">
									<font-awesome-icon :icon="['fas', 'satellite-dish']" class="me-2 fa-lg" />
									<strong>Currently Broadcasting Through:</strong>
								</div>
								<ul class="list-unstyled mb-0 ms-4">
									<li>
										<font-awesome-icon :icon="['fas', 'globe']" class="me-2" />
										Website Ticker
										<small class="text-muted ms-2">- Displays on {{ j.website }}</small>
									</li>
									<!-- <li>
									<font-awesome-icon :icon="['fas', 'envelope']" class="me-2" /> 
									Email
									<small class="text-muted ms-2">- Sends to all subscribers</small>
								</li>
								<li>
									<font-awesome-icon :icon="['fas', 'mobile-screen']" class="me-2" /> 
									SMS
									<small class="text-muted ms-2">- Sends to all subscribers</small>
								</li> -->
								</ul>
							</div>
							
							<div class="alert alert-danger d-flex align-items-center mb-0 overflow-hidden">
								<div class="d-flex align-items-center flex-shrink-0 me-3">
									<font-awesome-icon :icon="['fas', 'bell']" class="fa-shake me-2" />
									<strong>Emergency Message:</strong>
								</div>
								<div class="ticker-content">
									<div class="ticker-scroll">
										<strong>{{ emergencyAlert.title }}</strong>
										<span v-if="emergencyAlert.title && emergencyAlert.content"> - </span>
										{{ emergencyAlert.content }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- New Alert Form -->
					<div class="card mb-5" :class="{ 'opacity-50': hasActiveAlert }">
						<div class="card-header">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="my-0">
									<font-awesome-icon :icon="['fas', 'triangle-exclamation']"
										class="text-danger me-2" />
									{{ hasActiveAlert ? 'Create New Emergency Alert' : 'Create Emergency Alert' }}
								</h5>
								<help-button url="https://townweb.notion.site/Emergency-Alerts-Guide"
									text="How to send Emergency Alerts"></help-button>
							</div>
						</div>
						<div class="card-body px-5">
							<form @submit.prevent="createEmergencyAlert">
								<div class="row">
									<div class="col-12">
										<form @submit.prevent="generateEmergencyAlert" class="px-5">
											<div v-if="alertState === 'generating'" class="">
												<span class="spinner-border spinner-border-sm text-primary"
													role="status"></span>
												Generating alert for "{{ alertPrompt }}"
											</div>
											<div v-else class="form-floating mb-3">
												<input type="text" class="form-control" id="emergency-alert-prompt"
													placeholder="Ex: water main break on Main St" v-model="alertPrompt"
													required minlength="5"
													:disabled="hasActiveAlert || alertState === 'generating'" />
												<label for="emergency-alert-prompt">
													✨ Generate with AI
												</label>
											</div>
										</form>

										<hr class="bg-primary-100" />

										<div class="form-group mb-4">
											<label class="form-label d-flex align-items-center">
												<font-awesome-icon :icon="['fas', 'bullhorn']" class="me-2" />
												<strong>Alert Title</strong>
												<span class="text-danger ms-1">*</span>
											</label>
											<input type="text" class="form-control" placeholder="Short and clear title"
												v-model="emergencyAlert.title" required :disabled="hasActiveAlert" />
										</div>

										<div class="form-group mb-4">
											<label class="form-label d-flex align-items-center">
												<font-awesome-icon :icon="['fas', 'triangle-exclamation']"
													class="me-2" />
												<strong>Emergency Message</strong>
												<span class="text-danger ms-1">*</span>
											</label>
											<textarea v-model="emergencyAlert.content" class="form-control" rows="5"
												placeholder="Write your emergency alert content here..." required
												:disabled="hasActiveAlert"></textarea>
										</div>

										<div class="form-group mb-4">
											<label class="form-label d-flex align-items-center">
												<font-awesome-icon :icon="['fas', 'satellite-dish']" class="me-2" />
												<strong>Where to Post</strong>
											</label>
											<div class="row row-cols-1 row-cols-md-2 g-3">
												<div class="col">
													<div class="p-3 rounded-1" :class="[
														emergencyAlert.channels.includes('website')
															? 'bg-danger-50'
															: 'bg-neutral-50',
													]">
														<div class="form-check form-switch mb-2">
															<input class="form-check-input" type="checkbox"
																role="switch" id="channel-website"
																v-model="emergencyAlert.channels" value="website"
																:disabled="hasActiveAlert" />
															<label class="form-check-label" for="channel-website">
																<font-awesome-icon :icon="['fas', 'globe']"
																	class="me-2" />
																Website Ticker
															</label>
														</div>
														<p class="mb-0">
															Display alert banner on <code>{{ j.website }}</code>
														</p>
													</div>
												</div>

												<div class="col">
													<div class="p-3 rounded-1 bg-neutral-50" data-bs-toggle="tooltip"
														data-bs-placement="top" title="Coming soon">
														<div class="form-check form-switch mb-2">
															<input class="form-check-input" type="checkbox"
																role="switch" id="channel-subscribers" disabled
																value="subscribers" />
															<label class="form-check-label text-muted"
																for="channel-subscribers">
																<font-awesome-icon :icon="['fas', 'paper-plane']"
																	class="me-2" />
																Subscribers
															</label>
														</div>
														<p class="mb-0 text-muted">
															<font-awesome-icon :icon="['fas', 'envelope']"
																class="me-1" />
															Email and
															<font-awesome-icon :icon="['fas', 'mobile-screen']"
																class="mx-1" />
															SMS to all subscribers (Coming soon)
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					<!-- Website Ticker Preview Card -->
					<div v-if="emergencyAlert.channels.includes('website')" class="card mb-5">
						<div class="card-header">
							<h5 class="my-0">
								<font-awesome-icon :icon="['fas', 'globe']" class="me-2" />
								Website Ticker Preview
							</h5>
						</div>
						<div class="card-body">
							<div class="alert alert-danger d-flex align-items-center mb-0 overflow-hidden">
								<div class="d-flex align-items-center flex-shrink-0 me-3">
									<font-awesome-icon :icon="['fas', 'bell']" class="fa-shake me-2" />
									<strong>EMERGENCY ALERT:</strong>
								</div>
								<div class="ticker-content">
									<div class="ticker-scroll">
										<strong>{{ emergencyAlert.title }}</strong>
										<span v-if="emergencyAlert.title && emergencyAlert.content"> - </span>
										{{ emergencyAlert.content }}
									</div>
								</div>
							</div>
							<small class="text-muted d-block mt-2">
								<font-awesome-icon :icon="['fas', 'info-circle']" class="me-1" />
								This is how the alert will appear at the top of your website
							</small>
						</div>
					</div>

					<!-- Send Alert Card -->
					<div class="card mb-5">
						<div class="card-header">
							<h5 class="my-0">Send Emergency Alert</h5>
						</div>
						<div class="card-body text-center">
							<template v-if="emergencyAlert.channels.length">
								<p class="card-text">
									This emergency alert will be sent to {{ emergencyAlert.channels.join(', ') }}.
								</p>
								<p class="card-text">
									<button type="submit" class="btn btn-danger px-6"
										:disabled="hasActiveAlert || !emergencyAlert.content || alertState === 'submitting'"
										@click="createEmergencyAlert">
										<template v-if="alertState === 'submitting'">
											<span class="spinner-border spinner-border-sm me-2" role="status"></span>
											Broadcasting...
										</template>
										<template v-else>
											<font-awesome-icon :icon="['fas', 'satellite-dish']" class="me-2" />
											Broadcast Emergency Alert
											<font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="ms-2" />
										</template>
									</button>
								</p>
							</template>
							<div v-else class="alert alert-warning">
								Please select at least one channel to send this alert.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import heyGovApi, { handleResponseError } from '@/api.js'
import { truncateString } from '../../lib/strings'

export default {
	name: 'EmergencyAlerts',
	components: {
		'help-button': () => import('@/components/HelpButton.vue')
	},
	data() {
		return {
			hasActiveAlert: false,
			alertPrompt: '',
			alertState: 'idle',
			alertId: null,
			emergencyAlert: {
				title: '',
				content: '',
				channels: ['website']
			},
		}
	},
	computed: {
		...mapState(['apiUrl', 'j']),
	},
	created() {
		this.checkActiveAlert()
	},
	methods: {
		checkActiveAlert() {
			heyGovApi
				.get(`${this.j.slug}/alerts`)
				.then(({ data }) => {
					this.hasActiveAlert = !!data?.data?.data?.length
					if (data?.data?.data?.length) {
						this.alertId = data.data.data[0].id
						this.emergencyAlert.title = data.data.data[0].title
						this.emergencyAlert.content = data.data.data[0].content
					}
				})
				.catch(handleResponseError('Failed to check active alert ({error})'))
		},

		generateEmergencyAlert() {
			this.alertState = 'generating'
			console.log('Generating emergency alert with prompt:', this.alertPrompt)

			heyGovApi
				.post(`${this.j.slug}/generate-draft`, {
					prompt: `Generate emergency alert for ${this.j.name} about this prompt: ${this.alertPrompt}`,
				})
				.then(({ data }) => {
					this.emergencyAlert.title = data.draft?.website?.title || ''
					this.emergencyAlert.content = data.draft?.website?.body || ''
					Vue.toasted.success(`Generated draft for "${truncateString(this.alertPrompt, 22)}"`)
				})
				.catch(handleResponseError('Failed to generate alert content ({error})'))
				.finally(() => {
					this.alertState = 'idle'
				})
		},

		createEmergencyAlert() {
			if (!this.emergencyAlert.title) {
				Vue.toasted.error('Please enter alert title')
				return
			}

			if (!this.emergencyAlert.content) {
				Vue.toasted.error('Please enter alert content')
				return
			}

			if (!this.emergencyAlert.channels.length) {
				Vue.toasted.error('Please select at least one channel to send this alert.')
				return
			}

			this.alertState = 'submitting'

			const channels = [
				{
					slug: "website_ticker",
					title: this.emergencyAlert.title,
					content: this.emergencyAlert.content
				}
			]

			const payload = {
				name: this.emergencyAlert.title,
				image_prompt: '',
				scheduled_date: "",
				status: "queued",
				image_url: '',
				task: {
					slug: "emergency_alerts"
				},
				channels
			}

			heyGovApi
				.post(`${this.j.slug}/routines-create`, payload)
				.then(() => {
					Vue.toasted.success('Emergency alert sent successfully')
					this.hasActiveAlert = true
					this.alertPrompt = ''
				})
				.catch(handleResponseError('Failed to send emergency alert ({error})'))
				.finally(() => {
					this.alertState = 'idle'
				})
		},

		removeAlert() {
			if (!this.alertId) {
				Vue.toasted.error('No active alert to remove')
				return
			}

			this.alertState = 'deleting'

			heyGovApi
				.delete(`${this.j.slug}/alerts/${this.alertId}`)
				.then(() => {
					Vue.toasted.success('Emergency alert removed')
					this.hasActiveAlert = false
					this.alertId = null
					this.emergencyAlert.title = ''
					this.emergencyAlert.content = ''
				})
				.catch(handleResponseError('Failed to remove emergency alert ({error})'))
				.finally(() => {
					this.alertState = 'idle'
				})
		}
	}
}
</script>

<style scoped>

@keyframes alert-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.4);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
	}
}

.alert-pulse {
	animation: alert-pulse 2s infinite;
}

@keyframes ticker {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

.ticker-scroll:hover {
	animation-play-state: paused;
}

.ticker-content {
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	margin: 0;
	padding: 0;
}

.ticker-scroll {
	display: inline-block;
	padding-left: 100%;
	animation: ticker 20s linear infinite;
	white-space: nowrap;
}

</style>
