<template>
	<div class="page-meetings" @dragover="dragover" @dragleave="dragleave" @drop="dropMeetingAgendaFile">
		<div class="row align-items-center justify-content-between mb-2">
			<div class="col-auto">
				<h3>Meetings</h3>
			</div>
			<div class="col-auto d-none d-lg-block">
				<div class="input-group input-group-sm">
					<input type="search" v-model="searchQuery" class="form-control" placeholder="Search in meetings" />
					<select class="form-select" style="max-width: 170px">
						<option :value="null">All categories</option>
						<option v-for="category in meetingCategories" :key="category.id" :value="category.id">{{
							category.name
						}}</option>
					</select>
				</div>
			</div>
			<div v-if="!$route.query.unlocked" class="col-auto">
				<router-link :to="`/${j.slug}/billing?refer=1`" class="badge bg-danger-50 text-danger-400"
					>Refer, get 1 month free</router-link
				>
			</div>
			<div class="col-auto">
				<router-link :to="`/${j.slug}/meetings/create`" class="btn btn-sm btn-big-icon btn-primary"
					><font-awesome-icon :icon="['fas', 'plus-circle']" /> Add meeting</router-link
				>
			</div>
		</div>

		<div v-if="states.agenda_file === 'loading'" class="alert alert-info">
			<span class="spinner-border spinner-border-sm"></span> Uploading &amp; processing agenda..
			<small class="text-neutral-400">(shouldn't take more than 1 minute)</small>
		</div>

		<div
			v-if="
				subscription &&
					subscription.status === 'trialing' &&
					stripeDateDiffDays(subscription.trial_end) / (3600 * 24) < 7
			"
			class="bg-primary-50 p-3 rounded-1 my-4"
		>
			<div v-if="subscription.cancel_at_period_end" class="row align-items-center">
				<div class="col">
					<p class="mb-0">
						Your ClerkMinutes trial is <strong>ending {{ subscription.trial_end | dateToNow }}</strong
						>! Switch to a paid plan to keep using it.
					</p>
				</div>
				<div class="col-auto">
					<a class="btn btn-success" :href="`${apiUrl + j.slug}/billing/subscriptions/${subscription.id}`">
						<font-awesome-icon :icon="['fas', 'burst']" class="text-light me-1" /> Change plan
					</a>
				</div>
			</div>
			<div v-else class="row align-items-center">
				<div class="col">
					<p class="mb-0">
						Your ClerkMinutes trial is <strong>ending {{ subscription.trial_end | dateToNow }}</strong
						>! Upgrade to a paid plan to keep using it.
					</p>
					<!-- <p class="mb-0">
						You can also extend your trial by referring a friend.
						<router-link :to="`/${j.slug}/billing?refer=1`">Learn more about Refer-A-Clerk</router-link>
					</p> -->
				</div>
				<div class="col-auto">
					<a class="btn btn-success" :href="`${apiUrl + j.slug}/billing/subscriptions/${subscription.id}`">
						<font-awesome-icon :icon="['fas', 'burst']" class="text-light me-1" /> Subscribe now
					</a>
				</div>
			</div>
		</div>

		<div v-if="states.isSearch" class="py-2">
			<div v-if="states.search === 'loading'" class="text-center">
				<span class="spinner-border spinner-border-sm"></span> Searching for "<code>{{ searchQuery }}</code
				>"
			</div>
			<div v-else>
				<div v-for="meeting in searchResults" :key="meeting.id" class="card mb-3">
					<div class="card-body">
						<div class="row">
							<div class="col-auto">
								<div class="border rounded-1">
									<div
										class="bg-success-50 text-success-400 text-center px-2"
										style="border-radius: 1rem 1rem 0 0; min-width: 95px"
									>
										<strong>{{
											new Date(meeting.starts_at).toLocaleString('default', {
												month: 'long',
												timeZone: j.timezone,
											})
										}}</strong>
									</div>
									<div class="text-center p-1 fw-light fs-2">
										<strong>{{
											new Date(meeting.starts_at).toLocaleString('default', {
												day: 'numeric',
												timeZone: j.timezone,
											})
										}}</strong>
									</div>
								</div>
							</div>
							<div class="col">
								<h5 class="mb-1">
									<router-link :to="`/${j.slug}/meetings/${meeting.pid}`">
										<span
											v-if="meeting.search_meeting"
											v-html="meeting.search_meeting._formatted.title"
										></span>
										<template v-else>{{ meeting.title }}</template>
									</router-link>

									<span
										v-if="meeting.status !== 'publish'"
										class="badge bg-neutral-50 text-neutral-500 ms-2"
										>{{ meeting.status }}</span
									>
								</h5>

								<p class="mb-1 text-neutral-500">
									{{
										new Date(meeting.starts_at).toLocaleString('default', {
											dateStyle: 'medium',
											timeStyle: 'short',
											timeZone: j.timezone,
										})
									}}
									<span v-if="meeting.department_id">
										&middot; Department [{{ meeting.department_id }}]
									</span>
									<span v-if="meeting.categories && meeting.categories.length">
										&middot; Category {{ meeting.categories }}
									</span>
								</p>

								<p
									v-if="meeting.search_meeting"
									class="mb-0"
									v-html="meeting.search_meeting._formatted.content"
								></p>
								<p v-else class="mb-0" v-html="meeting.description"></p>
							</div>
						</div>

						<div v-if="meeting.search_agenda.length" class="mt-3">
							<p class="mb-1 subtitle text-neutral-400">Found in agenda</p>
							<div v-html="meeting.search_agenda[0]._formatted.content"></div>
						</div>

						<div v-if="meeting.search_transcript.length" class=" mt-3">
							<p class="mb-1 subtitle text-neutral-400">Transcript</p>

							<div class="card-table">
								<table class="table table-hover table-sm">
									<tbody>
										<tr v-for="line in meeting.search_transcript" :key="line.id">
											<td class="text-end">
												<span class="badge bg-neutral-50 text-primary-400">{{
													timestampToMinutes(line.timestamp)
												}}</span>
											</td>
											<td>
												<div
													v-if="line._formatted.content"
													v-html="line._formatted.content"
												></div>
												<template v-else>{{ line.content }}</template>
											</td>
											<td v-if="isStaff">
												<span class="badge bg-danger-50 text-danger-400">{{
													line._semanticScore
												}}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<!-- <div
					v-for="(result, index) in searchResults"
					:key="index"
					class="meeting-search-result bg-white py-1 rounded-1 mb-3"
				>
					<router-link
						:to="`/${j.slug}/meetings/${result.meeting.pid}`"
						class="result-meeting-title d-block hover px-3 py-1"
					>
						{{ result.meeting.title }} &middot;
						<span class="text-neutral-500">{{ result.meeting.starts_at | dateLocal }}</span>
					</router-link>

					<router-link
						v-for="(line, index2) in result.transcript"
						:key="index2"
						:to="`/${j.slug}/meetings/${result.meeting.pid}?ts=${line.timestamp}`"
						class="result-meeting-transcript d-block px-3 py-1 hover"
					>
						<span class="text-primary-400">{{ timestampToMinutes(line.timestamp) }}</span>
						<span v-if="isStaff" class="badge bg-neutral-100 text-danger-200 ms-1">{{
							line.score.toFixed(2)
						}}</span>
						&middot; {{ line.texts.join(' ') }}
					</router-link>
				</div> -->
			</div>
		</div>
		<div v-else class="card mb-2">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover table-borderless rounded bg-white">
						<thead>
							<tr>
								<th>Meeting</th>
								<th>Category</th>
								<th>Date <small class="text-neutral-400">⏷</small></th>
								<th>Agenda</th>
								<th><small>Transcript</small></th>
								<th>Minutes</th>
								<td v-if="isStaff" class="text-end text-danger-400">$AI</td>
							</tr>
						</thead>
						<tbody>
							<router-link
								v-for="meeting in meetings"
								:key="`meeting-${meeting.id}`"
								class="on-parent cursor-pointer"
								:to="`/${j.slug}/meetings/${meeting.pid}`"
								tag="tr"
							>
								<td>
									{{ meeting.title }}
									<small
										v-if="!['public', 'publish'].includes(meeting.status)"
										class="badge bg-warning-50 text-warning"
										>{{ meeting.status }}</small
									>
								</td>
								<td>
									<template v-for="catId in meeting.categories">
										<span
											v-if="meetingCategories.find(cat => cat.id == catId)"
											:key="catId"
											class="badge bg-light text-dark"
											>{{ meetingCategories.find(cat => cat.id == catId).name }}</span
										>
									</template>
									<small
										v-if="!meeting.categories || !meeting.categories.length"
										class="text-neutral-300"
										>-</small
									>
								</td>
								<td>
									{{ meeting.starts_at | dateLocal(undefined, { timeZone: j.timezone }) }}
									<span
										v-if="new Date(meeting.starts_at) > new Date()"
										class="badge bg-primary-50 text-primary"
										>Scheduled</span
									>
								</td>
								<td>
									<span v-if="!meeting.agenda_items.length" class="text-neutral-300">-</span>
									<span
										v-else-if="meeting.agenda_status === 'draft'"
										class="badge bg-warning-50 text-warning-400"
										>{{ meeting.agenda_items.length }} items, DRAFT</span
									>
									<span
										v-else-if="meeting.agenda_status === 'in_review'"
										class="badge bg-primary-50 text-primary-400"
										>{{ meeting.agenda_items.length }} items, REVIEW</span
									>
									<span
										v-else-if="meeting.agenda_status === 'public'"
										class="badge bg-success-50 text-success-400"
										>{{ meeting.agenda_items.length }} items</span
									>
									<code v-else>{{ meeting.agenda_status }}</code>
								</td>
								<td>
									<span
										v-if="['uploading', 'started'].includes(meeting.transcript_job_status)"
										class="spinner-border spinner-border-sm"
									></span>
									<span v-else-if="meeting.transcript_job_status === 'transcribed'">✅</span>
									<span v-else-if="['error', 'upload-error'].includes(meeting.transcript_job_status)">
										⚠️
									</span>
									<span v-else class="text-neutral-300">-</span>
								</td>
								<td>
									<span
										v-if="meeting.minutes_status === 'generating'"
										class="spinner-border spinner-border-sm"
									></span>
									<font-awesome-icon
										v-else-if="meeting.minutes_status === 'manual-upload'"
										:icon="['fas', 'file-pdf']"
										class="text-danger-500"
									/>

									<span v-else-if="['done', 'fresh-done'].includes(meeting.minutes_status)">✅</span>
									<span v-else-if="meeting.minutes_status === 'missing'" class="text-neutral-300"
										>-</span
									>
									<code v-else>{{ meeting.minutes_status }}</code>

									<span v-if="isStaff && meeting.minutes_rating" class="badge bg-light text-dark ms-1"
										>⭐️ {{ meeting.minutes_rating }}</span
									>
								</td>
								<td v-if="isStaff" class="text-end">
									<span :class="meeting.ai_cost ? '' : 'text-neutral-300'">{{
										meeting.ai_cost | currency
									}}</span>
								</td>
							</router-link>
						</tbody>
						<tfoot>
							<tr v-if="state === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="state === 'loaded' && !meetings.length">
								<td colspan="9" class="text-center py-3">
									<div v-if="hasActiveFilters">
										<img
											src="https://edge.heygov.com/illustrations/arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No meetings found. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://edge.heygov.com/illustrations/arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead mb-5">
											<router-link :to="`/${j.slug}/meetings/create`"
												>Create the first meeting</router-link
											>
											or just drag'n'drop an existing agenda here.
										</p>
										<p>
											🙋
											<a
												href="https://townweb.notion.site/ClerkMinutes-4369efc8cd84477880c0b0d9e399ac58"
												target="clerk-minutes-help"
												>Read or watch the ClerkMinutes guide on how to use all features</a
											>
										</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>

		<div
			v-if="!states.isSearch && !(state === 'loaded' && !meetings.length)"
			class="row justify-content-center d-none d-lg-flex"
		>
			<div class="col-6">
				<div class="bg-warning-50 text-neutral-400 rounded-1 border border-dashed text-center py-1">
					<small>Drop an agenda here to create a meeting</small>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.page-meetings.dragover {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		min-height: 500px;
		z-index: 1;
		background-color: $warning-50;
		border: 1px dashed $warning-100;
		border-radius: 0.25rem;
		backdrop-filter: blur(3px);
		background-color: rgba(255, 243, 232, 0.5);
		content: 'Yeees, drop it! Give me that agenda!';
		font-size: 28px;
		padding-top: 3rem;
		text-align: center;
	}
}

.meeting-search-result {
	.result-meeting-title {
		font-size: 1.25rem;
		font-weight: 500;
		color: #222;
	}

	.result-meeting-transcript {
		color: #444;
	}

	a:hover {
		text-decoration: none;
	}
}
</style>

<script>
import { fromUnixTime } from 'date-fns'
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'vue-debounce'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { handleResponseError, sendEvent } from '@/utils.js'

export default {
	name: 'MeetingsIndex',
	metaInfo: {
		title: 'ClerkMinutes',
	},
	data() {
		return {
			states: {
				agenda_file: 'idle',
				isSearch: false,
				search: 'idle',
			},
			state: 'loading',
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('meetings-per-page') || 100,
			},
			filters: {
				department: null,
			},
			meetings: [],
			meetingCategories: [],

			searchQuery: this.$route.query.q || '',
			searchReqController: null,
			searchResults: [],

			subscription: null,
		}
	},
	computed: {
		...mapState(['apiUrl', 'j', 'people']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		doSearchDebounced() {
			return debounce(this.doSearch, 500)
		},
	},
	created() {
		if (this.auth) {
			this.loadMeetingCategories()
			this.loadMeetings()
			this.$store.dispatch('loadDepartments')

			if (this.currentRole !== 'CITIZEN') {
				this.loadBilling()
			}

			if (this.$route.query.q) {
				this.states.isSearch = true
				this.doSearch(this.$route.query.q)
			}

			sendEvent('Load Meetings', {
				feature: 'ClerkMinutes',
			})
		} else {
			this.$router.push({
				name: 'Login',
				query: {
					redirect: this.$route.path,
					message: 'Please login to access ClerkMinutes',
				},
			})
		}
	},
	methods: {
		loadMeetingCategories() {
			heyGovApi
				.get(`${this.j.slug}/meetings/categories`)
				.then(({ data }) => {
					this.meetingCategories.push(...data)
				})
				.catch(handleResponseError(`Couldn't load meeting categories`))
		},
		loadMeetings() {
			this.state = 'loading'
			this.meetings = []

			heyGovApi(`${this.j.slug}/meetings`, {
				params: {
					...this.filters,
					page: this.pag.page,
					limit: this.pag.limit,
				},
			})
				.then(({ data }) => {
					this.meetings.push(...data)
				}, handleResponseError(`Couldn't load meetings ({error})`))
				.finally(() => {
					this.state = 'loaded'
				})
		},

		loadBilling() {
			heyGovApi(`${this.j.slug}/billing`).then(({ data }) => {
				const cmPricesLive = [
					'price_1OeHcULn2cdp6hSM9ZOzEDmC',
					'price_1OeHc0Ln2cdp6hSMgauavyL5',
					'price_1OeHbQLn2cdp6hSMrLRTIvgS',
					'price_1OeHayLn2cdp6hSM5k0OSIzz',
				]
				const cmPricesTest = [
					'price_1OkSIcLn2cdp6hSMBpd9vrbx',
					'price_1OkSHxLn2cdp6hSM5fc4q1ET',
					'price_1OkSKKLn2cdp6hSMg6wlgmEU',
					'price_1OkSJeLn2cdp6hSMVVY54Mcf',
				]

				this.subscription = data.subscriptions.find(subscription => {
					return cmPricesLive.includes(subscription.plan?.id) || cmPricesTest.includes(subscription.plan?.id)
				})
			})
		},

		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},
		dropMeetingAgendaFile(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.uploadMeetingAgenda(event.dataTransfer.files[0])
			} else {
				alert('So sorry, only PDF files are allowed 🤷')
			}
		},
		uploadMeetingAgenda(file) {
			const size = file.size / 1024 / 1024

			if (size > 30) {
				alert('File size is too big (max 30MB)')
			} else if (
				['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(
					file.type
				)
			) {
				this.states.agenda_file = 'loading'

				// prepare file data
				var form = new FormData()
				form.append('file', file)

				heyGovApi
					.post(`${this.j.slug}/meetings/create-from-agenda`, form)
					.then(({ data }) => {
						this.states.agenda_file = 'loading'
						Vue.toasted.success(`Meeting ${data.title} is created`)

						this.$router.push(`/${this.j.slug}/meetings/${data.pid}`)
					}, handleResponseError('Error processing agenda ({error})'))
					.finally(() => {
						this.states.agenda_file = 'idle'
					})
			} else {
				alert('So sorry, only .pdf and .docx files are allowed 🤷')
			}
		},

		doSearch() {
			this.searchResults = []

			this.states.search = 'loading'

			if (this.searchReqController) {
				this.searchReqController.abort()
			}

			this.searchReqController = new AbortController()

			heyGovApi
				.get(`${this.j.slug}/meetings/search`, {
					signal: this.searchReqController.signal,
					params: {
						q: this.searchQuery,
					},
				})
				.then(
					({ data }) => {
						this.states.search = 'loaded'
						this.searchResults.push(...data)
					},
					error => {
						if (error.message !== 'canceled') {
							this.states.search = 'error'
							handleResponseError('Error searching meetings ({error})')(error)
						}
					}
				)

			this.$router
				.replace({
					name: `Meetings`,
					params: {
						jurisdiction: this.j.slug,
					},
					query: {
						q: this.searchQuery,
					},
				})
				.catch(() => {})

			sendEvent('meetings.search', {
				feature: 'meetings',
				group: 'meetings',
				query: this.searchQuery,
			})
		},

		timestampToMinutes(timestamp) {
			const minutes = Math.floor(timestamp / 60)
			const seconds = Math.round(timestamp % 60)

			return `${minutes}:${String(seconds).padStart(2, '0')}`
		},

		stripeDateDiffDays(date) {
			return (fromUnixTime(date).getTime() - Date.now()) / 1000
		},
	},
	watch: {
		searchQuery() {
			if (this.searchQuery.length > 5) {
				this.states.isSearch = true
				this.states.search = 'loading'
				this.doSearchDebounced()
			} else if (this.states.isSearch) {
				this.states.isSearch = false

				this.$router.replace({
					name: `Meetings`,
					params: { jurisdiction: this.j.slug },
				})
			}
		},
	},
}
</script>
