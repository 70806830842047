<template>
	<div class="page-about">
		<ul class="nav nav-tabs mb-4">
			<li class="nav-item">
				<router-link to="/dev/debug" class="nav-link active">API &amp; Debug</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/dev/ui-kit" class="nav-link">UI Kit</router-link>
			</li>
		</ul>

		<h3 class="mb-2">
			App version: <code>{{ version }}</code>
		</h3>

		<h3 class="mb-2">
			Platform: <code>{{ device.platform }}</code>
		</h3>

		<h3 class="mb-3">
			Environment: <code>{{ env }}</code>
		</h3>

		<h3>
			API endpoint: <code>{{ apiUrl }}</code>
		</h3>

		<p class="mb-1">Switch API endpoint to:</p>
		<ul class="mb-5">
			<li>
				<button
					class="btn btn-outline-primary me-2 my-2"
					@click="setApiEndpoint($event, 'https://api.heygov.com/')"
				>
					<strong>Production</strong> - <code>https://api.heygov.com/</code>
				</button>
			</li>
			<li>
				<button
					class="btn btn-outline-info me-2 my-2"
					@click="setApiEndpoint($event, 'https://heygov-api-main-438038920523.us-central1.run.app/')"
				>
					<strong>#main</strong> - <code>https://heygov-api-main-438038920523.us-central1.run.app/</code>
				</button>
			</li>
			<li>
				<button class="btn btn-outline-dark me-2 my-2" @click="setApiEndpoint($event, 'http://localhost:808/')">
					<strong>local dev</strong> - <code>http://localhost:808/</code>
				</button>
			</li>
			<li>
				<form @submit.prevent="setApiEndpoint">
					<input
						type="url"
						required
						v-model="customApiUrl"
						class="form-control form-control-sm d-inline-block me-2"
						style="max-width:200px"
						placeholder="Your custom endpoint"
					/>
					<button class="btn btn-sm btn-outline-dark">Use</button>
				</form>
			</li>
		</ul>

		<h3 class="mb-1">📱 Device info:</h3>
		<pre class="mb-5">{{ device }}</pre>

		<h3 class="mb-1">🔗 window.location:</h3>
		<pre class="mb-5">{{ { href: location.href, host: location.host, origin: location.origin } }}</pre>

		<h3 class="mb-1">🤷 Reset to factory settings</h3>
		<p><button class="btn btn-sm btn-danger px-5" @click="$store.dispatch('reset')">Reset</button></p>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { version } from '@/../package.json'

export default {
	name: 'About',
	data() {
		return {
			version,
			customApiUrl: '',
			location: window.location,
		}
	},
	computed: {
		...mapState(['apiUrl', 'env', 'device']),
	},
	methods: {
		setApiEndpoint($event, url) {
			this.$store.commit('setApiUrl', url || this.customApiUrl)
			location.reload()
		},
	},
}
</script>
