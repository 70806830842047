<template>
	<div class="threads-page">
		<div class="row align-items-center mb-4">
			<div class="col">
				<h3 class="my-0">Departments &amp; Members</h3>
			</div>
			<div class="col-auto">
				<a
					href="https://www.notion.so/townweb/HeyGov-Core-departments-members-and-permissions-d43af86cb33441feb2057ac979018b44?pvs=4"
					target="heygov-help"
					class="d-inline-block bg-danger-50 text-neutral-500 px-3 py-2 rounded-1 me-3"
					>🙋 How to manage Departments &amp; Members</a
				>

				<button
					v-if="currentRole === 'ADMIN'"
					class="btn btn-primary btn-sm btn-big-icon ms-2"
					data-bs-toggle="modal"
					data-bs-target="#department-edit"
					@click="departmentSelected = departmentNew"
				>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add department
				</button>
			</div>
		</div>

		<div
			v-for="department in shownDepartments"
			:key="department.id"
			:id="`department-${department.id}`"
			class="card mb-3"
		>
			<div class="card-header bg-white" :class="{ 'rounded-bottom': !states['dept' + department.id] }">
				<div class="row align-items-center">
					<div class="col-auto">
						<label :for="`department-logo-${department.id}`" class="cursor-pointer mb-0"
							><img
								:src="
									department.logo ||
										`https://eu.ui-avatars.com/api/?size=100&background=${department.color.slice(
											1
										)}&name=${department.name}`
								"
								class="rounded shadow"
								width="55"
								height="55"
								:alt="department.name"
						/></label>
						<input
							type="file"
							:id="`department-logo-${department.id}`"
							@change="uploadDepartmentLogo($event, department)"
							class="d-none"
						/>
					</div>
					<div class="col">
						<h5 class="my-1">
							<span
								class="cursor-pointer"
								data-bs-toggle="modal"
								data-bs-target="#department-edit"
								@click="departmentSelected = department"
								>{{ department.name }}</span
							>
							<small
								v-if="department.status === 'archived'"
								class="badge bg-danger-lighter text-danger ms-2"
								>Archived</small
							>
						</h5>

						<label :for="`dept-${department.id}-email-notifications`" class="mb-0">{{
							department.email
						}}</label>
						<div
							class="form-check form-switch text-muted d-inline-block ms-2"
							data-bs-toggle="tooltip"
							title="Send email notifications to department email"
						>
							<input
								v-if="canEditDept(department.id)"
								type="checkbox"
								class="form-check-input"
								value="1"
								@change="updateDepartmentNotifications(department)"
								v-model="department.notifications"
								:id="`dept-${department.id}-email-notifications`"
							/>
							<label class="form-check-label" :for="`dept-${department.id}-email-notifications`"></label>
						</div>
					</div>
					<div v-if="department.stripe_account && canViewDept(department.id)" class="col-auto">
						<router-link
							:to="`/${j.slug}/payments/${department.id}`"
							class="bg-light rounded py-1 px-2 text-truncate text-dark"
							style="max-width: 250px"
							title="Go to Payments Account"
						>
							<font-awesome-icon
								v-if="department.stripe_account_status === 'started'"
								:icon="['fas', 'money-bill-wave']"
								class="text-danger-300"
								title="Required info needed"
							/>
							<font-awesome-icon
								v-else-if="department.stripe_account_status === 'waiting'"
								:icon="['fas', 'money-bill-wave']"
								class="text-warning-200"
								title="Waiting for verification & activation from Stripe"
							/>
							<font-awesome-icon v-else :icon="['fas', 'money-bill-wave']" />
							{{ department.legal_name || department.stripe_account }}
						</router-link>
					</div>
					<div
						v-if="!states['dept' + department.id]"
						class="col-auto pe-0"
						@click="toggleDepartment(department)"
					>
						<department-people :department="department" :size="40" :max="3"></department-people>
					</div>
					<div class="col-auto" @click="toggleDepartment(department)">
						<p class="mb-0">
							<small class="text-uppercase"><strong>Department members</strong></small>
						</p>
						<p class="mb-0 text-muted">
							{{
								pluralize('member', people.filter(p => p.department_id === department.id).length, true)
							}}
						</p>
					</div>
					<div class="col-auto" @click="toggleDepartment(department)">
						<font-awesome-icon
							:icon="['fas', `chevron-${states['dept' + department.id] ? 'up' : 'down'}`]"
							class="text-muted fs-4"
						/>
					</div>
				</div>
			</div>
			<div v-if="states['dept' + department.id]" class="card-body px-0">
				<div class="row align-items-center mb-3">
					<div class="col">
						<h6 class="mb-0 px-3">Department members:</h6>
					</div>
					<div class="col text-end pe-4">
						<button
							v-if="canEditDept(department.id)"
							class="btn btn-sm btn-outline-primary"
							data-bs-toggle="modal"
							data-bs-target="#person-edit"
							@click="
								personNew.department_id = department.id
								personSelected = personNew
							"
						>
							Add department member
						</button>
					</div>
				</div>

				<table class="table table-hover mb-4">
					<thead>
						<tr>
							<th class="ps-3">Member</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Title</th>
							<th>HeyGov Role</th>
							<th>Notifications</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="person in people.filter(p => p.department_id === department.id)"
							:key="person.id"
							class="on-parent"
						>
							<td class="ps-3">
								<person-link :id="person.person_id" :avatar="24">{{ person.name }}</person-link>
							</td>
							<td>{{ person.email }}</td>
							<td></td>
							<td>{{ person.title || '' }}</td>
							<td>
								<span class="badge" :class="[roles[person.role].class]">{{
									roles[person.role].name
								}}</span>

								<p
									v-if="
										person.email &&
											(!person.last_login_at || person.last_login_at < person.created_at)
									"
									class="mb-0 mt-2"
								>
									<button class="btn btn-sm rounded btn-outline-info" @click="resendInvite(person)">
										Pending invite - resend
									</button>
								</p>
							</td>
							<td>
								<template v-for="(channels, notification) in person.notifications">
									<span
										v-if="notification.startsWith('clerks')"
										:key="notification"
										class="badge my-1 me-1"
										:class="
											channels.length
												? 'bg-success-50 text-success-400'
												: 'bg-neutral-100 text-neutral-400'
										"
										:title="`Enabled channels: ${channels.join(', ')}`"
									>
										{{ channels.length ? '✓' : '' }} {{ notification.replace('clerks', '') }}
									</span>
								</template>
							</td>
							<td>
								<button
									v-if="canEditDept(department.id)"
									class="btn btn-sm btn-outline-primary float-end show-on-hover"
									data-bs-toggle="modal"
									data-bs-target="#person-edit"
									@click="personSelected = person"
								>
									Edit
								</button>
							</td>
						</tr>
						<tr v-if="!people.filter(p => p.department_id === department.id).length">
							<td colspan="8" class="text-center text-muted">
								<p>👥 No people added in this department yet</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="row mt-4 mb-5">
			<div class="col-md-6">
				<h6>What HeyGov roles mean?</h6>
				<ul>
					<li v-for="role in roles" :key="role.name">
						<span class="badge" :class="[role.class]">{{ role.name }}</span> {{ role.text }}
					</li>
				</ul>
			</div>
			<div v-if="isStaff || currentRole === 'ADMIN'" class="col-6 text-end">
				<div class="form-check form-switch form-check-inline">
					<input type="checkbox" class="form-check-input" id="show-archived" v-model="states.showArchived" />
					<label class="form-check-label" for="show-archived"> Show archived departments?</label>
				</div>
			</div>
		</div>

		<div class="modal fade" id="person-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="personSelected" class="modal-content">
					<form @submit.prevent="savePerson">
						<div class="modal-header">
							<h5 class="modal-title">{{ personSelected.id ? 'Edit member' : 'Add member' }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="person-first-name" class="form-label"
									>Name <small class="text-danger-400">*</small></label
								>

								<div class="row gx-2">
									<div class="col-md-6">
										<div class="form-floating">
											<input
												type="text"
												class="form-control"
												id="person-first-name"
												v-model="personSelected.first_name"
												required
												:readonly="personSelected.id"
												aria-describedby="personNameHelp"
											/>
											<label for="person-first-name">First name</label>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-floating">
											<input
												type="text"
												class="form-control"
												id="person-last-name"
												v-model="personSelected.last_name"
												:readonly="personSelected.id"
												aria-describedby="personNameHelp"
											/>
											<label for="person-last-name">Last name</label>
										</div>
									</div>
								</div>

								<small id="personNameHelp" class="form-text text-muted"
									>Use person's real name please</small
								>
							</div>

							<div class="form-group mb-3">
								<label for="person-email" class="form-label">Email address (optional)</label>
								<input
									type="email"
									class="form-control"
									id="person-email"
									:readonly="personSelected.id"
									v-model="personSelected.email"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="person-phone" class="form-label">Mobile phone</label>
								<input
									type="text"
									class="form-control"
									id="person-phone"
									:readonly="personSelected.id"
									v-model="personSelected.phone"
								/>
							</div>
							<div v-if="personSelected.id" class="alert alert-info">
								"{{ personSelected.first_name }}" can change their personal info in their account
								settings.
							</div>

							<div class="form-group mb-3">
								<label for="person-title" class="form-label">Title</label>
								<input
									type="text"
									class="form-control"
									id="person-title"
									v-model="personSelected.title"
									placeholder="e.g. Chief of Staff"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="person-role" class="form-label">Role in HeyGov</label>
								<select class="form-select" id="person-role" required v-model="personSelected.role">
									<option v-for="(role, roleId) in roles" :key="roleId" :value="roleId">{{
										role.name
									}}</option>
								</select>
							</div>

							<div v-if="!personSelected.id && personSelected.email" class="row">
								<div class="col form-group mb-3">
									<label for="send-invite-email" class="form-label me-2">Send Invite Email</label>
									<input
										type="checkbox"
										class="form-check-input"
										id="send-invite-email"
										v-model="personSelected.sendInviteEmail"
									/>
								</div>

								<div v-if="isStaff && !personSelected.id" class="col form-group mb-3">
									<label for="person-email-template" class="form-label">
										Template
									</label>

									<select
										class="form-select"
										id="person-email-template"
										required
										v-model="emailTemplate"
									>
										<option value="invite">Dept invite email</option>
										<option value="clerkminutes">ClerkMinutes</option>
									</select>
								</div>
							</div>

							<div
								v-if="!personSelected.id && personSelected.email && personSelected.sendInviteEmail"
								class="alert alert-info"
							>
								An email message will be sent to {{ personSelected.first_name || 'the member' }}, so
								they can finish setting up their account.
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button
								v-if="personSelected.id"
								type="button"
								class="btn btn-sm text-danger"
								@click.prevent="removePerson"
							>
								Remove from department
							</button>
							<button class="btn btn-primary px-5">
								{{ personSelected.id ? 'Save info' : 'Send invite' }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="department-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="departmentSelected" class="modal-content">
					<form @submit.prevent="saveDepartment">
						<div class="modal-header">
							<h5 class="modal-title">
								{{ departmentSelected.id ? 'Edit department' : 'Add department' }}
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="department-name" class="form-label"
									>Department name <small class="text-danger-400">*</small></label
								>
								<input
									type="text"
									class="form-control"
									id="department-name"
									required
									v-model="departmentSelected.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="department-email" class="form-label"
									>Email <small class="text-danger-400">*</small></label
								>
								<input
									type="email"
									class="form-control"
									id="department-email"
									required
									v-model="departmentSelected.email"
									aria-describedby="departmentEmailHelp"
								/>
								<small id="departmentEmailHelp" class="form-text text-muted"
									>Department's public email</small
								>
							</div>

							<div class="form-group mb-3">
								<label for="department-phone" class="form-label">Phone</label>
								<vue-phone-number-input
									id="department-phone"
									v-model="departmentSelected.phone"
									:default-country-code="defaultCountry"
									:preferred-countries="preferredCountries"
									class="mb-2"
									@update="setPhoneNumber"
									:border-radius="20"
								/>
								<small id="departmentEmailHelp" class="form-text text-muted"
									>Department's public phone number</small
								>
							</div>

							<div class="form-group mb-3">
								<label for="department-location" class="form-label">Address</label>

								<gmap-autocomplete
									v-if="departmentSelected"
									:options="autocompleteOptions"
									:select-first-on-enter="true"
									@place_changed="setDepartmentLocation"
								>
									<template v-slot:default="slotProps">
										<input
											id="department-location"
											class="form-control"
											ref="input"
											:value="
												departmentSelected.location ? departmentSelected.location.address : ''
											"
											placeholder="Department address"
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>
						</div>

						<div class="modal-footer justify-content-between">
							<button
								v-if="departmentSelected.id && departmentSelected.status === 'archived'"
								type="button"
								class="btn btn-sm text-info"
								@click.prevent="restoreDepartment"
							>
								Restore department
							</button>
							<button
								v-else-if="departmentSelected.id"
								type="button"
								class="btn btn-sm text-danger"
								@click.prevent="archiveDepartment"
							>
								Archive department
							</button>

							<button class="btn btn-primary">
								{{ departmentSelected.id ? 'Save' : 'Create department' }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="stripe-connect" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Connect Stripe account
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p class="lead">
							A Stripe account was created, and your {{ j.type }} is one step closer to accept online
							payments!
						</p>

						<ol>
							<li>Create Stripe account ✅</li>
							<li>Business and representative verification ⚪️</li>
							<li>Bank account for payouts ⚪️</li>
							<li>Create a license, permit or venue ⚪️</li>
						</ol>

						<p class="text-center px-4">
							<a
								:href="stripeConnectUrl"
								class="btn btn-primary"
								:class="{ disabled: !stripeConnectUrl }"
							>
								<font-awesome-icon :icon="['fab', 'stripe-s']" class="me-2" />
								Continue with verification</a
							>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { random } from 'lodash'
import { Modal, Tooltip } from 'bootstrap'
import parsePhoneNumber from 'libphonenumber-js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import pluralize from 'pluralize'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'
import { validateEmail } from '@/lib/strings.js'

import DepartmentPeople from '../components/DepartmentPeople.vue'
import PersonLink from '../components/PersonLink.vue'

export default {
	name: 'Departments',
	components: { DepartmentPeople, PersonLink, VuePhoneNumberInput },
	data() {
		return {
			state: 'idle',
			states: {
				showArchived: false,
			},
			people: [],
			personSelected: null,
			personNew: {
				first_name: null,
				last_name: null,
				email: null,
				phone: null,
				department_id: null,
				role: 'ADMIN',
				sendInviteEmail: true,
			},
			departmentSelected: null,
			departmentNew: {
				name: '',
				email: '',
				location: {},
				phone: '',
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['formatted_address', 'geometry', 'name', 'place_id', 'url', 'icon', 'icon_mask_base_uri'],
			},

			$modalDepartment: null,
			$modalPerson: null,
			stripeAccounts: {},
			stripeConnectUrl: null,
			stripeAccountStatuses: {
				started: '🟡',
				waiting: '🔵',
				ready: '🟢',
			},
			defaultCountry: 'US',
			preferredCountries: ['US', 'CA'],
			pNumber: '',

			emailTemplate: 'invite',
		}
	},
	created() {
		this.$store.dispatch('loadDepartments').then(() => {
			document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
				new Tooltip(el)
			})

			this.scrollToDepartment()
		})
		this.loadPeople()
		this.preparePhoneNumber()
	},
	mounted() {
		this.$modalDepartment = new Modal(document.getElementById('department-edit'))
		this.$modalPerson = new Modal(document.getElementById('person-edit'))
	},
	computed: {
		...mapState(['j', 'roles', 'departments']),
		...mapGetters(['currentRole', 'isStaff']),
		shownDepartments() {
			if (!this.departments) {
				return []
			}

			const deps = this.departments.filter(d => d.status === 'active')

			if (this.states.showArchived) {
				deps.push(...this.departments.filter(d => d.status !== 'active'))
			}

			return deps
		},
	},
	methods: {
		pluralize,

		loadPeople() {
			heyGovApi.get(`/${this.j.slug}/departments/people`).then(({ data }) => {
				this.people.push(...data)
			}, handleResponseError(`Could not load members list ({error})`))
		},

		canViewDept(departmentId) {
			return this.currentRole === 'ADMIN' || this.j.roles.find(role => role.department_id === departmentId)
		},
		canEditDept(departmentId) {
			return (
				this.currentRole === 'ADMIN' ||
				this.j.roles.find(role => role.role === 'EDITOR' && role.department_id === departmentId)
			)
		},

		updatePerson(person, fields) {
			return new Promise((resolve, reject) => {
				heyGovApi.put(`/${this.j.slug}/departments/people/${person.id}`, fields).then(
					response => {
						Vue.toasted.show(`Details for "${person.name}" are updated`)
						resolve(response)
					},
					error => {
						Vue.toasted.error(`Error updating member (${error.message})`)
						reject(error)
					}
				)
			})
		},
		savePerson() {
			if (this.personSelected.email) {
				try {
					this.personSelected.email = validateEmail(this.personSelected.email)
				} catch (error) {
					alert(`Invalid email address (${error.message})`)
					return
				}
			}

			if (this.personSelected.id) {
				this.updatePerson(this.personSelected, {
					department_id: this.personSelected.department_id,
					role: this.personSelected.role,
					title: this.personSelected.title,
				}).then(() => {
					this.$modalPerson.hide()
				})
			} else {
				const newPerson = {
					...this.personSelected,
					emailTemplate: this.emailTemplate,
					email: this.personSelected.email ? this.personSelected.email : undefined,
					sendInviteEmail: this.personSelected.email ? this.personSelected.sendInviteEmail : undefined,
				}

				heyGovApi.post(`/${this.j.slug}/departments/people`, newPerson).then(({ data }) => {
					this.people.push(data)
					this.$modalPerson.hide()
					Vue.toasted.show(`"${this.personSelected.first_name}" is added`)

					// clear new person data
					this.personNew.first_name = null
					this.personNew.last_name = null
					this.personNew.email = null
					this.personNew.phone = null
					this.personNew.role = 'ADMIN'
				}, handleResponseError(`Error adding member ({error})`))
			}
		},
		removePerson() {
			if (confirm('For sure remove this person from this department?')) {
				heyGovApi.delete(`/${this.j.slug}/departments/people/${this.personSelected.id}`).then(() => {
					this.$modalPerson.hide()
					Vue.toasted.show(`"${this.personSelected.first_name}" is removed`)

					this.people = this.people.filter(p => p.id !== this.personSelected.id)
				}, handleResponseError(`Error removing member ({error})`))
			}
		},
		resendInvite(person) {
			heyGovApi.post(`/${this.j.slug}/departments/people/${person.id}/resend-invite`).then(() => {
				Vue.toasted.show(`Invite email for "${person.name}" is sent`)
			}, handleResponseError(`Couldn't resent the invite ({error})`))
		},
		setDepartmentLocation(place) {
			if (!this.departmentSelected.location || typeof this.departmentSelected.location !== 'object') {
				this.departmentSelected.location = {}
			}

			this.departmentSelected.location.name = place.name
			this.departmentSelected.location.address = place.formatted_address
			this.departmentSelected.location.place_id = place.place_id
			this.departmentSelected.location.lat = place.geometry.location.lat()
			this.departmentSelected.location.lng = place.geometry.location.lng()
			this.departmentSelected.location.url = place.url
			this.departmentSelected.location.icon = place.icon
			this.departmentSelected.location.icon_svg = `${place.icon_mask_base_uri}.svg`
		},
		setPhoneNumber(payload) {
			if (payload.isValid) {
				const phone = '+' + payload.countryCallingCode + ' ' + payload.phoneNumber
				if (this.departmentSelected.phone !== phone) {
					this.departmentSelected.phone = phone
				}
			}
		},
		preparePhoneNumber() {
			if (this.departmentSelected && this.departmentSelected.phone) {
				const parsed = parsePhoneNumber(this.departmentSelected.phone)

				if (parsed) {
					this.defaultCountry = parsed.country
					this.pNumber = parsed.nationalNumber
				} else {
					this.pNumber = this.departmentSelected.phone
				}
			}
		},

		toggleDepartment(department) {
			this.$set(this.states, 'dept' + department.id, !this.states['dept' + department.id])
		},

		updateDepartment(department, fields, message) {
			return new Promise((resolve, reject) => {
				heyGovApi.put(`/${this.j.slug}/departments/${department.id}`, fields).then(
					response => {
						Vue.toasted.show(message || `Department "${department.name}" is updated`)
						resolve(response)
					},
					error => {
						Vue.toasted.show(`Error updating department (${error.message})`)
						reject(error)
					}
				)
			})
		},
		updateDepartmentNotifications(department) {
			this.updateDepartment(department, {
				notifications: department.notifications ? 1 : 0,
			})
		},
		saveDepartment() {
			if (this.departmentSelected.id) {
				this.updateDepartment(this.departmentSelected, {
					name: this.departmentSelected.name,
					email: this.departmentSelected.email,
					location: this.departmentSelected.location,
					phone: this.departmentSelected.phone,
				}).then(() => {
					this.$modalDepartment.hide()
				})
			} else {
				heyGovApi.post(`/${this.j.slug}/departments`, this.departmentSelected).then(({ data }) => {
					if (data.location) {
						data.location = JSON.parse(data.location)
					}
					this.$store.commit('addDepartment', data)
					this.$modalDepartment.hide()
					Vue.toasted.show(`Department "${this.departmentSelected.name}" is added`)
					this.scrollToDepartment(data.id)
				}, handleResponseError(`Error adding department ({error})`))
			}
		},
		uploadDepartmentLogo($event, department) {
			Array.from($event.target.files).forEach(file => {
				// prepare image data
				var form = new FormData()
				form.append('logo', file)

				// upload the file
				heyGovApi.post(`/${this.j.slug}/departments/${department.id}/logo`, form).then(
					({ data }) => {
						department.logo = data.logo + `?cache-buster=` + random(9999)
						Vue.toasted.show(`Department "${this.departmentSelected.name}" logo is updated`)
					},
					error => {
						Vue.toasted.error(error)
					}
				)
			})
		},
		archiveDepartment() {
			if (this.people.filter(p => p.department_id === this.departmentSelected.id).length) {
				alert('Please remove all people from department first')
			} else if (confirm('For sure archive & hide this department from HeyGov?')) {
				this.departmentSelected.status = 'archived'
				this.$modalDepartment.hide()

				this.updateDepartment(this.departmentSelected, {
					status: 'archived',
				})
			}
		},
		restoreDepartment() {
			if (confirm('For sure restore this department?')) {
				this.departmentSelected.status = 'active'
				this.$modalDepartment.hide()

				this.updateDepartment(this.departmentSelected, {
					status: 'active',
				})
			}
		},
		scrollToDepartment(departmentId) {
			departmentId = departmentId || this.$route.query.joined || this.$route.query.department || null

			if (departmentId) {
				this.$set(this.states, 'dept' + departmentId, !this.states['dept' + departmentId])

				setTimeout(() => {
					document
						.getElementById(`department-${departmentId}`)
						.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}, 150)
			}
		},
	},
}
</script>
