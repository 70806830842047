<template>
	<div :class="{ 'modal-content': isForModal }">
		<form @submit.prevent="handleSubmit">
			<div v-if="isForModal" class="modal-header">
				<h5 class="modal-title my-0">{{ parentId ? 'Add sub-item' : 'Add agenda item' }}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div :class="{ 'modal-body': isForModal }">
				<ul v-if="isForModal" class="nav nav-tabs mb-4">
					<li class="nav-item">
						<span class="nav-link active">Typed manually</span>
					</li>
					<li class="nav-item">
						<span class="nav-link disabled"
							>Agenda items queue <span class="badge bg-neutral-100 text-dark">0</span></span
						>
					</li>
				</ul>
				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-title"
						>Agenda item <small class="text-danger-400">*</small></label
					>
					<input
						type="text"
						class="form-control"
						id="agenda-item-title"
						v-model="item.title"
						required
						minlength="3"
						placeholder="What should be discussed?"
					/>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-text">Text / Description</label>

					<textarea
						class="form-control"
						id="agenda-item-text"
						v-model="item.text"
						placeholder="More information about this agenda item (optional)"
					></textarea>
				</div>

				<div v-if="isForModal" class="form-row mb-3">
					<label class="form-label" for="agenda-item-internal-note">Internal note</label>
					<textarea
						class="form-control"
						id="agenda-item-internal-note"
						v-model="item.internal_note"
						placeholder="Comment that only logged in clerks see, to share notes about an agenda item (optional)"
					></textarea>
				</div>

				<div class="form-group mb-3">
					<label for="speaker-presenter" class="form-label">Speaker / Presenter</label>
					<div v-if="speaker">
						<person-link :id="speaker.id" :avatar="24">{{ speaker.name }}</person-link>
						<button class="btn btn-sm btn-outline-secondary ms-2" @click.prevent="clearSpeaker">
							Unlink speaker
						</button>
					</div>
					<div v-else>
						<people-search
							id="speaker-presenter"
							@person="setSpeaker"
							:limit="5"
							:required="false"
							:placeholder="'Name/email/phone of a person from jurisdiction'"
						></people-search>
					</div>
				</div>

				<div class="form-row">
					<label class="form-label" for="agenda-item-file">Attachments (documents or other files)</label>
					<draggable
						v-model="item.files"
						group="files"
						class="row row-cols-3 gap-0"
						:options="{ draggable: '.file-preview' }"
					>
						<div v-for="file in item.files" :key="getFileKey(file)" class="col mb-3 file-preview">
							<file-preview :file="file" @remove="removeFile" />
						</div>
						<div class="col mb-3">
							<label
								class="d-block bg-light border border-dashed rounded-1 p-3 text-center cursor-pointer"
							>
								<font-awesome-icon :icon="['fas', 'file']" class="text-primary mb-2" />

								<p class="mb-0">
									Upload file
								</p>

								<input
									type="file"
									class="form-control d-none"
									id="agenda-item-file"
									@change="addFile"
									multiple
								/>
							</label>
						</div>
					</draggable>
				</div>
			</div>
			<div v-if="isForModal" class="modal-footer">
				<button :disabled="isLoading || !item.title" class="btn btn-primary">Save</button>
			</div>
			<div v-else>
				<button :disabled="isLoading || !item.title" class="btn btn-primary">Submit agenda-item</button>
			</div>
		</form>
	</div>
</template>

<script>
import PeopleSearch from '@/components/PeopleSearch.vue'
import FilePreview from '@/components/FilePreview.vue'
import PersonLink from '@/components/PersonLink.vue'
import draggable from 'vuedraggable'

export default {
	components: { PeopleSearch, FilePreview, PersonLink, draggable },
	props: {
		// HACK: isForModal is not best solution, it would be better to have a different component configuration.
		// This is actually used to determine if it's used from one page or another.
		isForModal: {
			type: Boolean,
			default: true,
		},
		defaultSpeaker: {
			type: Object,
			default: null,
		},
		parentId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			item: this.getEmptyItem(),
			speaker: this.defaultSpeaker,
			isLoading: false,
		}
	},
	watch: {
		defaultSpeaker(newVal) {
			this.speaker = newVal
		},
	},
	methods: {
		clearSpeaker() {
			this.speaker = null
		},
		getEmptyItem() {
			return {
				title: '',
				text: '',
				internal_note: '',
				files: [],
			}
		},
		handleSubmit() {
			this.isLoading = true
			this.$emit(
				'submit',
				{
					title: this.item.title,
					text: this.item.text,
					internal_note: this.item.internal_note,
					speaker_id: this.speaker ? this.speaker.id : null,
					files: this.item.files,
				},
				() => {
					this.isLoading = false
					this.item = this.getEmptyItem(null)
					this.speaker = null
				}
			)
		},
		setSpeaker(speaker) {
			this.speaker = speaker
		},
		addFile(event) {
			this.item.files.push(...event.target.files)
		},
		removeFile(file) {
			this.item.files = this.item.files.filter(f => f !== file)
		},
		getFileKey(file) {
			return file.name + '_' + file.lastModified
		},
	},
}
</script>
