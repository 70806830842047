<template>
	<div class="modal-content">
		<form @submit.prevent="handleSubmit">
			<div class="modal-header">
				<h5 class="modal-title my-0">Edit meeting</h5>
				<button @click.prevent="$emit('close')" type="button" class="btn-close" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="form-row mb-3">
					<label class="form-label" for="meeting-title">
						Meeting title <small class="text-danger-400">*</small>
					</label>
					<input
						type="text"
						class="form-control"
						id="meeting-title"
						v-model="meeting.title"
						required
						minlength="3"
						placeholder="What is this meeting about?"
					/>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="meeting-description">Description</label>
					<textarea
						class="form-control"
						id="meeting-description"
						v-model="meeting.description"
						placeholder="Additional details about this meeting (optional)"
					></textarea>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="meeting-categories">Categories</label>
					<multiselect
						v-model="meeting.categories"
						:options="meetingCategories"
						:multiple="true"
						track-by="id"
						label="name"
						placeholder="Select categories"
						:hideSelected="true"
						:taggable="true"
						@tag="addCategory"
						tag-placeholder="Add this as new category"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
					</multiselect>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="meeting-department">Department</label>
					<select class="form-select" id="meeting-department" v-model="meeting.department_id">
						<option value="">Select department</option>
						<option v-for="dept in departments" :key="dept.id" :value="dept.id">
							{{ dept.name }}
						</option>
					</select>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="meeting-venue">Venue</label>
					<select class="form-select" id="meeting-venue" v-model="meeting.venue_id">
						<option :value="null">Select a venue</option>
						<option v-for="venue in venues" :key="venue.id" :value="venue.id">
							{{ venue.name }}
						</option>
					</select>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="meeting-conferencing-link">Conferencing link</label>
					<input
						type="url"
						class="form-control"
						id="meeting-conferencing-link"
						v-model="meeting.conferencing_link"
						placeholder="Video conferencing link (optional)"
					/>
				</div>

				<div v-show="meeting.conferencing_link" class="form-row mb-3">
					<label class="form-label" for="meeting-conferencing-note">Conferencing link note</label>
					<textarea
						class="form-control"
						id="meeting-conferencing-note"
						v-model="meeting.conferencing_link_note"
						placeholder="Additional information about joining the meeting (optional)"
					></textarea>
				</div>
			</div>
			<div class="modal-footer">
				<button :disabled="state.loading" class="btn btn-primary">Save changes</button>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { hgApi } from '@/api.js'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
	name: 'EditMeetingForm',
	components: { Multiselect },
	props: {
		defaultMeeting: { type: Object, required: true },
	},
	data() {
		return {
			meeting: {
				title: this.defaultMeeting.title,
				description: this.defaultMeeting.description,
				categories: [], // categories are loaded later
				department_id: this.defaultMeeting.department_id,
				venue_id: this.defaultMeeting.venue_id,
				conferencing_link: this.defaultMeeting.conferencing_link,
				conferencing_link_note: this.defaultMeeting.conferencing_link_note,
			},
			state: {
				loading: false,
			},
			meetingCategories: [],
		}
	},
	created() {
		this.$store.dispatch('getVenues')
		this.loadCategories()
	},
	computed: {
		...mapState(['j', 'departments', 'venues']),
	},
	watch: {
		// each time parent passes new default value, update the meeting internal state
		defaultMeeting(newVal) {
			this.meeting = {
				title: newVal.title,
				description: newVal.description,
				categories: this.mapCategoriesToIds(newVal.categories, this.meetingCategories),
				department_id: newVal.department_id,
				venue_id: newVal.venue_id,
				conferencing_link: newVal.conferencing_link,
				conferencing_link_note: newVal.conferencing_link_note,
			}
		},
		// when the categories are loaded, update the meeting internal state
		meetingCategories(newVal) {
			this.meeting.categories = this.mapCategoriesToIds(this.defaultMeeting.categories, newVal)
		},
	},
	methods: {
		handleSubmit() {
			this.state.loading = true
			this.$emit(
				'submit',
				{
					title: this.meeting.title,
					description: this.meeting.description,
					categories: this.meeting.categories.map(c => c.id),
					department_id: this.meeting.department_id,
					venue_id: this.meeting.venue_id,
					conferencing_link: this.meeting.conferencing_link,
					conferencing_link_note: this.meeting.conferencing_link_note,
				},
				() => {
					this.state.loading = false
				}
			)
		},

		async addCategory(name) {
			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories`, {
					body: { name },
				})
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				const category = await response.json()
				this.meetingCategories.push(category)
				this.meeting.categories.push(category)
				Vue.toasted.success('Meeting category created successfully')
			} catch (error) {
				Vue.toasted.error(`Couldn't create category (${error})`)
			}
		},

		async loadCategories() {
			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories`)
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				this.meetingCategories = await response.json()
			} catch (error) {
				Vue.toasted.error(`Couldn't load meeting categories (${error})`)
			}
		},

		mapCategoriesToIds(categoryIds, categories) {
			// categories might not be loaded yet
			if (categories.length === 0) {
				return []
			}
			return categoryIds
				.map(categoryId => {
					return categories.find(category => category.id === categoryId)
				})
				.filter(category => category !== undefined)
		},
	},
}
</script>
