<template>
	<div class="page-payments-pay">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/quick-pay`">Payments portal</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
			{{
				isBillingAccount ? `Account ${billingAccount ? billingAccount.account_number : '..'}` : paymentType.name
			}}
		</h3>

		<div class="row justify-content-center pb-5">
			<div class="col-md-10 col-lg-8 col-xl-6">
				<div v-if="j.features_options.payments_options && j.features_options.payments_options.enabled">
					<div
						v-if="j.features_options.payments_options.video"
						class="bg-danger-lighter rounded-1 px-3 py-2 mb-3 cursor-pointer"
						@click="states.video = !states.video"
					>
						<span>▶️ Please watch this video on how to pay online</span>

						<div v-if="states.video" class="ratio ratio-16x9 mt-2">
							<iframe
								:src="j.features_options.payments_options.video"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
					</div>

					<div v-if="!auth" class="card">
						<div class="card-body">
							<p class="mb-3 text-center">First, what's your email address?</p>

							<login-form
								:redirectUrl="`/${j.slug}/quick-pay/pay`"
								:allowRegistration="true"
								loginMessage="Great, logged in to your account"
							></login-form>
						</div>
					</div>

					<div v-else class="card">
						<div class="card-body">
							<template v-if="isBillingAccount">
								<h5>
									Make a payment for billing account:
								</h5>

								<div class="bg-light p-3 rounded-1 mb-3">
									<span
										v-if="states.billingAccount === 'loading'"
										class="spinner-border spinner-border-sm"
									></span>
									<div v-else-if="states.billingAccount === 'loaded' && isPaymentBill">
										<p class="lead mb-2">
											<span class="text-neutral-400 me-1">Account</span>
											<code>{{ billingAccount.account_number }}</code
											>, <span class="text-neutral-400">Bill</span>
											{{ bill.bill_date | dateLocal('en-US', { timeZone: j.timezone }) }}
										</p>
										<p class="mb-2"><span class="text-neutral-400">Name:</span> {{ bill.name }}</p>
										<p v-if="bill.bill_due_date" class="mb-2">
											<span class="text-neutral-400">Due date:</span>
											{{ bill.bill_due_date | dateLocal('en-US', { timeZone: j.timezone }) }}
											<span
												v-if="new Date(bill.bill_due_date) < new Date()"
												class="text-primary-400"
												>You will be informed about your late fee by your clerk</span
											>
										</p>
										<p class="mb-0">
											<span class="text-neutral-400">Amount:</span>
											{{ bill.amount | currency }}
										</p>
									</div>
									<div v-else-if="states.billingAccount === 'loaded' && isBillingAccount">
										<p class="lead mb-2">
											<span class="text-neutral-400 me-1">Account</span>
											<code>{{ billingAccount.account_number }}</code>
										</p>
										<p class="mb-2">
											<span class="text-neutral-400">Name:</span> {{ billingAccount.name }}
										</p>
										<p class="mb-0">
											<span class="text-neutral-400">Balance:</span>
											{{ billingAccount.balance | currency }}
										</p>
									</div>
									<div v-else-if="states.billingAccount === 'error'" class="alert alert-danger">
										Error loading Billing Account. {{ billingAccountError }}
									</div>
								</div>
							</template>
							<h5 v-else>Start a payment for {{ paymentType.name }}</h5>

							<hr class="bg-primary-100 mb-3s" />

							<form @submit.prevent="makePayment">
								<div class="form-group">
									<div class="row">
										<label class="col form-label" for="account-first-name">Your name</label>
										<div class="col-auto">
											<router-link to="/your-account" class="text-neutral-400"
												><small>Edit in Account settings</small></router-link
											>
										</div>
									</div>

									<input
										v-if="accountHasName"
										type="text"
										class="form-control mb-3"
										id="account-first-name"
										:value="`${account.first_name} ${account.last_name}`"
										readonly
									/>
									<div v-else class="row gx-2">
										<div class="col-12 col-lg-6">
											<div class="form-floating mb-3">
												<input
													type="text"
													class="form-control"
													:class="{ 'is-invalid': states.name === 'error' }"
													id="account-first-name"
													v-model="accountName.first_name"
													required
													autocomplete="given-name"
												/>
												<label for="account-first-name">First name</label>
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-floating mb-3">
												<input
													type="text"
													class="form-control"
													:class="{ 'is-invalid': states.name === 'error' }"
													id="account-last-name"
													v-model="accountName.last_name"
													required
													autocomplete="family-name"
												/>
												<label for="account-last-name">Last name</label>
											</div>
										</div>
									</div>

									<div id="payment-name-feedback" class="invalid-feedback">
										Please provide your full name
									</div>
								</div>

								<div class="form-group">
									<label for="payment-location-line1" class="form-label">Billing address</label>

									<gmap-autocomplete
										@place_changed="setAddress"
										:select-first-on-enter="true"
										:options="autocompleteOptions"
									>
										<template v-slot:default="slotProps">
											<div class="form-floating mb-3">
												<input
													id="payment-location-line1"
													class="form-control"
													:class="{ 'is-invalid': states.address === 'error' }"
													ref="input"
													:value="payment.location.line1"
													placeholder="Street address"
													autocomplete="address-line1"
													required
													v-on="slotProps.listeners"
													@focus="states.address = 'idle'"
													@blur="setAddressPart"
												/>
												<label for="payment-location-line1">Address</label>
											</div>
										</template>
									</gmap-autocomplete>

									<div class="form-floating mb-3">
										<input
											type="text"
											class="form-control"
											id="payment-location-line2"
											placeholder="Apartment, suite, etc. (optional)"
											v-model="payment.location.line2"
											autocomplete="address-line2"
										/>
										<label for="payment-location-line2">Apartment, suite, etc. (optional)</label>
									</div>

									<div class="row gx-3">
										<div class="col-12 col-lg-4">
											<div class="form-floating mb-3">
												<input
													type="text"
													class="form-control"
													id="payment-location-city"
													:placeholder="capitalize(j.type)"
													v-model="payment.location.city"
													required
													autocomplete="address-level2"
												/>
												<label for="payment-location-city">City</label>
											</div>
										</div>
										<div class="col-6 col-lg-4">
											<div class="form-floating mb-3">
												<input
													type="text"
													class="form-control"
													id="payment-location-state"
													placeholder="State"
													v-model="payment.location.state"
													required
													autocomplete="address-level1"
												/>
												<label for="payment-location-state">State</label>
											</div>
										</div>
										<div class="col-6 col-lg-4">
											<div class="form-floating mb-3">
												<input
													type="text"
													class="form-control"
													id="payment-location-postal-code"
													placeholder="Postal code"
													v-model="payment.location.postal_code"
													required
													inputmode="numeric"
													minlength="5"
													autocomplete="postal-code"
												/>
												<label for="payment-location-postal-code">ZIP code</label>
											</div>
										</div>
									</div>

									<div id="payment-address-feedback" class="invalid-feedback">
										Please fill your billing address
									</div>
								</div>

								<div v-if="isPaymentType">
									<div v-if="paymentType && paymentType.installments" class="form-group mb-3">
										<label class="form-label">Installment</label>

										<div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													id="payment-installment-1"
													value="1"
													v-model="payment.installment"
												/>
												<label class="form-check-label" for="payment-installment-1"
													>1st installment</label
												>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													id="payment-installment-2"
													value="2"
													v-model="payment.installment"
												/>
												<label class="form-check-label" for="payment-installment-2"
													>2nd installment</label
												>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													id="payment-installment-full"
													value="full"
													v-model="payment.installment"
												/>
												<label class="form-check-label" for="payment-installment-full"
													>Full payment</label
												>
											</div>
										</div>
									</div>

									<div v-if="paymentTypeInstallmentForbidden" class="alert alert-danger">
										{{ paymentType.installments_2_disclaimer }}
									</div>

									<div v-show="!paymentTypeInstallmentForbidden" class="form-group mb-3">
										<label for="payment-description" class="form-label">Payment details</label>
										<input
											type="text"
											class="form-control"
											:class="{ 'is-invalid': states.description === 'error' }"
											id="payment-description"
											required
											v-model="payment.description"
											:placeholder="
												paymentType ? paymentType.help : 'Enter invoice reference number'
											"
											@focus="states.description = 'idle'"
										/>
										<div id="payment-description-feedback" class="invalid-feedback">
											"{{ paymentType.help }}" is required
										</div>
									</div>
								</div>

								<div v-show="!paymentTypeInstallmentForbidden">
									<div class="form-group mb-3">
										<label for="payment-amount" class="form-label"
											>{{ paymentType ? paymentType.name : '' }} Amount</label
										>

										<div class="input-group mb-3">
											<span class="input-group-text">$</span>
											<input
												type="number"
												class="form-control"
												id="payment-amount"
												v-model="payment.amount"
												step="0.01"
												min="0.5"
												:max="paymentMaxAmount"
												required
											/>
										</div>
									</div>

									<div
										v-if="
											paymentDept &&
												paymentDept.payment_methods.includes('us_bank_account') &&
												(payment.source === 'quick-pay' ||
													payment.source.startsWith('ba_') ||
													payment.source.startsWith('bl_'))
										"
										class="form-group mb-3"
									>
										<label for="payment-method-card" class="form-label">Payment method</label>

										<div class="row row-cols-2">
											<div class="col">
												<div
													class="border rounded-1 p-3 cursor-pointer"
													:class="
														payment.payment_method_types === 'us_bank_account'
															? 'bg-success-50 border-success'
															: 'bg-light'
													"
													@click="payment.payment_method_types = 'us_bank_account'"
												>
													<h6 class="mb-2">ACH Direct Debit</h6>
													<p class="mb-0">Pay with your bank account</p>
												</div>
											</div>
											<div class="col">
												<div
													class="border rounded-1 p-3 cursor-pointer"
													:class="
														payment.payment_method_types === 'card'
															? 'bg-success-50 border-success'
															: 'bg-light'
													"
													@click="payment.payment_method_types = 'card'"
												>
													<h6 class="mb-2">Card</h6>
													<p class="mb-0">Any US debit or credit card</p>
												</div>
											</div>
										</div>
									</div>

									<div v-if="payment.amount" class="bg-light rounded-2 p-3 mb-3">
										<p class="mb-2">
											Amount:
											<strong>{{ payment.amount | currency }}</strong>
										</p>
										<p class="mb-2">
											Payment fee: <strong>{{ paymentFee | currency }}</strong>
											<small
												class="text-muted cursor-pointer ms-2"
												@click="states.feeInfo = !states.feeInfo"
												><span v-if="extraDisclaimers.includes(j.slug)" class="me-1"
													>non-refundable</span
												>
												<font-awesome-icon :icon="['fas', 'info-circle']"
											/></small>
										</p>
										<p v-if="states.feeInfo" class="text-muted mb-2">
											This fee is paid directly to the payment processor and is non-refundable.
											Please refer to your {{ j.type }} for more info.
										</p>
										<p class="lead mb-0">
											Total:
											<strong>{{
												(Number(payment.amount) + Number(paymentFee)) | currency
											}}</strong>
										</p>
									</div>

									<div v-if="paymentError" class="alert alert-danger">
										{{ paymentError }}
									</div>

									<p class="card-text text-center">
										<button
											class="btn btn-primary px-5"
											:disabled="
												!payment.amount || payment.amount < 0.5 || states.payment == 'loading'
											"
										>
											Continue
											<span
												v-if="states.payment == 'loading'"
												class="spinner-border spinner-border-sm"
											></span
											><span v-else>→</span>
										</button>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div v-else class="py-4">
					<h2 class="text-center">💰 No payments for you</h2>

					<p class="lead text-center">Payments are not enabled for {{ j.name }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { capitalize } from 'lodash'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { paymentFee } from '@/actions/payments.js'

import LoginForm from '@/components/LoginForm.vue'

export default {
	name: 'HeyGovPay',
	components: { LoginForm },
	data() {
		//console.log('j start', this.j.location)

		return {
			states: {
				payment: 'idle',
				name: 'idle',
				address: 'idle',
				description: 'idle',
				feeInfo: true,
				video: false,
				billingAccount: 'idle',
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['geometry', 'address_components'],
			},

			accountName: {
				first_name: '',
				last_name: '',
			},

			isPaymentType: this.$route.query.pay || false,
			isBillingAccount: this.$route.query.account || false,
			isPaymentBill: this.$route.query.bill || false,
			billingAccount: null,
			bill: null,
			billingAccountError: '',

			paymentError: '',
			paymentIntent: null,
			paymentElement: null,
			payment: {
				description: '',
				type: this.$route.query.pay || null,
				installment: 'full',
				amount: null,
				source: this.$route.query.bill || this.$route.query.account || 'quick-pay',
				payment_method_types: 'card',
				location: {
					line1: '',
					line2: '',
					city: '', // city
					state: '',
					postal_code: '',
					country: 'US',
					lat: '',
					lng: '',
				},
			},
		}
	},
	computed: {
		...mapState([
			'j',
			'appVersion',
			'apiUrl',
			'account',
			'departments',
			'forms',
			'stripePublishableKey',
			'stripePublishableTestKey',
			'extraDisclaimers',
		]),
		...mapGetters(['auth']),
		formsWithPrice() {
			return this.forms.filter(f => f.status !== 'archived' && f.price)
		},
		accountHasName() {
			return this.account?.first_name && this.account?.last_name
		},
		paymentType() {
			return this.j.features_options?.payments_options.types.find(t => t.value === this.payment.type)
		},
		paymentMaxAmount() {
			return this.payment.payment_method_types === 'us_bank_account' ? 250000 : 50000
		},
		paymentFee() {
			return paymentFee(this.paymentPlatform, this.payment.payment_method_types, this.payment.amount)
		},
		paymentTypeInstallmentForbidden() {
			return (
				this.paymentType?.installments &&
				this.paymentType?.installments_2_disclaimer &&
				this.payment.installment == 2
			)
		},
		paymentDept() {
			let dept = null

			if (this.isPaymentType) {
				dept = this.departments?.find(d => d.id === this.paymentType.department)
			} else if (this.isBillingAccount && this.billingAccount) {
				dept = this.departments?.find(d => d.id === this.billingAccount.department_id)
			}

			return dept
		},
		paymentPlatform() {
			let platform = null

			if (this.paymentDept?.payments_platform) {
				platform = this.paymentDept.payments_platform
			} else if (this.paymentDept?.stripe_account) {
				platform = 'stripe'
			} else if (this.paymentDept?.justifi_account) {
				platform = 'justifi'
			} else if (this.paymentDept?.paygov_account) {
				platform = 'paygov'
			} else if (this.paymentDept?.municipay_account) {
				platform = 'municipay'
			} else if (this.paymentDept?.bridgepay_account) {
				platform = 'bridgepay'
			}

			return platform
		},
	},
	created() {
		this.$store.dispatch('getForms')

		this.$store.dispatch('loadDepartments').then(() => {
			if (this.isPaymentBill) {
				this.loadBill()
			} else if (this.isBillingAccount) {
				this.loadBillingAccount()
			}
		})

		if (this.isPaymentType) {
			if (!this.payment.type && this.j.features_options?.payments_options?.enabled) {
				this.payment.type = this.j.features_options.payments_options.types[0].value
			}
		}

		if (!this.isBillingAccount) {
			this.prefillAccountInfo()
		}
	},
	mounted() {
		if (!('Stripe' in window)) {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			document.head.appendChild(StripeScript)
		}

		if (!('JustiFiPaymentsJS' in window)) {
			let JustifiScript = document.createElement('script')
			JustifiScript.setAttribute('src', 'https://js.justifi.ai/payments/v1/payments.js')
			document.head.appendChild(JustifiScript)
		}
	},
	methods: {
		capitalize,

		loadBill() {
			this.states.billingAccount = 'loading'

			heyGovApi(`${this.j.slug}/billing-accounts/bills/${this.$route.query.bill}`)
				.then(({ data: bill }) => {
					this.setBillingAccount(bill.billingAccount)
					this.bill = bill
					this.payment.amount = bill.amount

					this.states.billingAccount = 'loaded'
				})
				.catch(err => {
					this.billingAccountError = err.response?.data?.message || err.response?.statusText || err.message
					this.states.billingAccount = 'error'
				})
		},
		loadBillingAccount() {
			heyGovApi(`${this.j.slug}/billing-accounts/${this.$route.query.account}`)
				.then(({ data }) => {
					this.setBillingAccount(data)
					this.states.billingAccount = 'loaded'
				})
				.catch(err => {
					this.billingAccountError = err.response?.data?.message || err.response?.statusText || err.message
					this.states.billingAccount = 'error'
				})
		},
		setBillingAccount(billingAccount) {
			this.billingAccount = billingAccount
			this.payment.amount = billingAccount.balance
			this.payment.description = billingAccount.account_number

			// prefill payment address
			if (billingAccount.address?.line1) {
				this.payment.location.line1 = billingAccount.address.line1
				this.payment.location.line2 = billingAccount.address.line2
				this.payment.location.city = billingAccount.address.city
				this.payment.location.state = billingAccount.address.state
				this.payment.location.postal_code = billingAccount.address.postal_code
			}
		},

		prefillAccountInfo() {
			if (this.account) {
				setTimeout(() => {
					if (this.account.address_json?.line1) {
						//todo prefill address
						Vue.toasted.show('prefill account address')
					} else {
						this.payment.location.state = this.j.location.state

						if (['city', 'town', 'village'].includes(this.j.type)) {
							this.payment.location.city = this.j.name
						}
					}
				}, 200)
			}
		},
		setAddressPart($event) {
			this.payment.location.line1 = $event.target.value
		},
		setAddress(place) {
			let street = ''
			let route = ''

			place.address_components.forEach(part => {
				if (part.types.includes('street_number')) {
					street = part.long_name
				} else if (part.types.includes('route')) {
					route = part.long_name
				} else if (part.types.includes('locality')) {
					this.payment.location.city = part.long_name
				} else if (part.types.includes('administrative_area_level_1')) {
					this.payment.location.state = part.short_name
				} else if (part.types.includes('postal_code')) {
					this.payment.location.postal_code = part.long_name
				} else if (part.types.includes('country')) {
					this.payment.location.country = part.short_name
				}
			})

			this.payment.location.line1 = `${street} ${route}`.trim()
			this.payment.location.lat = place.geometry.location.lat()
			this.payment.location.lng = place.geometry.location.lng()
		},
		async makePayment() {
			this.states.name = 'idle'
			this.states.address = 'idle'
			const errors = []

			if (!this.accountHasName) {
				if (this.accountName.first_name.trim().length < 2 && this.accountName.last_name.trim().length < 2) {
					this.states.name = 'error'
					errors.push('Full name is required')
				}
			}

			if (
				!this.payment.location.line1.trim().length ||
				this.payment.location.postal_code.trim().length < 5 ||
				!this.payment.location.city.trim().length
			) {
				this.states.address = 'error'
				errors.push('Billing address is required')
			}

			if (!this.payment.description.trim().length) {
				this.states.description = 'error'
				errors.push('Please fill the payment details')
			}

			if (errors.length) {
				this.paymentError = errors.join('. ')
				return
			}

			this.states.payment = 'loading'

			// update account name if empty
			if (!this.accountHasName) {
				this.$store.dispatch('accountUpdateIfEmpty', {
					first_name: this.accountName.first_name,
					last_name: this.accountName.last_name,
				})
			}

			let description = this.payment.description || ''

			if (this.isPaymentType) {
				description = `${this.paymentType.name} - ${this.payment.description || ''}`

				if (this.paymentType.installments) {
					description += ` (${this.payment.installment})`
				}
			}

			heyGovApi
				.post(`${this.j.slug}/payments?by=payer`, {
					department_id: this.paymentDept.id,
					person_id: this.auth.id,
					...this.payment,
					description,
					fee: this.paymentFee,
				})
				.then(
					({ data }) => {
						this.$router.push({
							name: 'QuickPayPaymentsPage',
							params: { jSlug: this.j.slug, paymentUuid: data.uuid },
							query: this.$route.query,
						})
					},
					error => {
						this.states.payment = 'error'
						this.paymentError = error.response?.data?.message || error.message
					}
				)
		},
	},
	watch: {
		/* 'payment.type'(type) {
			if (type && this.$route.query.pay !== type) {
				//this.$router.replace(`/${this.j.slug}/quick-pay/pay?pay=${type}`)
			}
			this.payment.installment = 'full'
		},
		auth(auth, oldAuth) {
			if (!oldAuth && auth) {
				setTimeout(() => {
					this.prefillAccountInfo()

					if (this.isPaymentBill) {
						this.amountUpdated()
					}
				}, 100)
			}
		}, */
	},
}
</script>
