<template>
	<div v-if="isStaff" class="heygov-assistant" :class="{ 'heygov-assistant-open': assistantShow }">
		<div class="heygov-assistant-header" @click="assistantShow = !assistantShow">
			👋 HeyGov Assistant
		</div>
		<div ref="assistant-messages" class="heygov-assistant-messages">
			<div
				v-for="(msg, index) in assistantMessages"
				:key="index"
				class="heygov-assistant-message"
				:class="`heygov-assistant-message-role-${msg.role}`"
			>
				<span v-if="msg.content" class="heygov-assistant-message-content">{{ msg.content }}</span>
				<div v-if="msg.tool_calls" class="heygov-assistant-message-tools">
					<div v-for="tool in msg.tool_calls" :key="tool.id" class="bg-success-50 text-success-400">
						{{ toolCallBadge(tool.function) }}
					</div>
				</div>
			</div>
		</div>
		<form @submit.prevent="() => assistantSend()" class="heygov-assistant-input">
			<input
				type="text"
				class="form-control form-control-sm"
				v-model="assistantMsg"
				placeholder="Let me help you"
				minlength="3"
				:disabled="assistantThinking"
			/>
		</form>
	</div>
</template>

<style scoped lang="scss">
@import '@/assets/variables';

.heygov-assistant {
	position: fixed;
	width: 375px;
	bottom: -325px;
	right: 1rem;
	z-index: 1000;
	background: #fff;
	border-radius: 0.25rem 0.25rem 0 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	transition: bottom 0.2s ease-in-out;

	&.heygov-assistant-open {
		bottom: 0;
	}

	.heygov-assistant-header {
		border-radius: 0.25rem 0.25rem 0 0;
		background: #f8f9fa;
		padding: 0.5rem 1rem;
		border-bottom: 1px solid #e9ecef;
		cursor: pointer;
	}

	.heygov-assistant-messages {
		padding: 0 0.5rem;
		height: 270px;
		overflow-y: auto;
	}

	.heygov-assistant-message {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.heygov-assistant-message-role-tool {
		display: none;
	}

	.heygov-assistant-message-content {
		display: inline-block;
		background-color: #f0f0f0;
		padding: 0.5rem 0.75rem;
		border-radius: 0.5rem;
	}

	.heygov-assistant-message-role-user {
		text-align: right;

		.heygov-assistant-message-content {
			background-color: $primary-400;
			color: #fff;
		}
	}

	.heygov-assistant-message-tools div {
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
	}

	.heygov-assistant-input {
		padding: 0.5rem;
		background-color: #fff;
	}
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'

import { getAssistantCompletion } from '@/actions/ai.js'

export default {
	name: 'AiAssistant',
	components: {},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'HeyGov',
		// all titles will be injected into this template
		titleTemplate: '%s | HeyGov',
	},
	data() {
		return {
			assistantShow: false,
			assistantThinking: false,
			assistantMsg: '',
			assistantMessages: [],
		}
	},
	computed: {
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		...mapState(['j']),
	},
	methods: {
		async assistantSend(msg) {
			this.assistantMessages.push({
				role: 'user',
				content: msg || this.assistantMsg,
			})

			setTimeout(() => {
				this.scrollToLastMessage()
			}, 50)

			this.assistantThinking = true

			const completion = await getAssistantCompletion(
				this.j,
				this.account,
				this.currentRole,
				this.assistantMessages
			)

			this.assistantMsg = ''
			this.assistantThinking = false

			if (completion.message.tool_calls?.length) {
				this.assistantMessages.push({
					role: completion.message.role,
					content: completion.message.content,
					tool_calls: completion.message.tool_calls,
				})

				completion.message.tool_calls.forEach(toolCall => {
					if (toolCall.type === 'function') {
						const args = JSON.parse(toolCall.function.arguments)
						console.log('Assistant Function Call', toolCall, args)

						let toolResultContent = ''

						if (toolCall.function.name === 'navigateToPage') {
							this.$router.push(`/${this.j.slug}${args.path}`)
							toolResultContent = `Navigated to app page ${args.path}`
						} else if (toolCall.function.name === 'createMeeting') {
							const query = {}

							if (args.name) {
								query.name = args.name
							}

							if (args.date) {
								query.date = args.date
							}

							if (args.time) {
								query.time = args.time.slice(0, 5)
							}

							this.$router.push({
								path: `/${this.j.slug}/meetings/create`,
								query,
							})

							toolResultContent = `Navigated to "Create meeting" page and pre-filled fields`
						} else if (toolCall.function.name === 'createEvent') {
							this.$router.push({
								path: `/${this.j.slug}/events/create`,
								query: {
									status: 'public',
									...args,
								},
							})

							toolResultContent = `Navigated to "Create event" page and pre-filled fields`
						} else if (toolCall.function.name === 'createNewsNotice') {
							this.$router.push({
								path: `/${this.j.slug}/news-notices/create`,
								query: { prompt: args.subject },
							})

							toolResultContent = `Started the process of creating "${args.subject}" news/notice post`
						} else if (toolCall.function.name === 'createEmergencyAlert') {
							this.$router.push({
								path: `/${this.j.slug}/emergency-alerts/create`,
								query: { prompt: args.subject },
							})

							toolResultContent = `Started the process of creating "${args.subject}" emergency alert`
						} else {
							console.warn('Unknown Function call', toolCall.function.name)
						}

						this.assistantMessages.push({
							role: 'tool',
							name: toolCall.function.name,
							content: toolResultContent,
							tool_call_id: toolCall.id,
						})
					} else {
						console.warn('Unknown tool call', toolCall)
					}
				})
			} else {
				console.log('Assistant Completion', completion)

				// display the Assistant chat, if hidden
				this.assistantShow = true

				this.assistantMessages.push({
					role: completion.message.role,
					content: completion.message.content,
				})
			}

			setTimeout(() => {
				this.scrollToLastMessage()

				document.querySelector('.heygov-assistant-input input').focus()
			}, 50)
		},
		scrollToLastMessage() {
			const $messages = this.$refs['assistant-messages']

			$messages.scrollTop = $messages.scrollHeight
		},
		toolCallBadge(toolCall) {
			const args = JSON.parse(toolCall.arguments)
			let text = ''

			switch (toolCall.name) {
				case 'navigateToPage':
					text = `Navigate to "${args.path}"`
					break
				case 'createMeeting':
					text = `Start a meeting "${args.name || '-'}"`
					break
				case 'createEvent':
					text = `Create event "${args.name || '-'}"`
					break
				case 'createNewsNotice':
					text = `Generate content for a "${args.subject}" news/notice post`
					break
				case 'createEmergencyAlert':
					text = `Generate content for a "${args.subject}" emergency alert`
					break
				default:
					text = `Unknown tool call (${toolCall.name})`
					break
			}

			return text
		},
	},
}
</script>
