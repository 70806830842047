<template>
	<div class="page-events-create">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/events`">Events</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			Add event
		</h3>

		<div class="row justify-content-center">
			<div class="col-lg-8 col-md-10">
				<div class="card">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">
								Add event
							</h5>
							<help-button
								url="https://townweb.notion.site/Create-a-meeting-b7260037f1d44c0dba0280a904c3673b"
								text="How to create events"
							></help-button>
						</div>
					</div>
					<form @submit.prevent="addEvent">
						<div class="card-body">
							<div class="form-group mb-3">
								<label class="form-label" for="event-name"
									>Name <span class="text-danger">*</span></label
								>
								<input
									class="form-control"
									id="event-name"
									v-model="newEvent.name"
									placeholder="Event name"
									required
								/>
							</div>

							<div class="row">
								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-starts-at"
										>Start <span class="text-danger">*</span></label
									>
									<input
										type="datetime-local"
										class="form-control"
										id="event-starts-at"
										v-model="newEvent.starts_at_local"
										required
									/>
								</div>

								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-ends-at"
										>End <span class="text-danger">*</span></label
									>
									<input
										type="datetime-local"
										class="form-control"
										id="event-ends-at"
										v-model="newEvent.ends_at_local"
										required
									/>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-categories">Category</label>

									<multiselect
										v-model="newEvent.categories"
										:multiple="true"
										:options="categories"
										track-by="id"
										label="name"
										placeholder="Select categories"
										:hideSelected="true"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{
											option.name
										}}</template>
									</multiselect>
								</div>

								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-status">Privacy</label>

									<div>
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												v-model="newEvent.status"
												id="event-status-private"
												value="private"
											/>
											<label class="form-check-label" for="event-status-private">Private</label>
										</div>
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												v-model="newEvent.status"
												id="event-status-public"
												value="public"
											/>
											<label class="form-check-label" for="event-status-public">Public</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="event-description">Description</label>
								<textarea
									rows="3"
									class="form-control"
									id="event-description"
									v-model="newEvent.description"
									placeholder="Short description"
								/>
							</div>

							<div class="row">
								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-department">Department</label>
									<select class="form-select" id="event-department" v-model="newEvent.department_id">
										<option :value="null">Select a department</option>
										<option
											v-for="department in activeDepartments"
											:key="`event-dept-${department.id}`"
											:value="department.id"
											>{{ department.name }}</option
										>
									</select>
								</div>

								<div v-if="j.features.includes('venues')" class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-venues">Venues</label>

									<multiselect
										v-model="newEvent.venues"
										:multiple="true"
										:options="venues"
										track-by="id"
										label="name"
										placeholder="Select venues"
										:hideSelected="true"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{
											option.name
										}}</template>
									</multiselect>
								</div>
							</div>

							<div class="form-group">
								<label class="form-label" for="event-recurrence"
									>Recurring event
									<input
										class="form-check-input ms-1"
										switch
										type="checkbox"
										id="event-recurrence"
										v-model="hasRecurrence"
								/></label>

								<div v-if="newEvent.recurrence" class="p-2 rounded-1 bg-neutral-50">
									<div class="row">
										<div class="col-7">
											<div class="row align-items-center gx-3 mb-3">
												<div class="col-auto">Every</div>
												<div class="col-auto">
													<input
														type="number"
														class="form-control form-control-sm"
														v-model="newEvent.recurrence.interval"
														min="1"
														max="999"
													/>
												</div>
												<div class="col-auto">
													<select
														class="form-select form-select-sm"
														v-model="newEvent.recurrence.frequency"
													>
														<option value="daily">{{
															pluralize('day', Number(newEvent.recurrence.interval))
														}}</option>
														<option value="weekly">{{
															pluralize('week', Number(newEvent.recurrence.interval))
														}}</option>
														<option value="monthly">{{
															pluralize('month', Number(newEvent.recurrence.interval))
														}}</option>
														<option value="yearly">{{
															pluralize('year', Number(newEvent.recurrence.interval))
														}}</option>
													</select>
												</div>
											</div>
											<div
												v-if="['weekly', 'monthly'].includes(newEvent.recurrence.frequency)"
												class="row align-items-center gx-3 mb-3"
											>
												<div class="col">[{{ newEvent.recurrence.frequency }} options]</div>
											</div>
											<div class="row align-items-center gx-3 mb-3">
												<div class="col-auto">Ends</div>
												<div class="col-auto">
													<select
														class="form-select form-select-sm"
														v-model="newEvent.recurrence.ends"
													>
														<option value="never">Never</option>
														<option value="after">After</option>
														<option value="on">On</option>
													</select>
												</div>
												<div class="col-auto">
													<div
														v-if="newEvent.recurrence.ends === 'after'"
														class="input-group input-group-sm"
													>
														<input
															type="number"
															class="form-control form-control-sm"
															v-model="newEvent.recurrence.ends_after"
															required
															min="1"
															max="999"
														/>
														<span class="input-group-text">times</span>
													</div>
													<input
														v-else-if="newEvent.recurrence.ends === 'on'"
														type="date"
														class="form-control form-control-sm"
														v-model="newEvent.recurrence.ends_on"
														required
													/>
												</div>
											</div>
										</div>
										<div class="col-5">
											<p class="mb-1">Next events:</p>
											<ul class="nicer-list">
												<li>2025-01-01</li>
												<li>2025-01-01</li>
												<li>2025-01-01</li>
												<li>2025-01-01</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer d-flex align-items-center justify-content-end">
							<button class="btn btn-primary px-5">Create event →</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import pluralize from 'pluralize'

import heyGovApi, { handleResponseError } from '@/api.js'
import { websiteUrl } from '@/utils.js'

export default {
	name: 'EventsCreate',
	components: { Multiselect },
	data() {
		return {
			categories: [],

			recurrenceRule: {
				interval: 1,
				frequency: 'daily', // enum(daily, weekly, monthly, yearly)
				every: null,
				day_in_week: 2,
				ends: 'never', // enum(never, after, on)
				ends_on: null,
				ends_after: 100,
				on_different_date: false,
			},

			hasRecurrence: false,

			newEvent: {
				name: '',
				department_id: null,
				status: 'private',
				categories: [],
				venues: [],
				description: '',
				starts_at_local: null,
				ends_at_local: null,
				recurrence: null,
			},
		}
	},
	computed: {
		...mapState(['j', 'departments', 'venues']),
		...mapGetters(['activeDepartments']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getVenues')
		this.loadCategories()

		this.prefillEventFromUrlQuery()
	},
	methods: {
		pluralize,

		loadCategories() {
			heyGovApi.get(`/${this.j.slug}/events/categories`).then(({ data }) => {
				this.categories.push(...data)
			}, handleResponseError(`Error loading categories ({error})`))
		},

		prefillEventFromUrlQuery() {
			if (this.$route.query.name) {
				this.newEvent.name = String(this.$route.query.name).trim()
			}

			if (this.$route.query.description) {
				this.newEvent.description = String(this.$route.query.description).trim()
			}

			if (this.$route.query.status) {
				this.newEvent.status = this.$route.query.status
			}

			if (this.$route.query.starts_at) {
				this.newEvent.starts_at_local = String(this.$route.query.starts_at).slice(0, 16)
			}

			if (this.$route.query.ends_at) {
				this.newEvent.ends_at_local = String(this.$route.query.ends_at).slice(0, 16)
			}
		},

		addEvent() {
			this.newEvent.starts_at_local = format(new Date(this.newEvent.starts_at_local), 'yyyy-MM-dd HH:mm:ss')
			this.newEvent.ends_at_local = format(new Date(this.newEvent.ends_at_local), 'yyyy-MM-dd HH:mm:ss')
			this.newEvent.categories = this.newEvent.categories.map(c => c.id || c)
			this.newEvent.venues = this.newEvent.venues.map(v => v.id || v)

			heyGovApi.post(`/${this.j.slug}/events`, this.newEvent).then(({ data }) => {
				Vue.toasted.success('Event created', {
					action: {
						text: 'View on website',
						href: websiteUrl(this.j.website, `events/?heygov-event=${data.pid}`),
						target: '_blank',
					},
				})

				this.$router.push(`/${this.j.slug}/events?month=${data.starts_at_local.slice(0, 7)}`)
			}, handleResponseError(`Error creating event ({error})`))
		},
	},
	watch: {
		hasRecurrence() {
			if (this.hasRecurrence) {
				this.newEvent.recurrence = this.recurrenceRule
			} else {
				this.newEvent.recurrence = null
			}
		},
		'$route.query'() {
			this.prefillEventFromUrlQuery()
		},
	},
}
</script>
