<template>
	<div class="meeting-overview">
		<div class="card mb-4">
			<div class="card-body">
				<div class="row">
					<div class="col-auto">
						<div class="border rounded-1 mb-3">
							<div
								class="bg-success-50 text-success-400 text-center px-2"
								style="border-radius: 1rem 1rem 0px 0px; min-width: 75px;"
							>
								<small>{{
									new Date(meeting.starts_at).toLocaleString('en-US', {
										month: 'long',
										timeZone: j.timezone,
									})
								}}</small>
							</div>
							<div class="text-center fw-light fs-3">
								{{
									new Date(meeting.starts_at).toLocaleString('en-US', {
										day: 'numeric',
										timeZone: j.timezone,
									})
								}}
							</div>
							<div
								class="bg-neutral-50 text-neutral-500 text-center px-2"
								style="border-radius: 0px 0px 1rem 1rem;"
							>
								<small>{{
									new Date(meeting.starts_at).toLocaleString('en-US', {
										year: 'numeric',
										timeZone: j.timezone,
									})
								}}</small>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="row">
							<div class="col-lg-6 mb-3">
								<p class="mb-2">
									<span class="text-neutral-400">Time: </span>
									<strong>{{
										new Date(meeting.starts_at).toLocaleString('en-US', {
											timeStyle: 'short',
											timeZone: j.timezone,
										})
									}}</strong>
									<span
										v-if="hasTimezoneDifference"
										class="badge bg-neutral-50 text-neutral-400 ms-1"
										>{{ j.timezone }}</span
									>
								</p>
								<p v-if="hasTimezoneDifference" class="mb-2">
									<span class="text-neutral-400">Time: </span>
									<strong>{{
										new Date(meeting.starts_at).toLocaleString('en-US', {
											timeStyle: 'short',
										})
									}}</strong>
									<span class="badge bg-neutral-50 text-neutral-400 ms-1">Your time</span>
								</p>
								<p class="mb-2">
									<span class="text-neutral-400">Location: </span>
									<strong v-if="meeting.location">{{ formatAddress(meeting.location) }}</strong>
									<small v-else>-</small>
								</p>
							</div>
							<div class="col-lg-6 mb-3">
								<p class="mb-2">
									<span class="text-neutral-400 me-1">Department:</span>
									<template v-if="departments && meeting.department_id">{{
										departments.find(d => d.id == meeting.department_id).name
									}}</template>
									<small v-else class="text-neutral-300">(none)</small>
								</p>
								<p class="mb-2">
									<span class="text-neutral-400 me-1">
										{{
											meeting.categories && meeting.categories.length === 1
												? 'Category'
												: 'Categories'
										}}:
									</span>
									<template v-if="meeting.categories && meeting.categories.length">
										{{
											meetingCategories
												.filter(c => meeting.categories.includes(c.id))
												.map(c => c.name)
												.join(', ')
										}}
									</template>
									<small v-else class="text-neutral-300">(none)</small>
								</p>
								<p v-if="meeting.state === 'upcoming' && meeting.conferencing_link" class="mb-0">
									<a
										v-if="meeting.conferencing_link.includes('zoom.us/')"
										:href="meeting.conferencing_link"
										class="btn btn-sm btn-primary"
										target="_blank"
										>Join Zoom meeting</a
									>
									<a
										v-else-if="meeting.conferencing_link.includes('meet.google.com/')"
										:href="meeting.conferencing_link"
										class="btn btn-sm btn-primary"
										target="_blank"
										>Join Google Meet meeting</a
									>
									<a
										v-else
										:href="meeting.conferencing_link"
										class="btn btn-sm btn-primary"
										target="_blank"
										>Join meeting</a
									>
									<small
										v-if="meeting.conferencing_link_note"
										class="badge bg-neutral-50 text-neutral-500 ms-1 mt-1"
										>{{ meeting.conferencing_link_note }}</small
									>
								</p>
							</div>
						</div>
					</div>
				</div>

				<p class="card-text">
					<template v-if="meeting.description">{{ meeting.description }}</template>
					<small v-else class="text-neutral-300">(no meeting description)</small>
				</p>

				<hr class="my-3 bg-primary-100" />

				<p class="subtitle text-neutral-500 mb-2">Meeting workflow</p>

				<div class="list-group rounded-1">
					<div class="list-group-item">
						<span
							class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
							style="width: 24px; height: 24px"
							><font-awesome-icon :icon="['fas', 'check']"
						/></span>
						Meeting is scheduled
					</div>

					<router-link
						:to="`/${j.slug}/meetings/${meeting.pid}/agenda`"
						class="list-group-item list-group-item-action"
					>
						<template v-if="meeting.agenda_status === 'public'">
							<span
								class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'check']"
							/></span>
							Agenda is ready with <strong>{{ meeting.agenda_items.length }}</strong> items
						</template>
						<template v-else-if="meeting.agenda_status === 'in_review'">
							<span
								class="d-inline-block bg-primary-50 text-primary-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'info']"
							/></span>
							Agenda is being reviewed
						</template>
						<template v-else-if="meeting.agenda_items.length">
							<span
								class="d-inline-block bg-warning-50 text-warning-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'plus']"
							/></span>
							Agenda is in draft mode with {{ meeting.agenda_items.length }} items. Due
							{{ agendaCutOffDate | dateToNow }}
						</template>
						<template v-else>
							<span
								class="d-inline-block bg-warning-50 text-warning-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'plus']"
							/></span>
							Create agenda
						</template>
					</router-link>

					<div class="list-group-item">
						<template v-if="meeting.state === 'past'">
							<span
								class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'check']"
							/></span>
							Meeting took place <template v-if="meeting.location">at [address]</template>
						</template>
						<template v-else-if="meeting.state === 'happening'">
							<span
								class="d-inline-block bg-primary-50 text-primary-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'map-pin']"
							/></span>
							Meeting is happening right now <template v-if="meeting.location">at [address]</template>
						</template>
						<template v-else>
							<span
								class="d-inline-block bg-warning-50 text-warning-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'map-pin']"
							/></span>
							Meeting takes place <template v-if="meeting.location">at [address]</template>
						</template>
					</div>

					<router-link
						v-if="meeting.transcript_job_status === 'transcribed'"
						:to="`/${j.slug}/meetings/${meeting.pid}/transcript`"
						class="list-group-item list-group-item-action"
					>
						<span
							class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
							style="width: 24px; height: 24px"
							><font-awesome-icon :icon="['fas', 'check']"
						/></span>
						Recording of {{ Math.floor(meeting.recording_duration_seconds / 60) }} minutes added &amp;
						transcribed
					</router-link>
					<div v-else class="list-group-item">
						<span
							class="d-inline-block bg-warning-50 text-warning-400 text-center rounded me-1"
							style="width: 24px; height: 24px"
							><font-awesome-icon :icon="['fas', 'plus']"
						/></span>
						Upload recording
					</div>

					<router-link
						:to="`/${j.slug}/meetings/${meeting.pid}/minutes`"
						class="list-group-item list-group-item-action"
					>
						<template v-if="meeting.minutes_status === 'missing'">
							<span
								class="d-inline-block text-center rounded me-1"
								:class="
									meeting.state === 'past'
										? 'bg-warning-50 text-warning-400'
										: 'bg-neutral-50 text-neutral-400'
								"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'plus']"
							/></span>
							Create minutes
						</template>
						<template v-else>
							<span
								class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
								style="width: 24px; height: 24px"
								><font-awesome-icon :icon="['fas', 'check']"
							/></span>
							Minutes ready
						</template>
					</router-link>
				</div>
			</div>
		</div>

		<div v-if="isStaff" class="card">
			<div class="card-header bg-danger-lighter">
				<h5 class="my-0">👀 Only for HeyGov staff</h5>
			</div>
			<div class="card-body">
				<p class="card-text">
					AI cost: <strong>{{ numberToCurrency(meeting.ai_cost) }}</strong>
				</p>

				<hr />

				<p class="subtitle mb-1">Recording</p>
				<p class="card-text mb-2">
					Transcribe recording again:
					<a :href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start`" target="_blank"
						>With Deepgram</a
					>
					&middot;
					<a
						:href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start?service=revai`"
						target="_blank"
						>With RevAI</a
					>
				</p>
				<p class="card-text">
					Transcript AI processing:
					<a :href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-ai`" target="_blank"
						>Identify speakers, extract motions</a
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { formatDistanceToNow, subHours } from 'date-fns'

import { hgApi } from '@/api.js'
import { getPublicFileUrl, formatAddress, numberToCurrency } from '@/utils.js'

export default {
	name: 'MeetingsOverview',
	components: {},
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	data() {
		return {
			meetingCategories: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		agendaCutOffDate() {
			let agendaDate = new Date(this.meeting.starts_at)

			if (this.meeting.agenda_cut_off_hours) {
				agendaDate = subHours(agendaDate, this.meeting.agenda_cut_off_hours)
			}

			return agendaDate
		},
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadMeetingCategories()
	},
	methods: {
		getPublicFileUrl,
		formatDistanceToNow,
		numberToCurrency,
		formatAddress,

		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`).then(response => {
				if (response.ok) {
					response.json().then(categories => {
						this.meetingCategories.push(...categories)
					})
				} else {
					Vue.toasted.error('Failed to load meeting categories')
				}
			})
		},

		hasTimezoneDifference() {
			const jurisdictionTime = new Date(this.meeting.starts_at).toLocaleString('en-US', {
				timeStyle: 'short',
				timeZone: this.j.timezone,
			})

			const localTime = new Date(this.meeting.starts_at).toLocaleString('en-US', {
				timeStyle: 'short',
			})

			return jurisdictionTime !== localTime
		},
	},
}
</script>
