<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Reschedule Meeting</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
		</div>
		<div class="modal-body">
			<form @submit.prevent="handleSubmit">
				<div class="mb-3">
					<label class="form-label" for="start-date">Start Date & Time</label>
					<div class="row">
						<div class="col">
							<input
								id="start-date"
								type="date"
								class="form-control"
								v-model="formData.starts_at_date"
								required
							/>
						</div>
						<div class="col">
							<input
								id="start-time"
								type="time"
								class="form-control"
								v-model="formData.starts_at_time"
								required
							/>
						</div>
					</div>
				</div>
				<div class="mb-3">
					<label class="form-label" for="end-date">End Date & Time</label>
					<div class="row">
						<!-- FIXME: figure out why required=true when it should be false -->
						<div class="col">
							<input
								id="end-date"
								type="date"
								class="form-control"
								v-model="formData.ends_at_date"
								:required="!!formData.ends_at_time"
							/>
						</div>
						<div class="col">
							<input
								id="end-time"
								type="time"
								class="form-control"
								v-model="formData.ends_at_time"
								:required="!!formData.ends_at_date"
							/>
						</div>
					</div>
				</div>
				<div v-if="meetingDuration" class="mb-3">
					<label class="form-label">Meeting Duration</label>
					<p>{{ meetingDuration }}</p>
				</div>
				<div v-if="durationChanged" class="alert alert-warning">
					<p>Warning: Meeting duration has changed from {{ previousDuration }} to {{ meetingDuration }}</p>
				</div>
				<div
					v-if="
						!formData.starts_at_date ||
							!formData.starts_at_time ||
							(formData.ends_at_date && !formData.ends_at_time) ||
							(!formData.ends_at_date && formData.ends_at_time)
					"
					class="alert alert-warning"
				>
					<p>
						Warning: Start-time is required. End-time is optional but must have both date and time if set.
					</p>
				</div>
				<div v-if="isDurationNegative" class="alert alert-danger">
					<p>Warning: Meeting duration cannot be negative.</p>
				</div>
				<div class="d-flex justify-content-end gap-2">
					<button
						type="submit"
						class="btn btn-primary"
						:disabled="
							loading ||
								!formData.starts_at_date ||
								!formData.starts_at_time ||
								(formData.ends_at_date && !formData.ends_at_time) ||
								(!formData.ends_at_date && formData.ends_at_time) ||
								isDurationNegative
						"
					>
						<span v-if="loading" class="spinner-border spinner-border-sm me-2"></span>
						Reschedule Meeting
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'RescheduleMeetingModal',
	props: {
		meeting: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			formData: {
				starts_at_date: '',
				starts_at_time: '',
				ends_at_date: '',
				ends_at_time: '',
			},
			previousDuration: null,
		}
	},
	computed: {
		...mapState(['j']),
		meetingDuration() {
			if (
				!this.formData.starts_at_date ||
				!this.formData.starts_at_time ||
				!this.formData.ends_at_date ||
				!this.formData.ends_at_time
			) {
				return null
			}
			const startsAt = new Date(`${this.formData.starts_at_date}T${this.formData.starts_at_time}`)
			const endsAt = new Date(`${this.formData.ends_at_date}T${this.formData.ends_at_time}`)
			const duration = (endsAt - startsAt) / (1000 * 60) // duration in minutes
			const hours = Math.floor(duration / 60)
			const minutes = duration % 60
			return `${hours}h ${minutes}m`
		},
		durationChanged() {
			return this.previousDuration && this.meetingDuration && this.previousDuration !== this.meetingDuration
		},
		isDurationNegative() {
			if (
				!this.formData.starts_at_date ||
				!this.formData.starts_at_time ||
				!this.formData.ends_at_date ||
				!this.formData.ends_at_time
			) {
				return false
			}
			const startsAt = new Date(`${this.formData.starts_at_date}T${this.formData.starts_at_time}`)
			const endsAt = new Date(`${this.formData.ends_at_date}T${this.formData.ends_at_time}`)
			return endsAt < startsAt
		},
	},
	watch: {
		meeting: {
			immediate: true,
			deep: true,
			handler(meeting) {
				if (meeting) {
					const startsAtLocal = this.timeFromUTCToJurisdiction(meeting.starts_at)
					const startsAtDate = startsAtLocal.split(', ')[0]
					const startsAtTime = startsAtLocal.split(', ')[1]

					// Convert date: YYYY-MM-DD format
					const [month, day, year] = startsAtDate.split('/')
					this.formData.starts_at_date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`

					// Convert time: 12-hour -> 24-hour format
					const [time, period] = startsAtTime.split(' ')
					const [hours, minutes] = time.split(':')
					let hour24 = parseInt(hours)
					if (period === 'PM' && hour24 !== 12) hour24 += 12
					if (period === 'AM' && hour24 === 12) hour24 = 0
					this.formData.starts_at_time = `${hour24.toString().padStart(2, '0')}:${minutes}`

					if (meeting.ends_at) {
						const endsAtLocal = this.timeFromUTCToJurisdiction(meeting.ends_at)
						const endsAtDate = endsAtLocal.split(', ')[0]
						const endsAtTime = endsAtLocal.split(', ')[1]

						const [endMonth, endDay, endYear] = endsAtDate.split('/')
						this.formData.ends_at_date = `${endYear}-${endMonth.padStart(2, '0')}-${endDay.padStart(
							2,
							'0'
						)}`

						const [endTime, endPeriod] = endsAtTime.split(' ')
						const [endHours, endMinutes] = endTime.split(':')
						let endHour24 = parseInt(endHours)
						if (endPeriod === 'PM' && endHour24 !== 12) endHour24 += 12
						if (endPeriod === 'AM' && endHour24 === 12) endHour24 = 0
						this.formData.ends_at_time = `${endHour24.toString().padStart(2, '0')}:${endMinutes}`
					} else {
						this.formData.ends_at_date = ''
						this.formData.ends_at_time = ''
					}

					// Calculate previous duration
					if (meeting.starts_at && meeting.ends_at) {
						const previousStartsAt = new Date(meeting.starts_at)
						const previousEndsAt = new Date(meeting.ends_at)
						const previousDurationMinutes = (previousEndsAt - previousStartsAt) / (1000 * 60)
						const previousHours = Math.floor(previousDurationMinutes / 60)
						const previousMinutes = previousDurationMinutes % 60
						this.previousDuration = `${previousHours}h ${previousMinutes}m`
					} else {
						this.previousDuration = null
					}
				}
			},
		},
	},
	methods: {
		handleSubmit() {
			this.loading = true

			const startsAt = `${this.formData.starts_at_date} ${this.formData.starts_at_time}`
			const endsAt =
				this.formData.ends_at_date && this.formData.ends_at_time
					? `${this.formData.ends_at_date} ${this.formData.ends_at_time}`
					: null

			this.$emit('submit', { starts_at_local: startsAt, ends_at_local: endsAt }, () => {
				this.loading = false
			})
		},
		timeFromUTCToJurisdiction(time) {
			return new Date(time).toLocaleString('en-US', {
				timeZone: this.j.timezone,
			})
		},
	},
}
</script>
