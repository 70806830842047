<template>
	<div class="page-events">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">News &amp; Notices</h2>
			</div>
			<div class="col-auto">
				<a
					:href="websiteUrl(j.website, `/news-and-notices/`)"
					target="_blank"
					class="btn btn-outline-primary btn-sm me-2"
				>
					View on website
				</a>
				<router-link :to="`/${j.slug}/news-notices/create`" class="btn btn-primary btn-sm btn-big-icon">
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add News or Notice
				</router-link>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th></th>
								<th>Title</th>
								<th>Date</th>
								<th>Sent to</th>
								<th class="text-end">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="notice in newsNotices" :key="notice.id">
								<td>
									<img
										v-if="notice.image_url"
										:src="notice.image_url"
										width="128"
										height="72"
										class="rounded-1"
										:alt="notice.title"
										style="object-fit: cover"
									/>
									<img
										v-else
										src="https://picsum.photos/128/72?grayscale"
										width="128"
										height="72"
										class="rounded-1"
									/>
								</td>
								<td>
									{{ notice.title }}
								</td>
								<td>
									<small class="text-neutral-500">{{ new Date(notice.created_at) | dateToNow }}</small>
								</td>
								<td>
									<p v-if="notice.channels.includes('website')" class="mb-1">
										<span class="badge bg-primary-50 text-primary-400">Website: {{ notice.category || 'News' }}</span>
									</p>
									<p v-if="notice.channels.includes('facebook')" class="mb-1">
										<span class="badge bg-primary-50 text-primary-400">Facebook: {{ j.name }} Page</span>
									</p>
									<p v-if="notice.channels.includes('subscribers')" class="mb-0">
										<span class="badge bg-primary-50 text-primary-400">
											Subscribers: {{ notice.subscribers_sent || 0 }}/{{ notice.subscribers_total || 0 }}
										</span>
									</p>
								</td>
								<td class="text-end">
									<button
										class="btn btn-sm btn-link text-danger"
										@click="deleteNewsNotice(notice)"
										title="Delete news/notice"
										:disabled="states.deleting === notice.id"
									>
										<template v-if="states.deleting === notice.id">
											<span class="spinner-border spinner-border-sm me-1"></span>
											Deleting...
										</template>
										<template v-else>
											<font-awesome-icon :icon="['fas', 'trash']" />
										</template>
									</button>
								</td>
							</tr>
							<tr v-if="states.newsNotices === 'loading'">
								<td colspan="4" class="text-center py-4">
									<span class="spinner-border spinner-border-sm text-primary me-2"></span>
									Loading news notices...
								</td>
							</tr>
							<tr v-else-if="states.newsNotices === 'error'">
								<td colspan="4" class="text-center py-4 text-danger">
									Failed to load news notices. Please try again.
								</td>
							</tr>
							<tr v-else-if="newsNotices.length === 0">
								<td colspan="4" class="text-center py-4 text-muted">
									No news notices found.
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="d-flex justify-content-between align-items-center mt-4">
					<div class="d-flex align-items-center">
						<select v-model="pag.limit" class="form-select form-select-sm w-auto">
							<option :value="10">10 per page</option>
							<option :value="25">25 per page</option>
							<option :value="50">50 per page</option>
							<option :value="100">100 per page</option>
						</select>
					</div>

					<nav v-if="pag.total > pag.limit">
						<ul class="pagination pagination-sm mb-0">
							<li
								:class="['page-item', { disabled: pag.page === 1 }]"
								@click="pag.page > 1 && (pag.page--)"
							>
								<a class="page-link" href="#" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>
							<li
								v-for="p in pages"
								:key="p"
								:class="['page-item', { active: pag.page === p }]"
								@click="pag.page = p"
							>
								<a class="page-link" href="#">{{ p }}</a>
							</li>
							<li
								:class="['page-item', { disabled: pag.page === pages }]"
								@click="pag.page < pages && (pag.page++)"
							>
								<a class="page-link" href="#" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'
import { websiteUrl } from '@/utils.js'

export default {
	name: 'NewsNotices',
	components: {},
	data() {
		return {
			states: {
				newsNotices: 'loading',
				categories: 'loading',
				deleting: null,
			},
			categories: [],
			newsNotices: [],
			pag: {
				page: 1,
				limit: Number(localStorage.getItem('news-notices-per-page')) || 10,
				total: 0,
			},
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole']),
		pages() {
			return Math.ceil(this.pag.total / this.pag.limit)
		},
	},
	created() {
		//this.loadCategories()
		this.loadNewsNotices()
	},

	methods: {
		websiteUrl,

		loadNewsNotices() {
			this.states.newsNotices = 'loading'

			const params = {
				page: this.pag.page,
				limit: this.pag.limit,
			}

			heyGovApi
				.get(`${this.j.slug}/news-notices`, { params })
				.then(({ data }) => {
					this.newsNotices = data.data.data.map(notice => ({
						...notice,
						image_url: notice.featured_media?.url,
						channels: ['website'],
						category: notice.categories?.[0]?.name || 'News',
						created_at: notice.created_on,
						subscribers_sent: 0,
						subscribers_total: 0
					}))
					this.pag.total = data.data.meta.records
					this.states.newsNotices = 'idle'
				})
				.catch(error => {
					Vue.toasted.error(`Failed to load news notices (${error.message})`)
					this.states.newsNotices = 'error'
				})
		},

		updatePageUrl() {
			let query = {}

			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}

			this.$router.replace({ path: `/${this.j.slug}/news-notices`, query }).catch(() => {})
		},

		deleteNewsNotice(notice) {
			if (confirm(`Are you sure you want to delete "${notice.title}"?`)) {
				this.states.deleting = notice.id

				heyGovApi
					.delete(`${this.j.slug}/news-notices/${notice.id}`)
					.then(() => {
						Vue.toasted.success(`"${notice.title}" has been deleted`)
						this.newsNotices = this.newsNotices.filter(n => n.id !== notice.id)
					})
					.catch(error => {
						Vue.toasted.error(`Failed to delete news notice (${error.message})`)
					})
					.finally(() => {
						this.states.deleting = null
					})
			}
		},
	},

	watch: {
		'pag.page'() {
			this.newsNotices = []
			this.loadNewsNotices()
			this.updatePageUrl()
		},
		'pag.limit'() {
			this.newsNotices = []
			this.loadNewsNotices()
			localStorage.setItem('news-notices-per-page', this.pag.limit)
		},
	},
}
</script>

<style scoped>
.pagination {
	cursor: pointer;
}
.page-link {
	user-select: none;
}
</style>
