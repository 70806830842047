<template>
	<div class="page-meetings-single">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/meetings`">Meetings</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			Add meeting
		</h3>

		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 col-xl-7">
				<div class="card mb-4">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">
								Add meeting
							</h5>
							<help-button
								url="https://townweb.notion.site/Create-a-meeting-b7260037f1d44c0dba0280a904c3673b"
								text="How to create meetings"
							></help-button>
						</div>
					</div>
					<form @submit.prevent="createMeeting">
						<div class="card-body">
							<div class="form-group mb-3">
								<label class="form-label" for="meeting-title"
									>Meeting title <small class="text-danger-400">*</small></label
								>

								<input
									type="text"
									class="form-control"
									id="meeting-title"
									v-model="meeting.title"
									required
									placeholder="Short and descriptive title"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="meeting-date"
									>Date <small class="text-danger-400">*</small></label
								>

								<div class="row">
									<div class="col">
										<input
											type="date"
											class="form-control"
											id="meeting-date"
											v-model="meeting.starts_at_date"
											required
										/>
									</div>
									<div class="col">
										<input
											type="time"
											class="form-control"
											id="meeting-time"
											v-model="meeting.starts_at_time"
											required
										/>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="meeting-end-date">End date & time</label>

								<div class="row">
									<div class="col">
										<input
											type="date"
											class="form-control"
											id="meeting-end-date"
											v-model="meeting.ends_at_date"
											:min="meeting.starts_at_date"
											:required="!!meeting.ends_at_time"
										/>
									</div>
									<div class="col">
										<input
											type="time"
											class="form-control"
											id="meeting-end-time"
											v-model="meeting.ends_at_time"
											:required="!!meeting.ends_at_date"
										/>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="meeting-description">Description</label>

								<textarea
									class="form-control"
									id="meeting-description"
									rows="3"
									v-model="meeting.description"
									placeholder="A short text about what the meeting is about"
								></textarea>
							</div>

							<div class="row">
								<div class="col form-group mb-3">
									<label class="form-label" for="meeting-categories">Categories</label>

									<multiselect
										v-model="meeting.categories"
										:options="meetingCategories"
										:multiple="true"
										track-by="id"
										label="name"
										placeholder="Select categories"
										:hideSelected="true"
										:taggable="true"
										@tag="addCategory"
										tag-placeholder="Add this as new category"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{
											option.name
										}}</template>
									</multiselect>
								</div>

								<div class="col form-group mb-3">
									<label class="form-label" for="meeting-department">Department</label>

									<select class="form-select" id="meeting-department" v-model="meeting.department_id">
										<option :value="null">-</option>
										<option
											v-for="department in departments"
											:key="`dept-${department.id}`"
											:value="department.id"
											>{{ department.name }}</option
										>
									</select>
								</div>
							</div>

							<div v-if="j.features.includes('venues') && venues.length" class="row">
								<div class="col-md-6 form-group mb-3">
									<label class="form-label" for="meeting-venue">Venue</label>
									<select class="form-select" id="meeting-venue" v-model="meeting.venue_id">
										<option :value="null">Select a venue</option>
										<option v-for="venue in venues" :key="venue.id" :value="venue.id">
											{{ venue.name }}
										</option>
									</select>
								</div>
							</div>

							<div v-show="meetingDateTime > new Date()" class="form-group mb-3">
								<label class="form-label" for="meeting-conferencing-link">Conferencing Link</label>
								<input
									type="url"
									class="form-control"
									id="meeting-conferencing-link"
									v-model="meeting.conferencing_link"
									placeholder="Zoom, Meet or YouTube Live link"
								/>
							</div>

							<div
								v-show="meetingDateTime > new Date() && meeting.conferencing_link"
								class="form-group mb-3"
							>
								<label class="form-label" for="meeting-conferencing-link-note"
									>Conferencing Link Note</label
								>
								<textarea
									class="form-control"
									id="meeting-conferencing-link-note"
									v-model="meeting.conferencing_link_note"
									placeholder="Password, meeting PIN, etc."
									rows="2"
								></textarea>
							</div>

							<div v-if="meetingDateTime > new Date()" class="form-group mb-3">
								<label class="form-label" for="meeting-cut-off">Agenda editing cut-off time</label>
								<select class="form-select" id="meeting-cut-off" v-model="meeting.agenda_cut_off_hours">
									<option :value="null">No cut-off</option>
									<option v-for="option in cutOffOptions" :key="option.value" :value="option.value">
										{{ option.label }}
									</option>
								</select>
							</div>
						</div>

						<div class="card-footer d-flex align-items-center justify-content-end">
							<button class="btn btn-primary px-5">Create meeting →</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { format, isMatch, startOfHour } from 'date-fns'
import { mapState } from 'vuex'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { hgApi } from '@/api.js'

export default {
	name: 'MeetingsCreate',
	components: { Multiselect },
	data() {
		const today = new Date()

		const starts_at_date = isMatch(this.$route.query.date, 'yyyy-MM-dd')
			? this.$route.query.date
			: format(today, 'yyyy-MM-dd')
		const starts_at_time = isMatch(this.$route.query.time, 'HH:mm')
			? this.$route.query.time
			: format(startOfHour(today), 'HH:mm')

		return {
			states: {
				meeting: 'idle',
			},
			meeting: {
				title: String(this.$route.query.name || '').trim(),
				description: String(this.$route.query.description || '').trim(),
				starts_at_date,
				starts_at_time,
				ends_at_date: '',
				ends_at_time: '',
				department_id: null,
				categories: [],
				venue_id: null,
				agenda_cut_off_hours: null,
				conferencing_link: '',
				conferencing_link_note: '',
			},
			meetingCategories: [],
			cutOffOptions: [
				{ label: '24 hours', value: 24 },
				{ label: '48 hours', value: 48 },
				{ label: '72 hours', value: 72 },
				{ label: '7 days', value: 7 * 24 },
				{ label: '10 days', value: 10 * 24 },
				{ label: '14 days', value: 14 * 24 },
				{ label: '30 days', value: 30 * 24 },
			],
		}
	},
	computed: {
		...mapState(['j', 'departments', 'venues']),
		meetingDateTime() {
			return new Date(`${this.meeting.starts_at_date} ${this.meeting.starts_at_time}`)
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getVenues')
		this.loadMeetingCategories()
	},
	methods: {
		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`)
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(categories => {
					this.meetingCategories = categories
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't load meeting categories (${error})`)
				})
		},

		createMeeting() {
			this.states.meeting = 'loading'

			const body = {
				title: this.meeting.title,
				description: this.meeting.description,
				starts_at_local: `${this.meeting.starts_at_date} ${this.meeting.starts_at_time}:00`,
				ends_at_local: this.meeting.ends_at_date
					? `${this.meeting.ends_at_date} ${this.meeting.ends_at_time || '23:59'}:00`
					: null,
				department_id: this.meeting.department_id,
				categories: this.meeting.categories.map(c => c.id),
				venue_id: this.meeting.venue_id,
				agenda_cut_off_hours: this.meeting.agenda_cut_off_hours,
				conferencing_link: this.meeting.conferencing_link,
				conferencing_link_note: this.meeting.conferencing_link_note,
			}

			hgApi(`${this.j.slug}/meetings`, {
				body,
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(meeting => {
					Vue.toasted.success('Meeting created successfully')

					this.$router.push(`/${this.j.slug}/meetings/${meeting.pid}`)
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't create meeting (${error})`)
					this.states.meeting = 'error'
				})
		},

		addCategory(name) {
			hgApi(`${this.j.slug}/meetings/categories`, {
				body: { name },
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(category => {
					this.meetingCategories.push(category)
					this.meeting.categories.push(category)
					Vue.toasted.success('Meeting category created successfully')
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't create category (${error})`)
				})
		},
	},
}
</script>
