<template>
	<div class="page-form-create">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/forms`">HeyLicense</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> Create form
		</h3>

		<div class="card">
			<div class="card-body">
				<form @submit.prevent="saveForm">
					<p class="lead">Do you want to start from a blank state or use a pre-made form?</p>

					<div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 gx-3 gy-3 mb-4">
						<div class="col">
							<div
								class="card h-100 rounded-1 cursor-pointer border"
								:class="{ 'border-primary bg-info-lighter': !form.template }"
								@click="selectTemplate(null)"
							>
								<div class="card-body">
									<h6 class="mb-3">Blank form</h6>

									<p class="card-text text-neutral-500">
										All any steps and fields needed through the Form Builder.
									</p>
								</div>
							</div>
						</div>

						<div v-for="template in templates" :key="`form-template-${template.id}`" class="col">
							<div
								class="card h-100 rounded-1 cursor-pointer border"
								:class="form.template === template.id ? 'border-primary bg-info-lighter' : ''"
								@click="selectTemplate(template)"
							>
								<div class="card-body">
									<h6>
										<font-awesome-icon v-if="template.icon" :icon="['fas', template.icon]" />
										{{ template.name }}
									</h6>

									<p class="card-text line-clamp-3">{{ template.description }}</p>
								</div>
							</div>
						</div>
					</div>

					<hr class="bg-primary-100" />

					<div class="row">
						<div class="col-md-6 col-md-7">
							<div class="form-group mb-3">
								<label class="form-label" for="name">Name <span class="text-danger">*</span></label>
								<input
									class="form-control"
									id="name"
									v-model="form.name"
									placeholder="License or Permit name"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-department"
									>Department <span class="text-danger">*</span></label
								>
								<select class="form-select" id="form-department" v-model="form.department_id" required>
									<option :value="null">Select a department</option>
									<option
										v-for="department in activeDepartments"
										:key="`form-dept-${department.id}`"
										:value="department.id"
										>{{ department.name }}</option
									>
								</select>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-description">Description</label>
								<textarea
									class="form-control"
									id="form-description"
									v-model="form.description"
									rows="4"
									placeholder="License or Permit description"
								></textarea>
							</div>
						</div>

						<div class="col">
							<div class="form-group mb-3">
								<label class="form-label" for="form-type">Form type</label>
								<select class="form-select" id="form-type" v-model="form.type">
									<option value="form">Form</option>
									<option value="permit">Permit</option>
									<option value="license">License</option>
									<option value="application">Application</option>
								</select>
							</div>

							<div class="form-group mb-3">
								<label for="form-application-prefix" class="form-label">Applications prefix</label>

								<input
									type="text"
									class="form-control"
									id="form-application-prefix"
									v-model="form.application_prefix"
									maxlength="8"
									placeholder="DOG, HG, BOAT-{year}, etc"
								/>
							</div>

							<div v-if="!form.template" class="form-group mb-3">
								<label class="form-label" for="file">Existing {{ form.type || 'form' }} PDF</label>
								<input
									class="form-control"
									type="file"
									id="file"
									@change="handleFileInput($event)"
									accept="application/pdf"
								/>
								<small class="form-text">This will help us digitize faster the form for you</small>
							</div>
						</div>
					</div>

					<p class="card-text text-center">
						<button class="btn btn-primary px-5" :disabled="states.form === 'saving'">Create form</button>
						<span v-if="states.form === 'saving'" class="ms-2 text-primary-200">Creating form..</span>
					</p>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'

export default {
	name: 'FormCreate',
	metaInfo: {
		title: `Create form`,
	},
	data() {
		return {
			states: {
				form: 'idle',
			},
			templates: [],
			form: {
				id: null,
				slug: null,
				department_id: null,
				name: '',
				description: '',
				icon: '',
				type: 'application',
				application_prefix: null,
				upload_file: null,
				template: null,
			},
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['activeDepartments']),
	},
	created() {
		this.loadTemplates()
		this.$store.dispatch('loadDepartments')
	},
	methods: {
		loadTemplates() {
			heyGovApi.get(`${this.j.slug}/forms/templates`).then(
				({ data }) => {
					this.templates.push(...data)
				},
				error => {
					Vue.toasted.error(`Error loading templates (${error.message})`)
				}
			)
		},
		selectTemplate(template) {
			if (template) {
				this.form.name = template.name
				this.form.description = template.description
				this.form.application_prefix = template.application_prefix
				this.form.icon = template.icon
				this.form.type = template.type
				this.form.template = template.id
			} else {
				this.form.name = ''
				this.form.description = ''
				this.form.application_prefix = ''
				this.form.icon = ''
				this.form.type = 'application'
				this.form.template = null
			}
		},

		saveForm() {
			this.states.form = 'saving'

			heyGovApi
				.post(`/${this.j.slug}/forms`, {
					name: this.form.name,
					description: this.form.description,
					department_id: this.form.department_id,
					application_prefix: this.form.application_prefix,
					type: this.form.type,
					icon: this.form.icon,
					template: this.form.template,
				})
				.then(
					({ data }) => {
						this.form.id = data.id
						this.form.slug = data.slug

						this.$store.commit('addForm', data)

						if (this.form.upload_file) {
							this.uploadFormFile()
						} else {
							this.formCreationFinished()
						}
					},
					error => {
						this.states.form = 'error'
						Vue.toasted.error(`Error creating form (${error.message})`)
					}
				)
		},
		handleFileInput($event) {
			this.form.upload_file = $event.target.files[0]
		},
		uploadFormFile() {
			// prepare file data
			var formFile = new FormData()
			formFile.append('file', this.form.upload_file)

			// upload the file
			heyGovApi.post(`/${this.j.slug}/forms/${this.form.id}/file`, formFile).then(
				() => {
					this.formCreationFinished()
				},
				error => {
					this.states.form = 'error'
					Vue.toasted.error(`Error uploading form PDF (${error.message})`)
				}
			)
		},
		formCreationFinished() {
			this.states.form = 'idle'
			this.$router.push(`/${this.j.slug}/forms/${this.form.slug}/formBuilder?created=1`)
		},
	},
}
</script>
