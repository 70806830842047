<template>
	<div id="app" :class="{ 'is-embed': isEmbed }">
		<div v-if="(onboarded || j) && !isEmbed" class="header-overlay d-md-none"></div>

		<div class="container-fluid" :class="[`app-${device.platform}`]">
			<div class="d-flex" :class="{ 'is-sidebar-mini': sidebarMini }">
				<aside v-if="shouldShowSidebar && !isEmbed" class="col-sidebar">
					<sidebar class="sticky-top" style="top: 1rem" />
				</aside>
				<div class="col col-content ps-md-3" id="content">
					<navbar v-if="(onboarded || j) && !isEmbed" />
					<router-view
						ref="appPage"
						:class="isEmbed ? 'p-3' : 'pb-3 mb-6'"
						:isEmbed="isEmbed"
						@assistantMessage="sendMessageToAiAssistant"
					/>
				</div>
			</div>
		</div>

		<footer v-if="!isEmbed" class="site-footer py-2 d-none d-md-block">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 my-2">
						<p class="mb-0">
							<strong class="text-bolder">Stay in touch:</strong> &nbsp;
							<a href="https://heygov.com/blog" target="_blank">Blog</a>, &nbsp;
							<a href="mailto:support@heygov.com">Contact Us</a>
						</p>
					</div>

					<div class="col-12 col-md-6 my-2">
						<p class="mb-0 text-md-end">
							<strong class="text-bolder">Documentation:</strong> &nbsp;
							<router-link to="/about-heygov">About HeyGov</router-link>, &nbsp;
							<a href="https://heygov.com/privacy-policy" target="heygov">Privacy Policy</a>, &nbsp;
							<a href="https://heygov.com/terms-and-conditions" target="heygov">Terms and Conditions</a>
						</p>
					</div>
				</div>
			</div>
		</footer>

		<mobile-nav v-if="j && !isEmbed" class="d-sm-none"></mobile-nav>

		<!--
		<div v-if="j && !$route.meta.hideMenu" class="heygov-big-button" :class="{ 'menu-open': bigMenu }">
			<div class="overlay" @click="bigMenu = false"></div>

			<nav class="nav heygov-big-nav flex-column text-end">
				<router-link
					v-if="bigMenu"
					:to="`/${j.slug}/threads/create`"
					class="nav-link"
					@click.native="bigMenu = false"
					>Report a <span class="text-capitalize">{{ j.type }}</span> Issue ⚠️</router-link
				>
				<router-link
					v-if="bigMenu && j.features && j.features.includes('chatbot')"
					:to="`/${j.slug}/chatbot`"
					class="nav-link"
					@click.native="bigMenu = false"
					>Ask a Question ❓</router-link
				>
				<div class="nav-link">
					<button
						@click="
							bigMenu = !bigMenu
						"
					>
						HeyGov
					</button>
				</div>
			</nav>
		</div>
		-->

		<div
			class="offcanvas"
			:class="[isMobile ? 'offcanvas-bottom' : 'offcanvas-end']"
			tabindex="-1"
			id="person-preview"
			style="width: 500px;"
		>
			<div class="offcanvas-body">
				<div v-if="people[previewPersonId]">
					<div class="p-3 rounded-1 bg-info-lighter mb-4">
						<div class="row align-items-center">
							<div class="col">
								<h3 v-if="people[previewPersonId].name" class="mb-0">
									{{ people[previewPersonId].name }}
								</h3>
								<p v-else class="mb-0">
									{{ people[previewPersonId].email || people[previewPersonId].anonymous_name }}
								</p>

								<p v-if="people[previewPersonId].bio" class="mb-0 mt-2">
									{{ people[previewPersonId].bio }}
								</p>
							</div>
							<div class="col-auto">
								<person-avatar
									:person="people[previewPersonId]"
									:preview="false"
									:tooltip="false"
									:size="75"
								></person-avatar>
							</div>
						</div>
					</div>

					<div v-if="people[previewPersonId].roles.some(r => r.role === 'BOT')" class="text-center">
						This is a bot, not much to see here 🤷
					</div>
					<template v-else-if="currentRole !== 'CITIZEN'">
						<h6 class="mb-1">Personal &amp; contact details</h6>

						<div style="margin-left: -1rem; margin-right: -1rem" class="mb-4">
							<table class="table table-sms table-hover">
								<tbody>
									<tr>
										<td class="ps-3">Email</td>
										<td>
											<template v-if="people[previewPersonId].email">
												<a :href="`mailto:${people[previewPersonId].email}`">{{
													people[previewPersonId].email
												}}</a>
												<font-awesome-icon
													:icon="['fas', 'copy']"
													@click="copyToClipboard(people[previewPersonId].email)"
													class="ms-2 text-neutral-400 cursor-pointer"
												/>
											</template>
											<small v-else class="text-neutral-300">(no email)</small>
										</td>
									</tr>
									<tr>
										<td class="ps-3">Phone</td>
										<td>
											<span v-if="people[previewPersonId].phone">
												<a :href="`tel:${people[previewPersonId].phone}`">{{
													people[previewPersonId].phone
												}}</a>
												<font-awesome-icon
													:icon="['fas', 'copy']"
													@click="copyToClipboard(people[previewPersonId].phone)"
													class="ms-2 text-neutral-400 cursor-pointer"
												/>
											</span>
											<small v-else class="text-neutral-300">(no phone number)</small>
										</td>
									</tr>
									<tr>
										<td class="ps-3">Address</td>
										<td>
											<template v-if="people[previewPersonId].address_json">
												<a
													:href="
														`https://www.google.com/maps/place/${formatAddress(
															people[previewPersonId].address_json,
															{ country: true }
														)}`
													"
													target="_blank"
													>{{ formatAddress(people[previewPersonId].address_json) }}</a
												>
												<font-awesome-icon
													:icon="['fas', 'copy']"
													@click="
														copyToClipboard(
															formatAddress(people[previewPersonId].address_json)
														)
													"
													class="ms-2 text-neutral-400 cursor-pointer"
												/>
											</template>
											<template v-else-if="people[previewPersonId].address">
												<a
													:href="
														`https://www.google.com/maps/place/${people[previewPersonId].address}`
													"
													target="_blank"
													>{{ people[previewPersonId].address }}</a
												>
												<font-awesome-icon
													:icon="['fas', 'copy']"
													@click="copyToClipboard(people[previewPersonId].address)"
													class="ms-2 text-neutral-400 cursor-pointer"
												/>
											</template>
											<small v-else class="text-neutral-300">(no address)</small>
										</td>
									</tr>
									<tr v-if="people[previewPersonId].dob">
										<td class="ps-3">Date of birth</td>
										<td>
											{{
												people[previewPersonId].dob
													| dateLocal('default', { dateStyle: 'medium' })
											}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="row mb-1 align-items-center">
							<div class="col">
								<h6 class="my-0">Payments</h6>
							</div>
							<div v-if="personPreviewData.payments.length" class="col-auto">
								<router-link
									:to="
										`/${j.slug}/payments/${personPreviewData.payments[0].department_id}/payments?person_id=${previewPersonId}`
									"
									>View all</router-link
								>
							</div>
						</div>

						<div style="margin-left: -1rem; margin-right: -1rem" class="mb-4">
							<table class="table table-hover">
								<thead>
									<tr>
										<th class="ps-3">Amount</th>
										<th>Fee</th>
										<th>Date</th>
										<th>Status</th>
										<th>Method</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="payment in personPreviewData.payments" :key="payment.id">
										<td class="ps-3">{{ payment.amount | currency }}</td>
										<td>{{ payment.fee | currency }}</td>
										<td>{{ payment.created_at | dateLocal }}</td>
										<td><payment-status :payment="payment"></payment-status></td>
										<td>
											<payment-method
												v-if="payment.payment_method"
												:payment-method="payment.payment_method"
											></payment-method>
											<small v-else class="text-neutral-300">-</small>
										</td>
									</tr>

									<tr v-if="personPreviewDataState === 'loading'">
										<td colspan="5" class="py-4 text-center text-neutral-400">
											<span
												class="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											Loading payments..
										</td>
									</tr>
									<tr v-else-if="personPreviewDataState === 'error'">
										<td colspan="5" class="py-4 text-center text-danger-400">
											Error loading payments ({{ personPreviewDataError }})
										</td>
									</tr>
									<tr v-else-if="!personPreviewData.payments.length">
										<td colspan="5" class="py-4 text-center text-neutral-400">
											No payments yet..
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<template v-if="j.features.includes('forms')">
							<div class="row mb-1 align-items-center">
								<div class="col">
									<h6 class="my-0">Form submissions</h6>
								</div>
								<div v-if="personPreviewData.formSubmissions.length" class="col-auto">
									<router-link :to="`/${j.slug}/form-requests?person_id=${previewPersonId}`"
										>View all</router-link
									>
								</div>
							</div>

							<div style="margin-left: -1rem; margin-right: -1rem" class="mb-4">
								<table class="table table-hover">
									<thead>
										<tr>
											<th class="ps-3">#</th>
											<th>Form</th>
											<th>Date</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										<router-link
											v-for="formSubmission in personPreviewData.formSubmissions"
											:key="formSubmission.id"
											:to="
												`/${j.slug}/forms/${
													forms.find(f => f.id === formSubmission.form_id).slug
												}/request-review/${formSubmission.pid}`
											"
											tag="tr"
											data-bs-dismiss="offcanvas"
											class="cursor-pointer"
										>
											<td class="ps-3">
												<template v-if="formSubmission.application_number">{{
													formSubmission.application_number
												}}</template>
												<small v-else class="text-neutral-300">-</small>
											</td>
											<td>
												<template
													v-if="forms && forms.find(f => f.id === formSubmission.form_id)"
													>{{
														forms.find(f => f.id === formSubmission.form_id).name
													}}</template
												>
												<code v-else>form {{ formSubmission.form_id }}</code>
											</td>
											<td>{{ formSubmission.sent_at | dateLocal }}</td>
											<td>
												<form-request-status
													:form-request="formSubmission"
												></form-request-status>
											</td>
										</router-link>

										<tr v-if="personPreviewDataState === 'loading'">
											<td colspan="5" class="py-4 text-center text-neutral-400">
												<span
													class="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
												Loading form submissions..
											</td>
										</tr>
										<tr v-else-if="personPreviewDataState === 'error'">
											<td colspan="5" class="py-4 text-center text-danger-400">
												Error loading form submissions ({{ personPreviewDataError }})
											</td>
										</tr>
										<tr v-else-if="!personPreviewData.formSubmissions.length">
											<td colspan="5" class="py-4 text-center text-neutral-400">
												No form submissions yet..
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</template>

						<template v-if="j.features.includes('venues')">
							<div class="row mb-1 align-items-center">
								<div class="col">
									<h6 class="my-0">Venue reservations</h6>
								</div>
								<div v-if="personPreviewData.venueReservations.length" class="col-auto">
									<router-link :to="`/${j.slug}/venues/bookings?person_id=${previewPersonId}`"
										>View all</router-link
									>
								</div>
							</div>

							<div style="margin-left: -1rem; margin-right: -1rem" class="mb-4">
								<table class="table table-hover">
									<thead>
										<tr>
											<th class="ps-3">Venue</th>
											<th>Booking Date</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										<router-link
											v-for="venueReservation in personPreviewData.venueReservations"
											:key="venueReservation.id"
											:to="
												`/${j.slug}/venues/${
													venues.find(v => v.id === venueReservation.venue_id).slug
												}/bookings/${venueReservation.pid}`
											"
											tag="tr"
											data-bs-dismiss="offcanvas"
											class="cursor-pointer"
										>
											<td class="ps-3">
												<template
													v-if="
														venues && venues.find(v => v.id === venueReservation.venue_id)
													"
													>{{
														venues.find(v => v.id === venueReservation.venue_id).name
													}}</template
												>
												<code v-else>venue {{ venueReservation.venue_id }}</code>
											</td>
											<td>{{ venueReservation.starts_at | dateLocal }}</td>
											<td>
												<venue-booking-status
													:venue-booking="venueReservation"
												></venue-booking-status>
											</td>
										</router-link>

										<tr v-if="personPreviewDataState === 'loading'">
											<td colspan="5" class="py-4 text-center text-neutral-400">
												<span
													class="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
												Loading venue reservations..
											</td>
										</tr>
										<tr v-else-if="personPreviewDataState === 'error'">
											<td colspan="5" class="py-4 text-center text-danger-400">
												Error loading venue reservations ({{ personPreviewDataError }})
											</td>
										</tr>
										<tr v-else-if="!personPreviewData.venueReservations.length">
											<td colspan="5" class="py-4 text-center text-neutral-400">
												No reservations yet..
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</template>

						<template
							v-if="
								people[previewPersonId].roles.filter(r => !['CITIZEN', 'BOT'].includes(r.role)).length
							"
						>
							<h6 class="mb-1">Departments &amp; Roles</h6>

							<ul class="nicer-list mb-4">
								<li
									v-for="role in people[previewPersonId].roles.filter(
										r => !['CITIZEN', 'BOT'].includes(r.role)
									)"
									:key="`person-${previewPersonId}-preview-${role.role}-${role.department_id}`"
								>
									<template v-if="departments && departments.length">{{
										departments.find(d => d.id === role.department_id).name
									}}</template>
									<code v-else>[{{ role.department_id }}]</code>

									<span v-if="roles[role.role]" class="badge ms-1" :class="[roles[role.role].class]"
										>{{ roles[role.role].name }}
									</span>
								</li>
							</ul>
						</template>

						<div v-if="isStaff" class="bg-danger-50 p-3 rounded-1">
							<h6 class="mb-3"><span class="me-2">👁️</span> Only for HeyGov admins</h6>

							<p class="mb-2">
								Internal ID: <code>{{ previewPersonId }}</code>
							</p>
							<p class="mb-2">
								Registered on: <strong>{{ people[previewPersonId].created_at | dateLocal }}</strong>
								<small class="text-muted ms-2">{{
									people[previewPersonId].created_at | dateToNow
								}}</small>
							</p>
							<p class="mb-2">
								Last login: <strong>{{ people[previewPersonId].last_login_at | dateLocal }}</strong>
								<small class="text-muted ms-2">{{
									people[previewPersonId].last_login_at | dateToNow
								}}</small>
							</p>
							<p class="mb-2">
								Used phone app:
								<strong>{{ people[previewPersonId].used_app ? '✅' : '❌' }}</strong>
							</p>
							<p>
								<a
									:href="
										`https://app.openreplay.com/3468/sessions?uid=is|${people[previewPersonId].email}`
									"
									target="_blank"
									><small>Sessions in OpenReplay</small></a
								>
								&middot;
								<a
									:href="
										`https://account.postmarkapp.com/servers/9064802/streams/outbound/addresses/${people[previewPersonId].email}`
									"
									target="_blank"
									><small>Emails in Postmark</small></a
								>
							</p>

							<div class="d-grid">
								<router-link
									:to="`/heygov-admin/people/${previewPersonId}`"
									class="btn btn-sm btn-outline-danger"
									>See account in ADMIN</router-link
								>
							</div>
						</div>
					</template>
				</div>
				<div v-else class="text-center text-muted">
					loading..
				</div>
			</div>
		</div>

		<ai-assistant ref="ai-assistant"></ai-assistant>
	</div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@font-face {
	font-family: 'Agrandir';
	src: url('/fonts/agrandir-regular-webfont.woff2') format('woff2'),
		url('/fonts/agrandir-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Agrandir';
	src: url('/fonts/agrandir-textbold-webfont.woff2') format('woff2'),
		url('/fonts/agrandir-textbold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@import '@/assets/design.scss';

#app.is-embed {
	background-color: white;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 20px;
}

.card h4 {
	font-size: 24px;
}

.col-sidebar {
	width: 225px;
	transition: width 0.1s ease-in-out;
}

.col-content {
	min-height: 100vh;
	width: calc(100vw - 225px - 2rem);
	transition: width 0.1s ease-in-out;
}

.is-sidebar-mini {
	.col-sidebar {
		width: 96px;
	}

	.col-content {
		width: calc(100vw - 96px - 2rem);
	}
}

.header-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(56px + 1.5rem);
	background-color: rgba(236, 242, 255, 0.8);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 50;
}
body:has(.embedded) {
	background-color: white;
	.header-overlay {
		display: none;
	}
}

.app-ios .header-overlay {
	height: calc(56px + 6rem);
}

.heygov-big-button {
	.overlay {
		position: fixed;
		top: 98vh;
		left: calc(100vw - 3rem);
		bottom: 3.5rem;
		right: 3rem;
		background: #000;
		z-index: 100;
		border-radius: 50%;
		opacity: 0;
		transition: all 0.3s ease-out;
	}

	.heygov-big-nav {
		position: fixed;
		bottom: 0rem;
		right: 0rem;
		z-index: 200;

		.nav-link {
			color: #fff;
		}

		button {
			width: 110px;
			height: 80px;
			background: url('/images/icon-bubble.png') center center no-repeat;
			background-size: cover;
			border: 0;
			color: transparent !important;
		}
	}

	&.menu-open {
		.overlay {
			top: -5rem;
			left: -5rem;
			right: -5rem;
			bottom: -5rem;
			border-radius: 10%;
			opacity: 0.8;
		}
	}
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import 'bootstrap'

import Navbar from '@/components/Navbar.vue'
import MobileNav from '@/components/MobileNav.vue'
import Sidebar from '@/components/Sidebar.vue'
import PersonAvatar from './components/PersonAvatar.vue'
import FormRequestStatus from './components/forms/FormRequestStatus.vue'
import PaymentMethod from './components/payments/PaymentMethod.vue'
import PaymentStatus from './components/payments/PaymentStatus.vue'
import VenueBookingStatus from './components/venues/VenueBookingStatus.vue'
import AiAssistant from './components/AiAssistant.vue'

import { copyToClipboard, formatAddress, identify } from './utils.js'
import { hgApi } from './api.js'

export default {
	name: 'App',
	components: {
		Navbar,
		Sidebar,
		MobileNav,
		FormRequestStatus,
		PersonAvatar,
		PaymentMethod,
		PaymentStatus,
		VenueBookingStatus,
		AiAssistant,
	},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'HeyGov',
		// all titles will be injected into this template
		titleTemplate: '%s | HeyGov',
	},
	data() {
		return {
			bigMenu: false,
			swipeStartX: null,
			swipeStartY: null,
			swipeMoveX: null,
			swipeMoveY: null,
			swipeTarget: null,
			$sidebar: null,

			personPreviewDataState: 'loading',
			personPreviewDataError: '',
			personPreviewData: {
				payments: [],
				formSubmissions: [],
				venueReservations: [],
			},
		}
	},
	computed: {
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		...mapState([
			'env',
			'j',
			'forms',
			'roles',
			'departments',
			'menuOpen',
			'sidebarMini',
			'account',
			'onboarded',
			'device',
			'previewPersonId',
			'people',
			'isMobile',
			'venues',
		]),
		shouldShowSidebar() {
			return this.$route.meta.sidebar !== false
		},
		isEmbed() {
			return this.$route.meta.embed || this.$route.query._embed == 1
		},
	},
	created() {
		if (document.referrer) {
			this.$store.commit('setReferrer', document.referrer)
		}
		this.$store.commit('deviceInfo')

		if (this.env !== 'development') {
			if (this.auth) {
				// identify current user
				identify(this.auth)
			}

			// start OpenReplay, if not HG Staff and not in iframe
			// disable OpenReplay for the moment
			/* if (!this.isStaff && !window.frameElement && 0) {
				tracker.start()

				if (this.j) {
					tracker.setMetadata('jurisdiction', this.j.slug)
				}
			} */
		}
	},
	mounted() {
		this.$sidebar = document.querySelector('.app-sidebar')

		document.getElementById('person-preview').addEventListener('show.bs.offcanvas', () => {
			this.loadPersonPreviewData()
		})

		// Check if pointer events are supported.
		if (window.PointerEvent) {
			// Add Pointer Event Listener
			document.body.addEventListener('pointerdown', this.handleGestureStart, true)
			document.body.addEventListener('pointermove', this.handleGestureMove, true)
			document.body.addEventListener('pointerup', this.handleGestureEnd, true)
			document.body.addEventListener('pointercancel', this.handleGestureEnd, true)
		} else {
			// Add Touch Listener
			//document.body.addEventListener('touchstart', this.handleGestureStart, true);
			//document.body.addEventListener('touchmove', this.handleGestureMove, true);
			//document.body.addEventListener('touchend', this.handleGestureEnd, true);
			//document.body.addEventListener('touchcancel', this.handleGestureEnd, true);
			// Add Mouse Listener
			//document.body.addEventListener('mousedown', this.handleGestureStart, true);
		}
	},
	methods: {
		copyToClipboard,
		formatAddress,

		handleGestureStart($event) {
			this.swipeStartX = $event.clientX
			this.swipeStartY = $event.clientY

			// if swipe is started on sidebar
			//console.log($event.target)
			if (this.$sidebar === $event.target) {
				this.swipeTarget = 'sidebar'
			}
		},
		handleGestureMove($event) {
			if (this.swipeStartX !== null) {
				this.swipeMoveX = $event.clientX
				this.swipeMoveY = $event.clientY

				if (this.swipeTarget === 'sidebar') {
					$event.stopPropagation()
					this.$sidebar.style.left = `calc(75vw * -1 + ${Math.min($event.clientX, 280)}px)`
				}
			}
		},
		handleGestureEnd($event) {
			const xDelta = ($event.clientX || this.swipeMoveX) - this.swipeStartX
			//const yDelta = ($event.clientY || this.swipeMoveY) - this.swipeStartY
			//console.log('deltas end', xDelta, yDelta)
			//console.log('pointer end', $event.clientX, $event.clientY)

			if (this.swipeStartX && xDelta > 50) {
				// swipe right
				//console.log('swipe right from left')

				if (!this.$route.meta.backTo) {
					// open or close menu based on current opening length
					this.$sidebar.style.left = null
					this.$store.commit('toggleMenu', ($event.clientX || this.swipeMoveX) > 100)
				} else if (this.swipeStartX && this.swipeStartX < 15) {
					// if swipe from far left AND page has back button
					if (window.history.length) {
						this.$router.back()
					} else {
						this.$router.push('/')
					}
				}
			} else if (this.swipeStartX && xDelta < 50) {
				// swipe left
				//console.log('swipe left')

				if (this.swipeTarget === 'sidebar') {
					this.$sidebar.style.left = null
					this.$store.commit('toggleMenu', ($event.clientX || this.swipeMoveX) > 140)
				}
			}

			this.swipeStartX = null
			this.swipeStartY = null
			this.swipeMoveX = null
			this.swipeMoveY = null
			this.swipeTarget = null
		},

		async loadPersonPreviewData() {
			this.personPreviewDataState = 'loading'
			this.personPreviewDataError = ''
			this.personPreviewData.payments = []
			this.personPreviewData.formSubmissions = []
			this.personPreviewData.venueReservations = []

			this.$store.dispatch('loadDepartments')
			this.$store.dispatch('getForms')
			this.$store.dispatch('getVenues')

			try {
				const responses = await Promise.all([
					hgApi(`${this.j.slug}/payments?person_id=${this.previewPersonId}&limit=5`),
					hgApi(
						`${this.j.slug}/form-requests?person_id=${this.previewPersonId}&limit=5&status=approved,declined,expired,revoked,sent,working`
					),
					hgApi(
						`${this.j.slug}/venue-bookings?person_id=${this.previewPersonId}&limit=5&status=approved,canceled,completed,declined,ended,pending,progress`
					),
				])

				if (responses[0].ok) {
					this.personPreviewData.payments = await responses[0].json()
				}

				if (responses[1].ok) {
					this.personPreviewData.formSubmissions = await responses[1].json()
				}

				if (responses[2].ok) {
					this.personPreviewData.venueReservations = await responses[2].json()
				}

				this.personPreviewDataState = 'loaded'
			} catch (error) {
				this.personPreviewDataError = error.message
				this.personPreviewDataState = 'error'
			}
		},
		sendMessageToAiAssistant(msg) {
			this.$refs['ai-assistant'].assistantSend(msg)
		},
	},
}
</script>
