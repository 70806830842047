export function buildAssistantSystemPrompt(jurisdiction, account, currentRole) {
	const now = new Date()

	return `You're the HeyGov Assistant, that can help users using the HeyGov platform. HeyGov app/platform/dashboard is a municipal software that helps clerks help their residents with:
- Payments
- forms (licenses and permits)
- venue bookings
- news and notices
- events and meetings

Current user:
- Name: ${account?.name || '-'}
- Email: ${account?.email || '-'}
- Role: ${currentRole}

Current municipality:
- Name: ${jurisdiction.name}
- Location: ${JSON.stringify(jurisdiction.location)}
- Population: ${jurisdiction.population}
- Date: ${now.toLocaleDateString('en-US')}

App pages (path in quotes):
- "/payments" - View payments & receipts, make refunds, send payment requests, view billing accounts
- "/forms" - View existing form, forms overview, create forms (permits, licenses, applications)
- "/form-requests" - View form requests and review: approve, issue, decline, revoke
- "/meetings" - Sometimes named 'Meeting repo', view meetings, upload agenda/recording/minutes for meetings
- "/events" - View & edit events. Events are public and can be added to the website
- "/news-notices" - View & edit News/Notices posts for the website
- "/emergency-alerts" - View & edit Emergency Alerts posts for the website
- "/people" - CRM with all residents using the platform, view their payments, forms, venue bookings
- "/threads" - View & manage 311 requests
`
}

export function buildAiTools() {
	return [
		{
			type: 'function',
			function: {
				name: 'navigateToPage',
				description: 'Navigate to a specific page in the app',
				parameters: {
					type: 'object',
					properties: {
						path: {
							type: 'string',
							description: 'The path of the page to navigate to',
						},
					},
					required: ['path'],
				},
			},
		},
		{
			type: 'function',
			function: {
				name: 'createMeeting',
				description: 'Navigate to "Create meeting" page and optionally pre-fill some fields',
				parameters: {
					type: 'object',
					properties: {
						name: {
							type: 'string',
							description: 'Name of the meeting',
						},
						date: {
							type: 'string',
							format: 'date',
							description: 'Date of the meeting',
						},
						time: {
							type: 'string',
							format: 'time',
							description: 'Time of the meeting',
						},
					},
				},
			},
		},
		{
			type: 'function',
			function: {
				name: 'createEvent',
				description:
					'Navigate to "Create event" page and optionally pre-fill some fields. Subsequent calls to this will overwrite the previous values',
				parameters: {
					type: 'object',
					properties: {
						name: {
							type: 'string',
							description: 'Name of the event',
						},
						description: {
							type: 'string',
							description: 'Description/details of the event',
						},
						starts_at: {
							type: 'string',
							format: 'date-time',
							description: 'Start date & time of the event',
						},
						ends_at: {
							type: 'string',
							format: 'date-time',
							description: 'Event end date & time, if known',
						},
					},
				},
			},
		},
		{
			type: 'function',
			function: {
				name: 'createNewsNotice',
				description: 'Open the "Create News or Notice" screen and start creating a post',
				parameters: {
					type: 'object',
					properties: {
						subject: {
							type: 'string',
							description: 'Subject/topic of the post',
						},
					},
					required: ['subject'],
				},
			},
		},
	]
}

export async function getAssistantCompletion(jurisdiction, account, currentRole, messages) {
	const response = await fetch(
		'https://gateway.ai.cloudflare.com/v1/913b7bb9d16f3e46e83fcf6c05acc233/heygov-assistant/openai/v1/chat/completions',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
			},
			body: JSON.stringify({
				model: 'gpt-4o',
				messages: [
					{ role: 'system', content: buildAssistantSystemPrompt(jurisdiction, account, currentRole) },
					...messages,
				],
				tools: buildAiTools(jurisdiction),
				tool_choice: 'auto',
			}),
		}
	)

	const data = await response.json()

	return data.choices[0]
}
