import { render, staticRenderFns } from "./AddAgendaItemForm.vue?vue&type=template&id=6caab8d4&"
import script from "./AddAgendaItemForm.vue?vue&type=script&lang=js&"
export * from "./AddAgendaItemForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports