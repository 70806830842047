<template>
	<draggable
		:value="nested"
		@change="handleItemsChange"
		:group="{ name: 'g1' }"
		handle=".drag-handle"
		animation="200"
		tag="div"
		class="item-container"
	>
		<div v-for="(item, index) in nested" :key="item.id" class="item-group border mb-3 rounded-1">
			<font-awesome-icon :icon="['fas', 'grip-vertical']" class="drag-handle text-neutral-300" />

			<div class="item p-3 on-parent" :class="{ 'pb-0': !item.parent_id }">
				<div class="row gx-2">
					<div class="col">
						<strong v-if="parent_id === 0" class="text-primary-300 me-1"
							>{{ getItemNumber(index) }}.</strong
						>
						<strong v-else class="text-warning-300 me-1">{{ getItemNumber(index) }})</strong>
						<strong>{{ item.title }}</strong>
						<span
							v-if="item.status && item.status !== 'ok'"
							class="badge ms-2"
							:class="{
								'bg-primary-50 text-primary-400': item.status === 'needs_review',
								'bg-warning-50 text-warning-400': item.status === 'change_requested',
							}"
						>
							{{ item.status.replace('_', ' ') }}
						</span>
					</div>
					<div class="col-auto">
						<div class="dropdown show-on-hover">
							<button
								class="btn btn-sm btn-icon-primary"
								type="button"
								id="dropdownMenuButton"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<li>
									<button class="dropdown-item" @click="editItem(item)">
										<font-awesome-icon :icon="['fas', 'pencil']" class="fa-fw" /> Edit
									</button>
								</li>
								<li v-if="allowApprove && item.status === 'needs_review'">
									<button class="dropdown-item" @click="approveItem(item)">
										<font-awesome-icon :icon="['fas', 'check']" class="fa-fw" /> Approve
									</button>
								</li>
								<li v-if="requestChangesStatus !== 'hidden'">
									<button
										:disabled="requestChangesStatus === 'disabled'"
										class="dropdown-item"
										@click="requestChanges(item)"
									>
										<font-awesome-icon :icon="['fas', 'exclamation-circle']" class="fa-fw" />
										Request Changes
									</button>
								</li>
								<!-- "Move to queue" feature is not implemented yet, button is hidden so it doesn't confuse users
								<li>
									<button class="dropdown-item" @click="alert('Not implemented yet')">
										<font-awesome-icon :icon="['fas', 'exchange-alt']" class="fa-fw" /> Move to queue
									</button>
								</li>
								-->
								<li>
									<button class="dropdown-item" @click="moveToAnotherMeeting(item)">
										<font-awesome-icon :icon="['fas', 'arrow-right']" class="fa-fw" /> Move to
										another meeting
									</button>
								</li>
								<li><hr class="dropdown-divider" /></li>
								<li>
									<button class="dropdown-item text-danger-400" @click="removeItem(item)">
										<font-awesome-icon :icon="['fas', 'trash']" class="fa-fw" /> Delete
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<p v-if="item.text" class="text-neutral-400">
					{{ item.text }}
				</p>

				<div class="row gy-2 gx-2">
					<div v-if="item.internal_note" class="col-auto">
						<span
							class="d-inline-block px-2 py-1 border rounded-1 bg-warning-50 text-dark"
							:title="item.internal_note"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
						>
							<div class="d-flex align-items-center">
								<font-awesome-icon :icon="['fas', 'note-sticky']" class="text-warning-300 me-2" />
								Note
							</div>
						</span>
					</div>

					<div v-if="item.speaker_id" class="col-auto">
						<span class="d-inline-block px-2 py-1 border rounded-1 bg-neutral-50">
							<PersonLink :id="item.speaker_id" :avatar="24" :preview="true" />
						</span>
					</div>

					<div v-for="file in item.files" :key="file" class="col-auto">
						<a
							:href="getPublicFileUrl(file)"
							target="_blank"
							class="d-inline-block px-2 py-1 border rounded-1 bg-neutral-50 text-dark"
						>
							<div class="d-flex align-items-center">
								<font-awesome-icon
									:icon="['fas', file.endsWith('pdf') ? 'file-pdf' : 'file']"
									class="text-primary-100 me-2"
								/>
								<span class="d-inline-block text-truncate" style="max-width: 150px;">
									{{ file.split('/').at(-1) }}
								</span>
							</div>
						</a>
					</div>
				</div>
			</div>

			<!-- only parent items list renders this -->
			<agenda-items-list
				v-if="parent_id === 0"
				:items="item.items"
				:parent_id="item.id"
				:settings="settings"
				:requestChangesStatus="requestChangesStatus"
				:allowApprove="allowApprove"
				@updateOrder="handleSubItemsChange"
				@removeItem="removeItem"
				@editItem="editItem"
				@requestChanges="requestChanges"
				@approve="approveItem"
				@moveToAnotherMeeting="moveToAnotherMeeting"
				@addSubItem="addSubItem"
				:allowAddSubItem="allowAddSubItem && !isBaseItem(item.title)"
				class="items-sub p-3 rounded-1"
			/>
		</div>

		<!-- only sub items list renders this -->
		<div v-if="allowAddSubItem && parent_id !== 0" class="ps-3 add-sub-item">
			<!-- it propagates its parent id up to its parent items list -->
			<!-- it's important to propagate data this way or we loose track of hierarchy -->
			<span role="button" class="text-primary text-decoration-none cursor-pointer" @click="addSubItem(parent_id)">
				<font-awesome-icon :icon="['fas', 'circle-plus']" class="me-1" />
				Add sub-item
			</span>
		</div>
	</draggable>
</template>

<script>
import draggable from 'vuedraggable'
import { Tooltip } from 'bootstrap'

import { getPublicFileUrl } from '@/utils.js'
import PersonLink from '@/components/PersonLink.vue'

// it's crucial to have skip in the global scope
// because we need to share this state between all nested instances
// when we move sub-items between parent items
let skip = false

export default {
	name: 'agenda-items-list',
	components: {
		draggable,
		PersonLink,
	},
	props: {
		items: {
			required: true,
			type: Array,
		},
		parent_id: {
			type: Number,
			required: true,
		},
		allowApprove: {
			type: Boolean,
			required: true,
		},
		requestChangesStatus: {
			type: String,
			required: true,
			validator: value => ['show', 'disabled', 'hidden'].includes(value),
		},
		settings: {
			type: Object,
			default: () => ({
				template_items_numbering_style: 'numeric',
				template_subitems_numbering_style: 'numeric',
			}),
		},
		allowAddSubItem: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		nested() {
			return this.nest(this.items)
		},
	},
	mounted() {
		document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
			new Tooltip(el)
		})
	},
	methods: {
		getPublicFileUrl,

		/**
		 * handleParentItemsChange is called when parent or sub-item is added, moved or removed.
		 * @param {Object} params - Input parameters
		 * @param {Object} [params.added] - Contains information about an added element
		 * @param {number} params.added.newIndex - The index where the element was added
		 * @param {Object} params.added.element - The element that was added
		 * @param {Object} [params.removed] - Contains information about a removed element
		 * @param {number} params.removed.oldIndex - The index where the element was before removal
		 * @param {Object} params.removed.element - The element that was removed
		 * @param {Object} [params.moved] - Contains information about a moved element
		 * @param {number} params.moved.newIndex - The current index of the moved element
		 * @param {number} params.moved.oldIndex - The previous index of the moved element
		 * @param {Object} params.moved.element - The element that was moved
		 */
		handleItemsChange({ added, removed, moved }) {
			// skip could be set to `true` after we handle sub-items change
			// in that case, everything was handled at that step, and if we don't skip now,
			// we will overwrite those changes with our stale state
			if (skip) {
				skip = false
				return
			}

			let newNested = [...this.nested]
			if (added) {
				newNested.splice(added.newIndex, 0, added.element)
			} else if (removed) {
				newNested.splice(removed.oldIndex, 1)
			} else if (moved) {
				const [movedItem] = newNested.splice(moved.oldIndex, 1)
				newNested.splice(moved.newIndex, 0, movedItem)
			}

			// for debugging:
			console.log('handleChange', { old: this.nested, new: newNested }, this.parent_id)

			// if item was added to root list, check if it exists in sub-items of some parent
			// and remove it from there to prevent duplicates
			if (added) {
				const sourceParentItem = this.nested.find(item =>
					item.items?.some(subItem => subItem.id === added.element.id)
				)
				if (sourceParentItem) {
					// we need to skip ongoing update in sub-items because this.nested
					// wasn't yet recomputed, so it will overwrite root list without added element
					// handle everything right now instead
					skip = true // this will prevent handleItemsChange in sub-items from emitting `updateOrder`
					newNested = newNested.map(item => {
						if (item.id === sourceParentItem.id) {
							return {
								...item,
								items: item.items.filter(subItem => subItem.id !== added.element.id),
							}
						}
						return item
					})
				}
			}

			this.$emit('updateOrder', this.flatten(newNested), this.parent_id, {
				added,
				removed,
				moved,
			})
		},

		// this method is called when sub-items emits `updateOrder` from its `handleParentItemsChange`
		handleSubItemsChange(newSubItems, parentItemId, { added, moved, removed }) {
			console.log('handleSubItemsChange', { added, moved, removed }, this.parent_id)

			// if parent item was moved into sub-items (of another parent)
			// we need to remove that parent item from the root list
			let newNested = this.nested
			const parentMovedToSubItems = added && this.nested.find(item => item.id === added.element.id) !== undefined
			if (parentMovedToSubItems) {
				newNested = newNested.filter(item => item.id !== added.element.id)
				// since parent was removed form root list
				// vue-draggable will call `handleItemsChange` after this function returns
				// it will handle removing parent item
				// but will replace every other parent item with its (stale) state,
				// that doesn't have latest sub-items updates (we handle them here)
				// so the solution is to prevent `handleItemsChange` from doing anything,
				skip = true
				// and emit event with ready-to-use state to `MeetingAgenda` from here
			}

			// if item was moved from sub-items of another parent, remove it from there
			if (added) {
				const sourceParentItem = newNested.find(
					item => item.id !== parentItemId && item.items?.some(subItem => subItem.id === added.element.id) // not the target parent // has the moved item
				)

				if (sourceParentItem) {
					newNested = newNested.map(item => {
						if (item.id === sourceParentItem.id) {
							return {
								...item,
								items: item.items.filter(subItem => subItem.id !== added.element.id),
							}
						}
						return item
					})
				}

				skip = true
			}

			// in all cases sub-items were updated, so we need to emit that information too
			newNested = newNested.map(item => (item.id === parentItemId ? { ...item, items: newSubItems } : item))

			// finally emit
			this.$emit('updateOrder', this.flatten(newNested), this.parent_id)

			return
		},

		nest(items) {
			const parentItems = items
				.filter(item => item.parent_id === this.parent_id)
				.sort((a, b) => a.order - b.order)

			return parentItems.map(parentItem => ({
				...parentItem,
				items: items
					.filter(subItem => subItem.parent_id === parentItem.id)
					.sort((a, b) => a.order - b.order)
					.map(subItem => ({
						...subItem,
						items: [],
					})),
			}))
		},

		flatten(nested) {
			const flat = []

			nested.forEach((parentItem, parentIndex) => {
				flat.push({
					...parentItem,
					order: parentIndex,
					parent_id: 0,
					items: undefined,
				})
				if (parentItem.items && parentItem.items.length > 0) {
					parentItem.items.forEach((subItem, subIndex) => {
						flat.push({
							...subItem,
							order: subIndex,
							parent_id: parentItem.id,
							items: undefined,
						})
					})
				}
			})

			return flat
		},

		editItem(item) {
			this.$emit('editItem', item)
		},

		removeItem(item) {
			this.$emit('removeItem', item)
		},

		requestChanges(item) {
			this.$emit('requestChanges', item)
		},

		alert(text) {
			window.alert(text)
		},

		approveItem(item) {
			this.$emit('approve', item)
		},

		getItemNumber(index) {
			if (this.parent_id === 0) {
				return this.getMainItemNumber(index)
			}
			return this.getSubItemNumber(index)
		},

		getMainItemNumber(index) {
			const num = index + 1
			switch (this.settings.template_items_numbering_style) {
				case 'alphabetic':
					return String.fromCharCode(64 + num) // A, B, C...
				case 'roman':
					return this.toRoman(num) // I, II, III...
				case 'numeric':
				default:
					return num // 1, 2, 3...
			}
		},

		getSubItemNumber(index) {
			const num = index + 1
			switch (this.settings.template_subitems_numbering_style) {
				case 'alphabetic':
					return String.fromCharCode(96 + num) // a, b, c...
				case 'roman':
					return this.toRoman(num).toLowerCase() // i, ii, iii...
				case 'numeric':
				default:
					return num
			}
		},

		toRoman(num) {
			const roman = {
				M: 1000,
				CM: 900,
				D: 500,
				CD: 400,
				C: 100,
				XC: 90,
				L: 50,
				XL: 40,
				X: 10,
				IX: 9,
				V: 5,
				IV: 4,
				I: 1,
			}
			let str = ''
			for (let i of Object.keys(roman)) {
				let q = Math.floor(num / roman[i])
				num -= q * roman[i]
				str += i.repeat(q)
			}
			return str
		},

		moveToAnotherMeeting(item) {
			this.$emit('moveToAnotherMeeting', item)
		},

		addSubItem(parentId) {
			this.$emit('addSubItem', parentId)
		},

		isBaseItem(itemTitle) {
			const baseTitles = ['roll call', 'allegiance', 'adjourn', 'call to order']
			const result = baseTitles.includes(itemTitle.toLowerCase())
			return result
		},
	},
	emits: ['updateOrder', 'removeItem', 'editItem', 'requestChanges', 'approve', 'moveToAnotherMeeting', 'addSubItem'],
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';

.item-container {
	margin: 0;
}

.item-group {
	position: relative;
	transition: background-color 0.1s ease-in-out;

	.drag-handle {
		position: absolute;
		cursor: grab;
		top: 15px;
		left: -20px;
		padding: 4px 3px;
		border-radius: 3px;
		opacity: 0;
		transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	.item {
		border-radius: 0.125rem;
	}

	&:hover {
		background-color: #f5f5f5;

		.drag-handle {
			opacity: 1;
		}
	}
}

.items-sub {
	margin-left: 1.25rem;
	border-radius: 0.125rem;
}

.items-sub .item-group {
	background-color: #f9f9f9;

	&:hover {
		background-color: #e4f0ff;
	}
}

.sortable-ghost {
	border-color: $primary-300 !important;
}

.sortable-chosen:not(.sortable-ghost) {
	border-color: #bbb !important;
}

.sortable-drag {
	border-color: yellow !important;
}

.add-sub-item {
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.item-group:hover .add-sub-item {
	opacity: 1;
}
</style>
