<template>
	<div class="page-meetings-try">
		<div class="row">
			<div class="col-lg-4">
				<div class="position-sticky">
					<label
						for="meeting-agenda-file"
						class="d-block file-drop rounded-1 bg-light border p-3 cursor-pointer mb-4"
						:class="{
							'border-danger': states.agenda === 'error',
							'border-success-': states.agenda === 'uploaded',
							'border-dashed': states.agenda !== 'uploaded',
						}"
						@dragover="dragover"
						@dragleave="dragleave"
						@drop="dropMeetingFile"
					>
						<h3 class="mb-4">
							<font-awesome-icon :icon="['fas', 'file-pdf']" class="text-danger-400 me-1" /> Meeting
							agenda
							<span v-if="states.agenda === 'uploaded'" class="ms-2">✅</span>
						</h3>

						<p v-if="states.agenda === 'uploading'" class="mb-0 text-center text-warning-500">
							Uploading <span class="spinner-border spinner-border-sm"></span>
						</p>
						<p v-else-if="states.agenda === 'uploaded'" class="mb-0 text-warning-500">
							Agenda for <strong>{{ meeting.title }}</strong> is ready,
							<strong>{{ meeting.agenda_items.length }} items</strong> detected
						</p>
						<p v-else-if="states.agenda === 'error'" class="mb-0 text-danger-400">
							{{ agendaUploadError }}
						</p>
						<div v-else class="text-center text-neutral-500">
							<p class="mb-1">Drop PDF or DOCX agenda here</p>
							<p class="mb-1 text-neutral-300">- or -</p>
							<span class="btn btn-sm btn-primary" role="button">Upload agenda</span>
						</div>

						<input type="file" id="meeting-agenda-file" class="d-none" @change="handleMeetingAgendaFile" />
					</label>

					<label
						for="meeting-recording-file"
						class="d-block file-drop rounded-1 bg-light border p-3 cursor-pointer mb-4"
						:class="{
							'border-danger': recordingFileError,
							'border-success-': meeting && meeting.transcript_job_status === 'transcribed',
							'border-dashed': !meeting || meeting.transcript_job_status !== 'transcribed',
						}"
						@dragover="dragover"
						@dragleave="dragleave"
						@drop="dropMeetingFile"
					>
						<h3 class="mb-3">
							<font-awesome-icon :icon="['fas', 'video']" class="text-primary-400 me-1" /> Meeting
							Recording
							<span v-if="meeting && meeting.transcript_job_status === 'transcribed'">✅</span>
						</h3>

						<input
							type="file"
							id="meeting-recording-file"
							class="d-none"
							@change="handleMeetingAudioVideo"
							accept="audio/*,video/*"
						/>

						<p v-if="meeting && meeting.transcript_job_status === 'error'" class="text-danger-400">
							<small>⚠️ Error transcribing recording. Please try again</small>
						</p>

						<p v-if="!meeting && recordingFile" class="mb-0">
							Waiting for agenda to be uploaded..
						</p>
						<p
							v-else-if="meeting && meeting.transcript_job_status === 'uploading'"
							class="my-3 text-center"
						>
							Uploading <strong>{{ (states.meeting_audio_video_progress * 100).toFixed(1) }}%</strong>
						</p>
						<p v-else-if="meeting && meeting.transcript_job_status === 'started'" class="mb-0">
							Processing <span class="spinner-border spinner-border-sm"></span>
							<small class="text-neutral-300 ms-2">(max 1 minute)</small>
						</p>
						<p v-else-if="meeting && meeting.transcript_job_status === 'transcribed'" class="mb-0">
							Transcript is ready
						</p>
						<p v-else-if="recordingFileError" class="mb-0 text-danger-400">{{ recordingFileError }}</p>
						<div v-else class="text-center text-neutral-500">
							<p class="mb-1">Drop audio/video here</p>
							<p class="mb-1 text-neutral-300">- or -</p>
							<span class="btn btn-sm btn-primary" role="button">Upload audio or video file</span>
						</div>
					</label>
				</div>
			</div>

			<div class="col">
				<div class="bg-ai border border-dashed rounded-1 px-3 py-4 mb-4">
					<h5 class="text-center mb-3">Generate meeting minutes</h5>

					<p class="text-center">
						<label>Length:</label>
						<select
							class="d-inline-block form-select form-select-sm mx-2"
							style="max-width: 200px"
							v-model="aiOptions.length"
						>
							<option value="short-length">Short - Action Minutes</option>
							<option value="medium-length">Medium</option>
							<option value="comprehensive">Long and detailed</option>
						</select>

						<label>Tone:</label>
						<select
							class="d-inline-block form-select form-select-sm mx-2"
							style="max-width: 200px"
							v-model="aiOptions.tone"
						>
							<option value="official-meeting-speak">Official meeting</option>
							<option value="formal">Make it sound more formal</option>
							<option value="professional-corporate">Professional</option>
							<option value="overly-energetic-and-enthusiastic">Enthusiastic</option>
							<option value="casual">Make it sound casual</option>
							<option value="in-southern-belle-speaking-with-a-southern-drawl"
								>Make it sound like a Southerner</option
							>
							<option value="like-spoken-by-a-true-texan">Make it sound like spoken by a Texan</option>
							<option value="over-the-top-like-a-wisconsinite">Make it sound like a Wisconsinite</option>
						</select>
					</p>

					<p class="text-center mb-0">
						<button
							class="btn btn-primary px-5"
							@click="generateDraftMinutes"
							:disabled="
								!meeting ||
									meeting.minutes_status == 'generating' ||
									meeting.agenda_items.length < 3 ||
									meeting.transcript_job_status !== 'transcribed'
							"
						>
							✨ Generate draft minutes
						</button>
					</p>
				</div>

				<div
					v-if="meeting && ['generating', 'done'].includes(meeting.minutes_status)"
					ref="meetingMinutes"
					class="position-relative bg-light border rounded overflow-hidden p-3"
					style="min-height: 750px; max-height: 1300px;"
				>
					<h2 class="card-title text-center mt-4 mb-2">{{ meeting.title }}</h2>
					<h3 class="card-title text-center mb-5">Meeting minutes</h3>

					<div v-html="meeting.minutes_text || '<i>Watch the minutes being written..</i>'"></div>

					<div
						class="position-absolute rounded-bottom border-top bg-ai p-3"
						style="left: 0; right: 0; bottom: 0"
					>
						<div v-if="states.form === 'success'" class="text-center">
							<div v-if="states.clerkMinutesStatus === 'clerkminutes-active'" class="px-4 text-center">
								<h3 class="mb-3">✅ Here's your ClerkMinutes account</h3>
								<p>
									Your ClerkMinutes is active and ready to use. 👉
									<a
										href="https://app.heygov.com/account/login?redirect=%2F%3Ajurisdiction%2Fmeetings&for=clerkminutes"
										target="_blank"
										>Click here to open ClerkMinutes</a
									>
									👈
								</p>
							</div>
							<div
								v-else-if="states.clerkMinutesStatus === 'clerkminutes-disabled'"
								class="px-4 text-center"
							>
								<h3 class="mb-3">ℹ️ Info about ClerkMinutes account</h3>
								<p>
									<strong>"{{ form.municipality }}"</strong> used ClerkMinutes previously, but doesn't
									have an active plan anymore.
									<a
										href="https://app.heygov.com/account/login?redirect=%2F%3Ajurisdiction%2Fmeetings&for=clerkminutes"
										target="_blank"
										>Click here to open ClerkMinutes and choose a plan</a
									>
								</p>
							</div>
							<h3 v-else class="my-2">✅ We'll send you the full meeting to your email soon!</h3>
						</div>

						<template v-else>
							<div class="px-4 text-center">
								<h3 class="mb-3">🙋 View and download full minutes</h3>
								<p>
									Do you want to view &amp; export the full minutes, check out the agenda builder, or
									view full transcript?
								</p>
							</div>

							<form @submit.prevent="sendReq" class="row justify-content-center">
								<div class="col-6 my-2">
									<div class="form-floating">
										<input
											type="text"
											class="form-control"
											v-model="form.name"
											placeholder="Your name"
											autocomplete="name"
											required
										/>
										<label class="form-label">Your name</label>
									</div>
								</div>
								<div class="col-6 my-2">
									<div class="form-floating">
										<input
											type="email"
											class="form-control"
											v-model="form.email"
											placeholder="Your email"
											autocomplete="email"
											required
										/>
										<label class="form-label">Your email</label>
									</div>
								</div>
								<div class="col-6 my-2">
									<div class="form-floating">
										<input
											type="search"
											class="form-control"
											v-model="form.municipality"
											placeholder="Municipality / Organization"
											required
										/>
										<label class="form-label">Municipality / Organization</label>
									</div>
								</div>
								<div class="col-6 my-2">
									<div class="form-floating">
										<input
											type="text"
											class="form-control"
											v-model="form.ref"
											placeholder="Who referred you?"
										/>
										<label class="form-label">Who referred you?</label>
									</div>
								</div>
								<div class="col-6 col-lg-4 text-center">
									<button class="btn btn-primary px-5">Continue →</button>
								</div>
							</form>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import { Converter } from 'showdown'

import heyGovApi, { hgApi, handleResponseError } from '@/api.js'
import { sendEvent, isHeyGovIdValid } from '@/utils.js'
import { validateEmail } from '@/lib/strings.js'

const apiKey = 'sk_01hf6jk4vb7a4yzajfpnsp0jrs'

export default {
	name: 'MeetingsTry',
	data() {
		return {
			states: {
				agenda: 'idle',
				meeting_audio_video_progress: 0,
				form: 'idle',
				clerkMinutesStatus: 'unknown',
			},
			meeting: null,

			allowedAgendaFileTyes: [
				'application/pdf', // pdf
				//'application/msword', // doc
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
				//'text/plain', // txt
			],

			agendaUploadError: '',

			recordingFile: null,
			recordingFileError: '',
			transcriptionStatusTimer: null,

			aiOptions: {
				length: 'comprehensive',
				tone: 'professional-corporate',
			},

			form: {
				municipality: '',
				name: '',
				email: '',
				ref: this.$route.query.ref || '',
			},
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		window.parent.postMessage('hg-ref-param', '*')

		setTimeout(() => {
			window.parent.postMessage('hg-ref-param', '*')
		}, 5000)

		window.onmessage = event => {
			if (typeof event.data === 'object' && 'ref' in event.data) {
				this.form.ref = event.data.ref
			}
		}

		if (this.$route.query.meeting && isHeyGovIdValid(this.$route.query.meeting)) {
			this.loadMeeting(this.$route.query.meeting)
		}
	},
	mounted() {
		sendEvent('meetings.view_try_page', {
			feature: 'Meetings',
			page: 'try_page',
		})

		//todo delete after 2024-03-01
		sendEvent('clerkminutes_try.view_page', {
			feature: 'ClerkMinutes',
		})

		//todo delete after 2024-03-01
		sendEvent('Try page - View page', {
			feature: 'ClerkMinutes',
		})
	},
	methods: {
		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},

		loadMeeting(meetingId) {
			hgApi(`${this.j.slug}/meetings/${meetingId}?expand=agenda_items&api=1`)
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(`${response.status} ${response.statusText}`)
					}
				})
				.then(meeting => {
					this.meeting = meeting

					if (meeting.agenda_file_path && meeting.agenda_items.length) {
						this.states.agenda = 'uploaded'
					}
				})
				.catch(error => {
					Vue.toasted.error(`Error loading meeting (${error.message})`)
				})
		},

		updateMeeting(fields) {
			hgApi(`${this.j.slug}/meetings/${this.meeting.id}`, {
				method: 'PUT',
				body: fields,
				query: { apiKey },
			})
				.then(response => {
					if (response.ok) {
						for (const key in fields) {
							Vue.set(this.meeting, key, fields[key])
						}
					}
				})
				.catch(error => {
					Vue.toasted.error(`Error updating meeting (${error.message})`)
					console.warn(this.meeting.pid, 'meeting.update', error)
				})
		},

		// handle file drop
		dropMeetingFile(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				event.dataTransfer.files.forEach(file => {
					if (this.allowedAgendaFileTyes.includes(file.type)) {
						this.uploadMeetingAgenda(file)
					} else if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
						this.uploadMeetingAudioVideo(file)
					} else {
						Vue.toasted.error(`File "${file.name}" is not a valid agenda (.pdf, .docx) or recording`, {
							position: 'top-right',
						})

						sendEvent('meetings.file_upload_error', {
							feature: 'Meetings',
							page: 'try_page',
							error: 'unsupported type',
							file: file.name,
							size: file.size,
							type: file.type,
						})
					}
				})
			} else {
				alert('No files dropped 🤷')
			}
		},

		// handle meeting agenda file
		handleMeetingAgendaFile($event) {
			this.uploadMeetingAgenda($event.target.files[0])
		},
		uploadMeetingAgenda(file) {
			if (file.size < 1) {
				sendEvent('meetings.agenda_upload_error', {
					feature: 'Meetings',
					error: 'size too small',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too small, is the file empty?')
			} else if (file.size / 1024 / 1024 > 30) {
				sendEvent('meetings.agenda_upload_error', {
					feature: 'Meetings',
					error: 'size too big',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too big (max 30MB)')
			} else if (!this.allowedAgendaFileTyes.includes(file.type)) {
				sendEvent('meetings.agenda_upload_error', {
					feature: 'Meetings',
					error: 'format not supported',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('Only PDF/DOCX agendas are accepted 🤷')
			} else {
				this.states.agenda = 'uploading'
				this.agendaUploadError = ''

				// prepare file data
				var form = new FormData()
				form.append('file', file)

				heyGovApi
					.post(`${this.j.slug}/meetings/create-from-agenda`, form, {
						params: { apiKey },
					})
					.then(
						({ data }) => {
							this.meeting = data
							this.states.agenda = 'uploaded'

							if (this.recordingFile) {
								this.reallyUploadMeetingAudioVideo(this.recordingFile)
							}

							//todo delete after 2024-03-01
							sendEvent('clerkminutes_try.file_upload_completed', {
								feature: 'ClerkMinutes',
								file_type: 'agenda',
							})

							sendEvent('meetings.agenda_upload_completed', {
								feature: 'Meetings',
								page: 'try_page',
								file: file.name,
								size: file.size,
								type: file.type,
							})
						},
						error => {
							this.agendaUploadError =
								error.response?.data?.message || error.response?.statusText || error.message
							this.states.agenda = 'error'

							sendEvent('meetings.agenda_processing_error', {
								feature: 'Meetings',
								file: file.name,
								size: file.size,
								type: file.type,
								error: this.agendaUploadError,
							})
						}
					)

				sendEvent('meetings.agenda_upload_start', {
					feature: 'Meetings',
					page: 'try_page',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				//todo delete after 2024-03-01
				sendEvent('clerkminutes_try.file_upload_start', {
					feature: 'ClerkMinutes',
					file_type: 'agenda',
				})
			}
		},

		// handle recording
		handleMeetingAudioVideo($event) {
			this.uploadMeetingAudioVideo($event.target.files[0])
		},
		uploadMeetingAudioVideo(file) {
			const isRecording = file.type.startsWith('audio/') || file.type.startsWith('video/')

			if (!isRecording) {
				alert('Only audio or video files are allowed 🤷')

				sendEvent('meetings.recording_upload_error', {
					feature: 'Meetings',
					error: 'format not supported',
					file: file.name,
					size: file.size,
					type: file.type,
				})
			} else if (file.size / 1024 / 1024 < 5) {
				sendEvent('meetings.recording_upload_error', {
					feature: 'Meetings',
					error: 'file size too small',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too small to be a meeting recording')
			} else if (file.size / 1024 / 1024 / 1024 > 5) {
				sendEvent('meetings.recording_upload_error', {
					feature: 'Meetings',
					error: 'file size too big',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too big. Please contact HeyGov if you need to process a big meeting')
			} else {
				if (this.meeting) {
					this.reallyUploadMeetingAudioVideo(file)
				} else {
					this.recordingFile = file
				}
			}
		},
		async reallyUploadMeetingAudioVideo(file) {
			this.recordingFileError = ''
			this.meeting.transcript_job_status = 'uploading'
			this.states.meeting_audio_video_progress = 0

			const uploadStartedAt = Date.now()

			//todo remove after 2024-03-01
			sendEvent('clerkminutes_try.file_upload_start', {
				feature: 'ClerkMinutes',
				file_type: 'recording',
			})

			sendEvent('meetings.recording_upload_start', {
				feature: 'Meetings',
				page: 'try_page',
				meeting_id: this.meeting.pid,
				file: file.name,
				size: file.size,
				type: file.type,
			})

			try {
				const uploadLinkResponse = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.id}/audio-video-link`,
					{
						name: file.name,
						size: file.size,
						type: file.type,
					}
				)

				await axios.put(uploadLinkResponse.data.uploadUrl, file, {
					headers: {
						'Content-Type': file.type,
					},
					onUploadProgress: p => {
						this.states.meeting_audio_video_progress = p.loaded / p.total
					},
				})

				this.recordingFile = null

				const fields = {
					transcript_job_status: 'started',
				}

				if (file.type.startsWith('audio/')) {
					fields.audio_file_path = uploadLinkResponse.data.path
				} else {
					fields.video_file_path = uploadLinkResponse.data.path
				}

				this.updateMeeting(fields)

				//todo delete after 2024-03-01
				sendEvent('clerkminutes_try.file_upload_completed', {
					feature: 'ClerkMinutes',
					file_type: 'recording',
				})

				sendEvent('meetings.recording_upload_completed', {
					feature: 'Meetings',
					file: file.name,
					size: file.size,
					type: file.type,
					duration: Math.round((Date.now() - uploadStartedAt) / 1000),
				})

				clearInterval(this.transcriptionStatusTimer)
				this.transcriptionStatusTimer = setInterval(() => {
					this.checkTranscriptJobStatus()
				}, 8000)
			} catch (error) {
				this.recordingFileError = error.message
				this.meeting.transcript_job_status = 'not-started'

				sendEvent('meetings.recording_upload_error', {
					feature: 'Meetings',
					page: 'try_page',
					error: error.message,
					file: file.name,
					size: file.size,
					type: file.type,
				})
			}
		},

		checkTranscriptJobStatus() {
			console.log(this.meeting.id, 'checking transribing job status')

			heyGovApi(`${this.j.slug}/meetings/${this.meeting.id}`)
				.then(({ data }) => {
					console.log(this.meeting.id, 'got status', data.transcript_job_status)

					if (data.transcript_job_status !== this.meeting.transcript_job_status) {
						this.meeting.transcript_job_status = data.transcript_job_status

						clearInterval(this.transcriptionStatusTimer)
					}
				})
				.catch(handleResponseError(`Error checking transcription status ({error})`))
		},

		generateDraftMinutes() {
			const previousStatus = this.meeting.minutes_status
			this.meeting.minutes_status = 'generating'
			this.meeting.minutes_text = ''
			this.minutesMarkdown = ''

			const converter = new Converter()
			converter.setOption('simplifiedAutoLink', true)
			converter.setOption('openLinksInNewWindow', true)

			hgApi(`${this.j.slug}/meetings/${this.meeting.id}/generate-minutes-stream`, {
				body: this.aiOptions,
			})
				.then(response => {
					const reader = response.body.getReader()
					const decoder = new TextDecoder()

					const read = () => {
						reader
							.read()
							.then(({ done, value }) => {
								if (done) {
									this.meeting.minutes_status = 'done'

									sendEvent('meetings.generate_minutes_completed', {
										feature: 'Meetings',
										page: 'try_page',
										meeting_id: this.meeting.pid,
										meeting: this.meeting.title,
									})
								} else {
									const chunk = decoder.decode(value, { stream: true })

									this.minutesMarkdown += chunk

									let html = converter.makeHtml(this.minutesMarkdown)
									html = this.fixHtmlForQuillEditor(html)

									this.meeting.minutes_text = html

									read()
								}
							})
							.catch(error => {
								console.error('stream error', error)
							})
					}

					read()
				})
				.catch(error => {
					this.meeting.minutes_status = previousStatus
					Vue.toasted.error(`Failed to generate minutes (${error.message})`)

					sendEvent('meetings.generate_minutes_error', {
						feature: 'Meetings',
						page: 'try_page',
						error: error.message,
						meeting_id: this.meeting.pid,
						meeting: this.meeting.title,
					})
				})

			sendEvent('meetings.generate_minutes_start', {
				feature: 'Meetings',
				page: 'try_page',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			//todo remove after 2024-03-01
			sendEvent('clerkminutes_try.generate_minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			//todo remove after 2024-03-01
			sendEvent('Try page - generate minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})
		},
		fixHtmlForQuillEditor(html) {
			const parser = new DOMParser()
			const doc = parser.parseFromString(html, 'text/html')

			// remove html tags inside a blockquote, keep only text
			doc.querySelectorAll('blockquote *').forEach(el => {
				el.outerHTML = el.innerText.trim()
			})

			let newHtml = doc.body.innerHTML

			// remove all new lines from newHtml
			newHtml = newHtml.replace(/\n/g, '')

			return newHtml
		},

		sendReq() {
			const body = {
				municipality: this.form.municipality,
				name: this.form.name,
				ref: this.form.ref,
			}

			try {
				body.email = validateEmail(this.form.email)
			} catch (error) {
				Vue.toasted.error(`Invalid email (${error.message})`)
				return
			}

			hgApi(`${this.j.slug}/meetings/${this.meeting.id}/request-full-meeting`, {
				body,
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(`${response.status} ${response.statusText}`)
					}
				})
				.then(data => {
					this.states.clerkMinutesStatus = data.status
					this.states.form = 'success'
				})
				.catch(error => {
					Vue.toasted.error(`Error sending request (${error.message})`)
				})

			sendEvent('meetings.request_full_access', {
				feature: 'Meetings',
				page: 'try_page',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			//todo remove after 2024-03-01
			sendEvent('clerkminutes_try.request_access', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			//todo remove after 2024-03-01
			sendEvent('Try page - request access', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})
		},
	},
	beforeDestroy() {
		clearInterval(this.transcriptionStatusTimer)
	},
}
</script>
