<template>
	<div class="page-news-notices-create">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/news-notices`">News &amp; Notices</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			Create News or Notice
		</h3>

		<div class="row justify-content-center">
			<div class="col-lg-10">
				<div class="card mb-5">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">
								Create News or Notice
							</h5>
							<help-button
								url="https://townweb.notion.site/Create-a-meeting-b7260037f1d44c0dba0280a904c3673b"
								text="How to post News or Notices"
							></help-button>
						</div>
					</div>
					<div class="card-body">
						<form @submit.prevent="generateNewsNotice" class="px-5">
							<div v-if="newsNoticeState === 'generating'" class="">
								<span class="spinner-border spinner-border-sm text-primary" role="status"> </span>
								Generating content for "{{ newsNoticePrompt }}"
							</div>
							<div v-else class="form-floating mb-3">
								<input
									type="text"
									class="form-control"
									id="news-notice-post-prompt"
									placeholder="Ex: friendly notice about low water pressure"
									v-model="newsNoticePrompt"
									required
									minlength="5"
									:disabled="newsNoticeState === 'generating'"
								/>
								<label for="news-notice-post-prompt">✨ Generate with AI</label>
							</div>
						</form>

						<hr class="bg-primary-100" />

						<form @submit.prevent="createNewsNotice">
							<div class="row gx-5">
								<div class="col-8">
									<div class="form-group mb-4">
										<label class="form-label" for="notice-name">
											Title <span class="text-danger">*</span>
										</label>
										<input
											class="form-control"
											id="notice-name"
											placeholder="Short and clear title"
											required
											v-model="newsNoticePost.title"
										/>
									</div>

									<div class="form-group mb-4">
										<label class="form-label" for="notice-content">
											Content <span class="text-danger">*</span>
										</label>
										<Editor
											v-model="newsNoticePost.content"
											:placeholder="'Write your content here...'"
											:showAIActions="false"
											:showBorder="true"
											:rows="5"
											:showBubbleMenu="false"
											required
										/>
									</div>
								</div>

								<div class="col-4">
									<div class="form-group">
										<label class="form-label">Featured Image</label>

										<div
											class="ratio ratio-16x9 bg-neutral-50 rounded-1 mb-2 position-relative overflow-hidden"
											:class="{ 'border border-dashed': !newsNoticePost.image }"
										>
											<img
												v-if="newsNoticePost.image"
												:src="newsNoticePost.image"
												:alt="newsNoticePost.title"
												class="rounded-1 border w-100 h-100"
												style="object-fit: cover;"
											/>

											<div v-else-if="imageOptions.length > 0" class="w-100 h-100">
												<div id="imageOptionsCarousel" class="carousel slide h-100" data-bs-interval="false">
													<div class="carousel-inner h-100">
														<div
															v-for="(img, index) in imageOptions"
															:key="index"
															class="carousel-item h-100 position-relative"
															:class="{ active: index === 0 }"
														>
															<img
																:src="img.url"
																:alt="img.alt"
																class="d-block w-100 h-100"
																style="object-fit: cover;"
															/>
															<div class="position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center">
																<div class="w-100 p-3 bg-dark bg-opacity-50">
																	<div class="d-flex flex-column gap-2 w-75 mx-auto">
																		<button
																			type="button"
																			class="btn btn-sm btn-light"
																			@click="showPreview(index)"
																		>
																				Preview image
																		</button>
																		<button
																			type="button"
																			class="btn btn-sm btn-primary"
																			@click="selectImage(img)"
																		>
																				Select image
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<button
														v-if="imageOptions.length > 1"
														class="carousel-control-prev"
														type="button"
														data-bs-target="#imageOptionsCarousel"
														data-bs-slide="prev"
													>
														<span class="carousel-control-prev-icon" aria-hidden="true"></span>
														<span class="visually-hidden">Previous</span>
													</button>
													<button
														v-if="imageOptions.length > 1"
														class="carousel-control-next"
														type="button"
														data-bs-target="#imageOptionsCarousel"
														data-bs-slide="next"
													>
														<span class="carousel-control-next-icon" aria-hidden="true"></span>
														<span class="visually-hidden">Next</span>
													</button>
												</div>
											</div>

											<div v-else class="d-flex align-items-center justify-content-center h-100">
												<div v-if="newsNoticeImageState === 'generating'" class="text-center">
													<div class="spinner-border text-primary mb-2" role="status"></div>
													<div><small class="text-muted">Generating images...</small></div>
												</div>
												<small v-else class="text-muted">No image selected</small>
											</div>
										</div>

										<div class="text-center position-relative">
											<label class="text-neutral-500 cursor-pointer">
												<input
													type="file"
													class="d-none"
													accept="image/*"
													@change="handleImageUpload"
												/>
												<small>Upload</small>
											</label>
											&middot;
											<small
												class="text-neutral-500 cursor-pointer"
												@click="showImagePromptInput = !showImagePromptInput"
											>
												{{ imageOptions.length ? 'Regenerate' : 'Generate' }}
											</small>
											<span v-if="newsNoticePost.image">
												&middot;
												<small
													class="text-neutral-500 cursor-pointer"
													@click="clearSelectedImage"
												>
													Clear
												</small>
											</span>

											<!-- Image prompt popover -->
											<div v-if="showImagePromptInput" class="image-prompt-popover p-3">
												<div class="mb-2">
													<label class="form-label small">Customize image prompt</label>
													<textarea
														class="form-control form-control-sm"
														v-model="newsNoticeImagePrompt"
														placeholder="Describe the image you want..."
														rows="2"
													></textarea>
												</div>
												<div class="d-flex justify-content-between align-items-center">
													<button
														class="btn btn-sm btn-light"
														@click="showImagePromptInput = false"
													>
														Cancel
													</button>
													<button
														class="btn btn-sm btn-primary"
														@click="generateNewsNoticeImage(); showImagePromptInput = false;"
														:disabled="newsNoticeImageState === 'generating'"
													>
														<span v-if="newsNoticeImageState === 'generating'">
															<span class="spinner-border spinner-border-sm" role="status"></span>
															Generating...
														</span>
														<span v-else>Generate</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="notice-channels">Where to post</label>

								<div class="row row-cols row-cols-3">
									<div class="col">
										<div
											class="p-3 rounded-1"
											:class="[
												newsNoticePost.channels.includes('website')
													? 'bg-success-50'
													: 'bg-neutral-50',
											]"
										>
											<div class="form-check form-switch mb-2">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="channel-website"
													v-model="newsNoticePost.channels"
													value="website"
												/>
												<label class="form-check-label" for="channel-website">Post on website</label>
											</div>
											<p class="mb-0">
												Send this News/Notice post to <code>{{ j.website }}</code>
											</p>
										</div>
									</div>

									<div class="col">
										<div
											class="p-3 rounded-1"
											:class="[
												newsNoticePost.channels.includes('facebook')
													? 'bg-success-50'
													: 'bg-neutral-50',
											]"
										>
											<div class="form-check form-switch mb-2">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="channel-facebook"
													v-model="newsNoticePost.channels"
													value="facebook"
												/>
												<label class="form-check-label" for="channel-facebook">Post on Facebook Page</label>
											</div>
											<p class="mb-0">
												Send this News/Notice post to
												<span class="badge bg-primary-50 text-primary-400">{{ j.name }}</span>
											</p>
										</div>
									</div>

									<div class="col">
										<div
											class="p-3 rounded-1"
											:class="[
												newsNoticePost.channels.includes('subscribers')
													? 'bg-success-50'
													: 'bg-neutral-50',
											]"
										>
											<div class="form-check form-switch mb-2">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="channel-subscribers"
													v-model="newsNoticePost.channels"
													value="subscribers"
												/>
												<label class="form-check-label" for="channel-subscribers">Send to Subscribers</label>
											</div>
											<p class="mb-0">
												Send by email or text to Subscribers
											</p>
										</div>
									</div>
								</div>
							</div>
						</form>

						<vue-easy-lightbox
							:visible="previewVisible"
							:imgs="imageOptions.map(img => img.url)"
							:index="previewIndex"
							@hide="handleHide"
						></vue-easy-lightbox>
					</div>
				</div>

				<div v-if="newsNoticePost.channels.includes('website')" class="card mb-4">
					<div class="card-header">
						<h5 class="my-0">Website post</h5>
					</div>
					<div class="card-body">
						<div class="form-group mb-4">
							<label class="form-label" for="notice-category">
								Category
							</label>

							<select
								class="form-select"
								id="notice-category"
								v-model="newsNoticePost.category"
								required
							>
								<option :value="null" v-if="categories.length === 0">Uncategorized</option>
								<option
									v-for="category in categories"
									:key="category.id"
									:value="category.id"
								>
									{{ category.name }}
								</option>
							</select>
						</div>

						<!-- Website Post Preview -->
						<div class="website-preview border rounded-1">
							<div v-if="newsNoticePost.image" class="website-preview-image">
								<img
									:src="newsNoticePost.image"
									:alt="newsNoticePost.title"
									class="w-100 h-100"
									style="object-fit: cover;"
								/>
							</div>
							<div class="p-4">
								<div class="d-flex align-items-center gap-2 mb-2">
									<span
										class="badge"
										:class="categoryBadgeClass"
									>
										{{ categoryName }}
									</span>
									<small class="text-muted">Today</small>
								</div>

								<h2 class="h4 mb-3">{{ newsNoticePost.title || 'Post title' }}</h2>

								<div class="website-preview-content" v-html="newsNoticePost.content"></div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="newsNoticePost.channels.includes('facebook')" class="card mb-4">
					<div class="card-header">
						<h5 class="my-0">Facebook post</h5>
					</div>
					<div class="card-body">

						<!-- Facebook Post Preview -->
						<div class="border rounded bg-white p-3">
							<div class="d-flex align-items-start">
								<div
									v-if="!j.logo_url"
									class="rounded-circle me-2 bg-neutral-200 d-flex align-items-center justify-content-center"
									style="width: 40px; height: 40px;"
								>
									<font-awesome-icon :icon="['fas', 'building']" class="text-neutral-400" />
								</div>
								<img
									v-else
									:src="j.logo_url"
									class="rounded-circle me-2"
									width="40"
									height="40"
									alt="Page logo"
								/>
								<div>
									<div class="fw-bold">{{ j.name }}</div>
									<small class="text-muted">Just now</small>
								</div>
							</div>
							<div
								class="my-2 whitespace-pre-wrap"
								contenteditable="true"
								@input="e => handleContentInput(e, 'facebook', 'text')"
								ref="facebookText"
								:class="{'empty-content': !newsNoticePost.facebook.text}"
							></div>
							<div v-if="newsNoticePost.image || newsNoticePost.title" class="border rounded">
								<img
									v-if="newsNoticePost.image"
									:src="newsNoticePost.image"
									class="w-100 rounded-top"
									style="max-height: 200px; object-fit: cover;"
									alt="Post image"
								/>
								<div class="p-3">
									<div class="small text-muted text-uppercase">{{ j.website }}</div>
									<div class="fw-bold">{{ newsNoticePost.title }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Replace the separate SMS and Email cards with this combined card -->
				<div v-if="newsNoticePost.channels.includes('subscribers')" class="card mb-4">
					<div class="card-header">
						<h5 class="my-0">Send to Subscribers</h5>
					</div>
					<div class="card-body">
						<div class="card-body">
							<!-- SMS Preview -->
							<div class="preview-section border rounded-3 p-4 mb-4">
								<div class="d-flex align-items-center gap-2 mb-4">
									<div class="preview-icon-wrapper">
										<font-awesome-icon :icon="['fas', 'comment-sms']" class="text-primary" />
									</div>
									<h6 class="mb-0">SMS Preview</h6>
								</div>
								<div class="sms-preview-container">
									<div class="sms-preview-header">
										<div class="sms-time">9:41</div>
										<div class="sms-icons">
											<font-awesome-icon :icon="['fas', 'signal']" />
											<font-awesome-icon :icon="['fas', 'wifi']" />
											<font-awesome-icon :icon="['fas', 'battery-full']" />
										</div>
									</div>
									<div class="sms-messages">
										<div class="sms-bubble">
											<div class="sms-sender">{{ j.name }}</div>
											<div
												class="sms-content"
												contenteditable="true"
												@input="e => handleContentInput(e, 'sms', 'text')"
												ref="smsText"
												:class="{'empty-content': !newsNoticePost.sms.text}"
											></div>
											<div class="sms-time-sent">Now</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Email Preview -->
							<div class="preview-section border rounded-3 p-4">
								<div class="d-flex align-items-center gap-2 mb-4">
									<div class="preview-icon-wrapper">
										<font-awesome-icon :icon="['fas', 'envelope']" class="text-primary" />
									</div>
									<h6 class="mb-0">Email Preview</h6>
								</div>
								<div class="email-preview-container">
									<div class="email-preview-header">
										<div class="d-flex align-items-center gap-2 mb-3">
											<img
												v-if="j.logo_url"
												:src="j.logo_url"
												class="rounded"
												width="40"
												height="40"
												alt="Logo"
											/>
											<div v-else class="rounded bg-neutral-200 d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
												<font-awesome-icon :icon="['fas', 'building']" class="text-neutral-400" />
											</div>
											<div>
												<div class="fw-bold">{{ j.name }}</div>
												<div class="text-muted small">{{ j.email || 'notifications@emailheygov.com' }}</div>
											</div>
										</div>
										<div class="email-subject mb-3">
											<input
												type="text"
												class="form-control"
												v-model="newsNoticePost.email.subject"
												placeholder="Email subject line..."
											/>
										</div>
									</div>

									<!-- Move image section here, before the content -->
									<div v-if="newsNoticePost.image" class="email-image-container mb-4">
										<img
											:src="newsNoticePost.image"
											:alt="newsNoticePost.title"
											class="w-100 rounded"
											style="max-height: 300px; object-fit: cover;"
										/>
									</div>

									<div class="email-content p-4 border rounded-1">
										<div
											contenteditable="true"
											@input="e => handleContentInput(e, 'email', 'text')"
											ref="emailText"
											:class="{'empty-content': !newsNoticePost.email.text}"
										></div>

										<div class="email-cta mt-4 text-center">
											<a
												href="#"
												class="btn btn-primary"
												@click.prevent
											>
												View on Website →
											</a>
										</div>
									</div>

									<div class="email-footer mt-3 text-center text-muted">
										<small>You're receiving this message because you're a member of {{ j.name }}</small><br>
										<small>Powered by HeyGov</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<form @submit.prevent="createNewsNotice" class="card mb-5">
					<div class="card-header">
						<h5 class="my-0">Send News/Notice</h5>
					</div>
					<div class="card-body text-center">
						<template v-if="newsNoticePost.channels.length">
							<p class="card-text">
								This news/notice will be sent to {{ newsNoticePost.channels.join(', ') }}.
							</p>
							<p class="card-text">
								<button
									type="submit"
									class="btn btn-primary px-6"
									:disabled="!canCreatePost || newsNoticeState === 'submitting'"
								>
									<template v-if="newsNoticeState === 'submitting'">
										<span class="spinner-border spinner-border-sm me-2" role="status"></span>
										Creating...
									</template>
									<template v-else>
										Create post →
									</template>
								</button>
							</p>
							<small v-if="!canCreatePost" class="text-muted">
								{{ !newsNoticePost.channels.includes('website')
									? 'Please select "Post on website" to continue'
									: 'Please add a title and content to continue'
								}}
							</small>
						</template>
						<div v-else class="alert alert-warning">
							Please select at least one channel to send this news/notice.
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { Carousel, Tooltip } from 'bootstrap'
import VueEasyLightbox from 'vue-easy-lightbox'
import Editor from '../../components/editor/Editor.vue'
import showdown from 'showdown'

import heyGovApi, { handleResponseError } from '@/api.js'
import { truncateString } from '../../lib/strings'

export default {
	name: 'EventsCreate',
	components: {
		VueEasyLightbox,
		Editor
	},
	data() {
		return {
			categories: [],

			newsNoticePrompt: '',
			newsNoticeImagePrompt: '',

			newsNoticeState: 'idle',
			newsNoticeImageState: 'idle',
			imageOptions: [],
			newsNoticePost: {
				title: '',
				category: null,
				content: '',
				image: null,
				facebook: {
					text: '',
				},
				sms: {
					text: ''
				},
				email: {
					subject: '',
					text: ''
				},
				channels: ['website', 'facebook', 'subscribers']
			},
			previewVisible: false,
			previewIndex: 0,
			showImagePromptInput: false,
		}
	},
	computed: {
		...mapState(['apiUrl', 'j']),

		categoryBadgeClass() {
			if (!this.newsNoticePost.category) {
				return 'bg-secondary text-white'
			}
			return 'bg-primary text-white'
		},

		categoryName() {
			if (!this.newsNoticePost.category) {
				return 'Uncategorized'
			}

			const category = this.categories.find(c => c.id === this.newsNoticePost.category)
			return category?.name || 'Uncategorized'
		},

		canCreatePost() {
			const hasWebsiteChannel = this.newsNoticePost.channels.includes('website')
			const hasRequiredFields = this.newsNoticePost.title.trim() && this.newsNoticePost.content.trim()
			return hasWebsiteChannel && hasRequiredFields
		}
	},
	created() {
		this.loadCategories()
	},
	mounted() {
		if (this.$route.query.prompt) {
			this.newsNoticePrompt = this.$route.query.prompt
			this.generateNewsNotice()
		}

		this.$nextTick(() => {
			const carousel = document.getElementById('imageOptionsCarousel')
			if (carousel) {
				new Carousel(carousel, {
					interval: false
				})
			}

			const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
			if (tooltipTriggerList.length) {
				tooltipTriggerList.forEach(tooltipTriggerEl => {
					if (tooltipTriggerEl) {
						new Tooltip(tooltipTriggerEl)
					}
				})
			}
		})
	},
	methods: {
		loadCategories() {
			heyGovApi
				.get(`${this.j.slug}/news-notices/categories`)
				.then(({ data }) => {
					this.categories = data?.data?.data || []
				})
				.catch(handleResponseError('Failed to load categories'))
		},

		generateNewsNotice() {
			this.newsNoticeState = 'generating'
			console.log('Generating news notice with prompt:', this.newsNoticePrompt)

			this.newsNoticePost.title = ''
			this.newsNoticePost.content = ''
			this.newsNoticePost.facebook.text = ''
			this.newsNoticePost.image = null

			heyGovApi
				.post(`${this.j.slug}/generate-draft`, {
					prompt: `Generate a news notice for ${this.j.name} about this prompt: ${this.newsNoticePrompt}`,
				})
				.then(({ data }) => {
					this.newsNoticePost.title = data.draft?.website?.title

					// facebook
					this.newsNoticePost.facebook.text = data.draft?.facebook?.text || ''

					// sms
					this.newsNoticePost.sms.text = data.draft?.sms?.text || ''

					// email
					this.newsNoticePost.email.subject = data.draft?.email?.subject || ''
					this.newsNoticePost.email.text = data.draft?.email?.body || ''

					// website
					const converter = new showdown.Converter()
					this.newsNoticePost.content = converter.makeHtml(data.draft?.website?.body || '')

					// image
					this.newsNoticeImagePrompt = data.draft?.image_prompt || this.newsNoticePrompt
					this.generateNewsNoticeImage()
					Vue.toasted.success(`Generated draft for "${truncateString(this.newsNoticePrompt, 22)}"`)
				})
				.catch(handleResponseError(`Failed to generate news/notice ({error})`))
				.finally(() => {
					this.newsNoticeState = 'idle'
				})
		},

		generateNewsNoticeImage() {
			console.log('Generating news notice image with prompt:', this.newsNoticePrompt)
			this.newsNoticeImageState = 'generating'
			this.imageOptions = []
			this.newsNoticePost.image = null

			heyGovApi
				.get(`${this.j.slug}/generate-image?prompt=${this.newsNoticeImagePrompt}`)
				.then(({ data }) => {
					this.imageOptions = data
					Vue.toasted.success(`Generated ${data.length} image options`)
				})
				.catch(handleResponseError(`Failed to generate news/notice image ({error})`))
				.finally(() => {
					this.newsNoticeImageState = 'idle'
				})
		},

		createNewsNotice() {
			this.newsNoticeState = 'submitting'

			const payload = {
				title: this.newsNoticePost.title,
				content: this.newsNoticePost.content,
				image_url: this.newsNoticePost.image,
				status: 'publish',
				category_id: this.newsNoticePost.category || undefined
			};

			if (this.newsNoticePost.channels.includes('facebook')) {
				payload.facebook = {
					content: this.newsNoticePost.facebook.text
				};
			}

			if (this.newsNoticePost.channels.includes('subscribers')) {
				payload.sms = {
					content: this.newsNoticePost.sms.text
				};
				payload.email = {
					subject: this.newsNoticePost.email.subject,
					content: this.newsNoticePost.email.text
				};
			}

			heyGovApi
				.post(`${this.j.slug}/news-notices`, payload)
				.then(({ data }) => {
					setTimeout(() => {
						Vue.toasted.success('News notice created', {
							...(data?.data?.url && {
								action: {
									text: 'View on website',
									href: data.data.url,
									target: '_blank',
								}
							})
						})
						this.$router.push(`/${this.j.slug}/news-notices`)
						this.newsNoticeState = 'idle'
					}, 8000) // 8000ms delay - to wait for the post to be created
				})
				.catch((error) => {
					handleResponseError('Failed to create news notice ({error})')(error)
					this.newsNoticeState = 'idle'
				})
		},

		selectImage(image) {
			this.newsNoticePost.image = image.url
			Vue.toasted.success('Image selected')
		},

		clearSelectedImage() {
			this.newsNoticePost.image = null
		},

		showPreview(index) {
			this.previewIndex = index
			this.previewVisible = true
		},

		handleHide() {
			this.previewVisible = false
		},

		handleImageUpload(event) {
			const file = event.target.files[0]
			if (!file) return

			// Validate file type
			if (!file.type.startsWith('image/')) {
				Vue.toasted.error('Please select an image file')
				return
			}

			// Convert to base64
			const reader = new FileReader()
			reader.onload = (e) => {
				this.newsNoticePost.image = e.target.result
				Vue.toasted.success('Image uploaded')
			}
			reader.onerror = () => {
				Vue.toasted.error('Failed to upload image')
			}
			reader.readAsDataURL(file)
		},

		handleContentInput(e, channel, field) {
			const text = e.target.innerText
			const currentValue = this.newsNoticePost[channel][field]

			if (currentValue !== text) {
				this.newsNoticePost[channel][field] = text
			}
		},

		updateContent(channel, field, text) {
			const ref = `${channel}Text`
			if (this.$refs[ref]) {
				const currentText = this.$refs[ref].innerText
				if (currentText !== text) {
					this.$refs[ref].innerText = text || ''
				}
			}
		}
	},
	watch: {
		'newsNoticePost.content'(newContent) {
			if (!this.newsNoticePost.facebook.text) {
				this.newsNoticePost.facebook.text = newContent.replace(/<[^>]*>/g, '')
			}
		},
		'$route.query.prompt'(newPrompt) {
			this.newsNoticePrompt = newPrompt
			this.generateNewsNotice()
		},
		newsNoticePost: {
			deep: true,
			handler(newPost) {
				this.updateContent('facebook', 'text', newPost.facebook.text)
				this.updateContent('sms', 'text', newPost.sms.text)
				this.updateContent('email', 'text', newPost.email.text)
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.image-prompt-popover {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  width: 300px;
  z-index: 1000;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.website-preview {
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  &-image {
    width: 100%;
    height: 300px;
    background: #f8f9fa;
  }

  &-content {
    max-height: 300px;
    overflow-y: auto;

    :deep(p:last-child) {
      margin-bottom: 0;
    }

    :deep(h1),
    :deep(h2),
    :deep(h3) {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    :deep(ul),
    :deep(ol) {
      padding-left: 1.5rem;
    }
  }
}

[contenteditable] {
  outline: none;
  padding: 8px;
  border-radius: 4px;

  &:focus {
    background: #f8f9fa;
  }
}

.empty-content {
  &:empty {
    &:before {
      content: 'Write your Facebook post text here...';
      color: #6c757d;
    }
  }
}

.sms {
  &-preview {
    &-container {
      max-width: 375px;
      margin: 0 auto;
      background: #f8f9fa;
      border-radius: 2rem;
      padding: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }
  }

  &-icons {
    display: flex;
    gap: 0.5rem;
  }

  &-messages {
    padding: 1rem;
    background: white;
    border-radius: 1rem;
    min-height: 200px;
  }

  &-bubble {
    background: #e9ecef;
    padding: 0.75rem;
    border-radius: 1rem;
    max-width: 80%;
  }

  &-sender {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  &-content {
    min-height: 3rem;

    &.empty-content:empty:before {
      content: 'Write your SMS message here...';
    }
  }

  &-time-sent {
    font-size: 0.75rem;
    color: #6c757d;
    text-align: right;
    margin-top: 0.25rem;
  }
}

.email {
  &-preview-container {
    max-width: 600px;
    margin: 0 auto;
    background: white;
    padding: 1.5rem;
  }

  &-content {
    min-height: 200px;
    background: white;

    &.empty-content:empty:before {
      content: 'Write your email message here...';
    }
  }

  &-subject {
    input {
      border: none;
      border-bottom: 1px solid #dee2e6;
      border-radius: 0;
      padding: 0.5rem 0;
      font-weight: 600;

      &:focus {
        box-shadow: none;
        border-color: #0d6efd;
      }
    }
  }

  &-image-container {
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-cta {
    border-top: 1px solid #dee2e6;
    padding-top: 1.5rem;
    margin-top: 1.5rem;

    .btn {
      padding: 0.5rem 1.5rem;
      font-weight: 500;
      border-radius: 4px;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
      }
    }
  }
}

.preview {
  &-section {
    height: 100%;
    background: #fff;
    transition: all 0.2s ease;

    &:hover {
      border-color: #0d6efd !important;
      box-shadow: 0 0 0 1px #0d6efd;
    }
  }

  &-icon-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(13, 110, 253, 0.1);
    border-radius: 8px;
  }
}
</style>
