var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"editor-toolbar"},[(_vm.showAIActions)?_c('a',{staticClass:"btn btn-ai-actions",attrs:{"role":"button","type":"button","tabindex":"0","data-bs-container":"body","data-bs-toggle":"popover","data-bs-placement":"bottom","titlee":"ClerkMinutes AI Actions","data-bs-content":"Select a section of the minutes (title + content) then click on one of the AI actions: Make Longer, Make Shorter, Describe Your Change, or Rewrite in style"}},[_c('font-awesome-icon',{staticClass:"text-primary-400 me-2",attrs:{"icon":['fas', 'magic-wand-sparkles']}}),_c('span',{staticClass:"text-ai"},[_vm._v("AI Actions")])],1):_vm._e(),(_vm.showAIActions)?_c('span',{staticClass:"toolbar-separator"}):_vm._e(),(_vm.editor)?[_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn dropdown-toggle",attrs:{"type":"button","id":"editor-line-type","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'heading']}})],1),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"editor-line-type"}},[_c('li',[_c('button',{staticClass:"dropdown-item",class:{ 'text-primary': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
									.chain()
									.focus()
									.toggleHeading({ level: 1 })
									.run()}}},[_vm._v(" Heading 1 ")])]),_c('li',[_c('button',{staticClass:"dropdown-item",class:{ 'text-primary': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
									.chain()
									.focus()
									.toggleHeading({ level: 2 })
									.run()}}},[_vm._v(" Heading 2 ")])]),_c('li',[_c('button',{staticClass:"dropdown-item",class:{ 'text-primary': _vm.editor.isActive('heading', { level: 3 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
									.chain()
									.focus()
									.toggleHeading({ level: 3 })
									.run()}}},[_vm._v(" Heading 3 ")])])])]),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('bold') },attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.toggleBold()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleBold()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bold']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('italic') },attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.toggleItalic()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleItalic()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'italic']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('underline') },attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.toggleUnderline()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleUnderline()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'underline']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('strike') },attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.toggleStrike()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleStrike()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'strikethrough']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('blockquote') },attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.toggleBlockquote()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleBlockquote()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'quote-right']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('link') },attrs:{"type":"button"},on:{"click":_vm.setLink}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'link']}})],1),_c('span',{staticClass:"toolbar-separator"}),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('bulletList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleBulletList()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'list-ul']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('orderedList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.toggleOrderedList()
						.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'list-ol']}})],1),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.setHorizontalRule()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'ruler-horizontal']}})],1),_c('span',{staticClass:"toolbar-separator"}),_c('button',{staticClass:"btn",attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.undo()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.undo()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'undo']}})],1),_c('button',{staticClass:"btn",attrs:{"type":"button","disabled":!_vm.editor
						.can()
						.chain()
						.focus()
						.redo()
						.run()},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.redo()
						.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'redo']}})],1)]:_vm._e()],2),_c('my-bubble-menu',{ref:"bubble-menu",attrs:{"editor":_vm.editor,"onSetLink":_vm.setLink,"onDiscard":_vm.forceCloseBubbleMenu,"showAIActions":_vm.showAIActions,"showBubbleMenu":_vm.showBubbleMenu},on:{"ai-action":_vm.handleAIAction,"make-longer-context-menu":function($event){return _vm.$emit('make-longer-context-menu')},"make-shorter-context-menu":function($event){return _vm.$emit('make-shorter-context-menu')},"describe-your-change-context-menu":function($event){return _vm.$emit('describe-your-change-context-menu')}}}),_c('editor-content',{class:{ 'editor-bordered': _vm.showBorder },style:(_vm.rows ? { minHeight: ((_vm.rows * 1.5 + 1) + "rem") } : {}),attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }